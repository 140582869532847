.enquiries{
	padding-top: 50px;
}
.enquiry{
	margin-bottom: 30px;
	&:last-of-type{
		margin-bottom: 75px;
	}
	a{
		color: $brand-darkblue;
		&:hover,
		&:focus{
			color: $brand-lightblue;
		}
	}
}