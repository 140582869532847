.single-col,
.two-col{
	padding-top: 20px !important;
	padding-bottom: 20px !important;

	& + footer{
		margin-top: 40px;
	}
}




@media(max-width:767px) {
	.single-col,
	.two-col{
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}
}
