	.tooltip-toggle{
		background: none;
		border:0;
	}
	.tooltip-inner {
		max-width: 200px;
		padding: 8px 8px;
		color: #fff;
		text-align: center;
		background-color: $brand-darkblue;
		border-radius: 4px;
		border:1px solid $brand-darkblue;
	}
	.tooltip{
		&.left{
			.tooltip-arrow {
				border-left-color: $brand-darkblue;
			}
		}
		&.right{
			.tooltip-arrow {
				border-right-color: $brand-darkblue;
			}
		}
		&.top{
			.tooltip-arrow {
				border-top-color: $brand-darkblue;
			}
		}
		&.bottom{
			.tooltip-arrow {
				border-bottom-color: $brand-darkblue;
			}
		}

	}