.public-website{

	.shrink{
		.navbar-brand{
			margin-top: 15px;
		}
		.main-nav.navbar.navbar-default {
			padding-bottom: 5px;

			.navbar-nav{
				margin-top: 0;
			}
		}
	}

	.company-details {
		border-top: 1px rgba(33,43,54,0.2) solid;
		padding-top: 55px;
		p{
			font-size: 16px;
			font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
			font-weight: 500;
			color: #fff;
		}
		h3{
			margin-top: 0;
		}

		.time-details{
			padding-left: 0;
			list-style: none;
			li{
				color: #fff;
				display: flex;
				-ms-flex-pack: justify;
				justify-content: space-between;
				width: 80%;
				margin-bottom: 10px;
			}
		}
		.company-contact-details{
			padding: 0;
			list-style: none;
			li{
				margin-bottom: 30px;
				position: relative;
				padding-left: 40px;
				p{
					padding-top: 20px;
					font-size: 16px;
					@include font-regular;
					color: #fff;
				}
				a {
					i{
						color: $brand-darkblue;
						margin-right: 10px;
						position: absolute;
						left: 0;
						top: 8px;
					}
					color: #fff;
					font-weight: bold;
					font-size: 24px;
					&:hover{
						color: #CBDB2A;
					}
				}
			}
		}

	}

	@media(max-width:767px) {

		.company-details {
			padding-top: 35px;
			.time-details li {
				width: 100%;
			}
			.company-contact-details {
				margin-bottom: 30px;
			}
		}
	}

}

.physio-members{
	    margin-left: 0;
	    display: block;
	h3{
		font-size: 20px;
		margin-bottom: 0.5em;
	}

	.fa-li {
    	left: 0;
    	i{
			font-size: 36px;
			color: rgba(245,246,246,0.5);
		}
	}


	& > li{
		padding-left: 3.5em;
		border-top: 1px rgba(170,165,158,0.3) solid;
    	margin-top: 20px;
    	display: flex;
    	align-items: center;

    	&:nth-last-child(1){
    		border-bottom: 1px rgba(170,165,158,0.3) solid;
    		padding-bottom: 20px;
    	}
		ul{
			padding-left: 0;
			li{
				list-style: none;
				display: inline-block;
				text-transform: uppercase;
				font-size: 12px;
				@include font-bold;
				color: #C4CDD5;

				&:nth-last-child(1){
					&:after{
						display: none;
					}
				}
				&:after{
					content:"/";
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
}