
.hero.sig-banner{
	position: relative;
	height: 430px;
	background-size: cover;


	h1{
		z-index: 1;
		color: #fff;
		position: absolute;
		@include center('vertical');
		padding-left: 15px;
		max-width: 80%;
		span{
			font-size: 0.7em;
			display: block;
		}
	}

}

