//---- Nav
@import "_structure/_navigation-structure";



$bg-color: $brand-darkblue; 
$nav-link-color: #fff;

$link-alignment: flex-end;

.header-wrap {
	background-color: $bg-color;
	& ~ .page-wrapper{

		margin-top:145px;

	}
}

.main-nav.navbar.navbar-default {
	min-height: 0;
	margin-top: 15px;
	margin-bottom: 15px;
	background-color: $bg-color;

	.navbar-header{
		width: 100%;
	}

	.navbar-nav {
		& > li{
			& > a{
				@include font-bold;
			}
		}
		li {
			position: relative;
			@include font-regular;
			font-size: 17px;
			transition: opacity 0.2s ease-in-out;
			//
			&.active{
				& > a:not(.btn){
					background: transparent;
				}
				
				&::after{
					content: '';
					width: 100%;
					position: absolute;
					height: 4px;
					background: #ffffff;
				}
			}

			& > a:not(.btn) {
				color: $nav-link-color;
				padding: 10px 20px 10px 20px;
				transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
				opacity: hidden;
				display: block;

				&:hover,
				&:focus,
				&:active {
					color: $brand-color;
					background-color: transparent;
				}	
				&:nth-last-child(1){
					padding: 10px 0 10px 20px;
				}
			}
		}
		&.opened{
			li:not(.open){
				opacity: 0.2;
			}

		}
	}

	.dropdown {
		&::before{
			content: '\f0d7';
			@include fontawesome-solid;
			color: $brand-color;
			display: block;
			position: absolute;
			bottom: 8px;
		}
		.close {

			font-size: 35px;
			top: 10px;
			right: 15px;
			color: #ffffff;

			&:hover{
				color: $brand-color;
			}
		}
		&.open{
			background-color: $bg-color;
			color: #fff;
			a:not(.btn){
				color: #fff;
			}
			.dropdown-menu {
				-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
				box-shadow: 0 6px 12px rgba(0,0,0,.175);
				z-index: 2;
				li{
					opacity: 1;
				}
			}
		}
		.dropdown-menu {
			position: fixed;
			top: 133px;
			left: 0;
			width:100%;
			background-color: $bg-color;
			border:0;
			overflow: auto;
			padding: 3em 0;
			z-index: 1;
			transition: box-shadow 0.5s 0.1s;
			box-shadow: none;
			
			.dropdown-container{
				list-style: none;
				padding-left: 0;
				small{
						display: block;
						color: #576571;
					}
				.dropdown-category{
					list-style: none;
					padding-left: 0;
					
				}
				.card{
					a h2{
						color: #fff;
						@include font-regular;
					}
				}
			}

			li {
				text-align: left;
				opacity: 0;
				transition: opacity 0.5s 0.1s;
				position: relative;
				padding: 0 30px 15px 0;
				& > a:not(.btn) {
					padding: 10px 25px 10px 0;
					font-size: 1.1em;
					white-space: normal;
					justify-content: left;
					line-height: 1.4em;
					color: #fff;

					&:focus,
					&:hover {
						color: $brand-color;

					}
				}
				&.dropdown-heading{
					@include font-light;
					width: 100%;
					color:#C4CDD5;
					padding-top: 15px;
					font-size: 2.2em;
					padding-bottom: 12px;
					margin-bottom: 15px;
					flex-basis:100%;
				}
			}
		}
	}
}

.public-website .navigation-wrap,
.toggle-wrap{
	form{
		max-width: 250px;

		.form-control{
			height: auto;
			border-radius: 31px;
			background-color: #fff;
			padding: 15px;
			&:first-child{
				border-top-right-radius: 31px;
				border-bottom-right-radius: 31px
			}
		}
		.input-group{
			position: relative;
		}
		.input-group-btn{
			font-size: 1em;
			position: absolute;
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;;
			z-index: 9;
			button{
				background-image: none;
				border-radius: 50%;
				width: 100%;
				height: 100%;
				border: 0;
				transition: background 0.2s ease-in-out, color;
				font-size: 14px;
				&:hover,
				&:focus{

					background: $brand-highlight;
					color: #fff;
				}
			}
		}
	}
}




.navbar-brand {

	.nav-logo{
		width: 90px;
	}
}

@media (min-width: 992px) {

	.shrink{
		.toggle-wrap {
			.tools {
				.dropdown {
					.dropdown-menu{
						top: 110px;
					}
				}
			}
		}
		&.header-wrap ~ .page-wrapper {
			margin-top: 112px;
		}

		.toggle-wrap {
			.tools {
				padding-top: 15px;
				transition: all 0.1s;
			}
		}
		.main-nav.navbar.navbar-default {
			margin-top: 15px;
			transition: margin 0.1s;
			.dropdown{
				.dropdown-menu{
					top: 112px;
					&.open{
						display: block;
					}
				}
			}
		}
	}

	.navbar-nav {
		display: flex;
		align-items: flex-end;
		justify-content: $link-alignment;
		width: 100%;
		padding-left:0;
		flex-wrap:wrap;

	}

	.navbar-brand {
		width: 175px;
		transition: all 0.1s;
		margin-top: 20px;
		position: absolute;
		margin-top: 30px;
		padding: 0;
		height: auto;
		z-index: 9;

	}

	.dropdown-container{

		display: flex;
		flex-wrap:wrap;
		flex-basis:100%;
		& > li{
			&:not(.dropdown-heading):not([class^="flex-container"]){
				flex-basis:33%;
			}
		}
		.dropdown-category{

			display: flex;
			flex-wrap:wrap;
			flex-basis: 100%;
			justify-content:space-between;

			.list-heading{
				color: $brand-color;
				font-size: 0.8em;
				flex-basis: 100%;
			}
			
		}

		.flex-container-66{
			flex-basis:66%;

			li:not(.list-heading){
				flex-basis:48%;
				padding-right: 50px;
				max-width: 300px;
			}

		}
		.flex-container-50{
			flex-basis:48%;

			li:not(.list-heading){
				flex-basis:48%;
				padding-right: 50px;
			}

		}
		.flex-container-33{
			flex-basis:33%;

			li:not(.list-heading){
				flex-basis:100%;
			}
		}
		.flex-container-25{
			flex-basis:25%;

			li:not(.list-heading){
				flex-basis:100%;
			}

		}

	}
	.toggle-wrap{
		.tools{
			max-width: 520px;
			padding-top: 30px;
			display: flex;
			align-items:center;
			justify-content:space-around;
			float: right;
			.dropdown-menu {
				position: fixed;
				top: 142px;
				left: 0;
				width:100%;
				background-color: $brand-darkblue;
				border:0;
				overflow: visible;
				border-radius:0;
				padding-bottom: 40px;
				&.open{
					display: block;
				}
				.container{
					position: relative;
					&:after{
						content:'\f0d8';
						@include fontawesome-solid;
						display: block;
						position: absolute;
						top: 0px;
						right: 40px;
						color: $brand-darkblue;
						transition:top 0.2s ease-in-out 0.5s;
					}
				}
				li {
					text-align: left;
					small{
						display: block;
						color: #576571;
					}
					& > a {
						padding: 10px 25px;
						font-size:18px;
						white-space: normal;
						justify-content: left;
						line-height: 1.4em;
						color: #fff;
						i{
							color: #CFE018;
						}
						&:focus,
						&:hover {
							color: $brand-color;
						}
					}
					&:hover {
						background-color: transparent;
						color: $brand-color;
					}
				}
				li{
					position: relative;
					&:first-of-type{
						@include font-light;
						width: 100%;
						padding-top: 15px;
						font-size: 1.7em;
						color: #fff;
						padding-bottom: 12px;
						margin-bottom: 15px;
					}
					&:not(:first-of-type){
						float: left;
						width: 33%;
						padding: 20px 0;
						a{
							&:hover, 
							&:focus{
								&::after{
									color: $brand-color;
								}
							}
						}
					}
					a{
						padding: 20px 0 20px 0;
					}
				}
			}
			a:not(.btn){
				color: $brand-color;
				margin-right: 20px;
				white-space: nowrap;
				
				&:hover,
				&:focus{
					color: #fff;
				}
			}
		}
	}
}

@media(max-width: 1200px){
	.navbar-brand{
			.nav-logo{
			width: 60px;
		}
	}
			

}



@media (max-width: 991px) {

	.public-website{
		.navbar-brand {
			.nav-logo{
				width: 65px;
			}
		}	
		.main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn){
			padding-left: 0;
		}
	}

	.toggle-wrap{
		position: fixed;
		width: 100%;
		z-index: 9;
		padding-bottom: 20px;
		height: 70px;
		background:$brand-darkblue;
		.tools{
			position: absolute;
			top: 18px;
			right: 55px;
			padding-top: 0;

			& > a:not(.btn){
				font-size: 1.7em;
				padding-right: 20px;
				float: left;
				color: #fff;
				&:hover{
					color:#CBDB2A;
				}
			}
			.search-toggle{
				font-size: 1.7em;
				padding-top: 0;
				padding-right: 20px;
			}
		}
	}

	.navbar-brand {
		width: 116px;
		height: 48px;
		margin-top: 15px;
	}

	.page-wrapper {
		position: relative;
		z-index: 2;
		background-color: #ffffff;
		transform: translateZ(0);
		transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);
		// overflow-x:hidden; 
	}

	.main-nav.navbar.navbar-default {

		background-color: $bg-color;
		.main-navigation-wrapper {
			padding: 65px 50px;
			transform: translateX(0%);
			transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);
		}

		.navbar-nav {

			li {
				float: none;

				& > a:active,
				& > a:hover,
				& > a:focus,
				& > a:hover:before,
				& > a:focus:before {
					color: $brand-color;
					background: transparent;
				}
				a:not(.btn){
					color: #fff;
				}
			}
			.dropdown{

				&.open{
					background: transparent;
				}
				li.dropdown-heading{
					font-size: 1.8em;
				}
				li.list-heading{
					font-size:0.9em;
					color: $brand-color;
				}
				li:not(:first-of-type){
					width:100%;
					padding: 10px 0 10px;

				}
				li.close{
					width: auto;
					display: block;
					padding: 10px;
					transition:color 0.1s ease-in-out;
					&:after{
						content: '';
					}
				}
				.dropdown-menu {
					top: 0;
					padding: 0 50px;
					margin-top: 65px;

					li {
						padding:0;
						& > a:not(.btn){
							font-size: 17px;
						}
					}

					& > li {
						& > a:not(.btn) {
							padding: 10px 25px;
							font-size: 17px;
							line-height: 20px;

							&:hover,
							&:focus {
								color: $brand-lightblue;
							}
						}
						.card{
							padding:0;
						}
					}
					&:before {
						color: $brand-lightblue;
						left: 19px;
						top: -10px;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-bottom: 10px solid $brand-darkblue;
						transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

					}
					.sub-category{
						border-top:1px solid #fff;
						padding-top: 20px;
						margin-top: 20px;
					}
					.card{
						padding:10px 0;
						border-top:1px solid #fff;
						margin-top: 10px;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.main-nav-trigger {
		right: 15px;
		top: 20px;
		height: 25px;
		width: 30px;
		transition: transform 0.2s;

		.main-nav-icon {

			@include center;
			width: 30px;
			height: 2px;

			background-color: #fff;
			cursor: pointer;
		}
	}

	.header-wrap {
		width: 100%;
		background-color: $brand-darkblue;
		& ~ .page-wrapper{
			margin-top: 65px;
		}
	}
	.navigation-is-open{
		overflow: hidden;
	}
}



@media (max-width: 768px) {

	.main-nav.navbar.navbar-default {
		.dropdown-menu{
			.card{
				padding:0;
			}
		}
	}
}


.public-website{
	.header-wrap{
		background-color: $brand-color;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		& + .page-wrapper{
			margin-top: 144px;
		}
	}
	.toggle-wrap{
		.tools{
			font-size: 1.5em;
			position: relative;
			width: 40px;
			height: 60px;
			overflow: visible;
			z-index: 3;
			.dropdown{
				float: left;
			}
			a{
				position: absolute;
				top: 86px;
				display: block;
			}
			a:not(.btn){
				color:#fff;
				&:hover,
				&:focus{
					color:$brand-darkblue;
				}

			}
		}

	}

	.main-nav.navbar.navbar-default{
		background-color: $brand-color;
		padding-bottom: 30px;

		.navbar-nav{
			padding-left: 175px;
			margin-top:20px; 
			padding-bottom: 0;
			form {
				margin-left: auto;
			}
			li {
				margin-left: auto;
				& > a:not(.btn) {

					&:hover,
					&:focus{
						color: $brand-darkblue;
					}
				}
			}
			.dropdown{
				&.open{
					background-color:$brand-color; 
				}
			}
			.dropdown-menu{
				padding-top: 0;
				background-color:$brand-color;
			}
		}
	} 
}

@media (max-width: 991px) {
	.public-website{
		.header-wrap{
			background-color: $brand-color;
			& + .page-wrapper{
				margin-top: 70px;
			}
		}
		.main-nav.navbar.navbar-default .navbar-nav li{
			margin-bottom: 00px;
		}
		.main-nav.navbar.navbar-default .navbar-nav form {
			margin-left: 0;
			padding-top: 60px;
		}
		.main-nav.navbar.navbar-default{
			.navbar-nav{
				padding-left: 0;
			}
		}
		.toggle-wrap{
			background-color:$brand-color;
		}

		.main-nav.navbar.navbar-default {
			background-color: $brand-color;
		}
	}
}

.dropdown-backdrop{
	z-index: 0;
	display: none;
}
