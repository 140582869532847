.tint-bg{

	background-color: #F3F2EE;
	padding: 50px 0;

}


.subscription-form{
	padding-top: 40px;
	label{
		font-size: 14px;
		color: #aab5af;
		font-style: italic;
		@include font-regular;
		padding-left: 20px;
	}
	.form-control{
    border-radius: 30px;
    padding-left: 20px;
    border: 0;
    border-bottom: 1px;
    height: 45px;
    background: #ffffff;
	}
	.btn{
		margin-top: 20px;
	}
}
