
 
//- Common Page elements

.reveal{ 
	// visibility: hidden;
}

.lead,
.intro{
	margin-top: 2em;
	font-size: 22px;
	line-height: 1.5em;
	color: #637381;
	margin-bottom: 2em;
	@include font-light;
}

.btn.btn-primary,
.btn.btn-default{

	background-color: #fff;
	border-radius: 20px;
	border:0;
	    padding: 10px 35px;
	font-size: 1.1em; 
	transition: background-color 0.2s ease-in-out;
	color: $brand-color;
	border:1px solid $brand-color;
	// min-width: 175px;
	display: inline-block;
	font-size: 14px;

	&.active,
	&.acive:hover, 
	&.acive:focus,
	&:active,
	&:active:focus,
	&:hover,
	&:focus{
		background-color: $brand-darkblue;
		border-color: $brand-darkblue;
		box-shadow: none;
		color: #fff;
		outline: none;
	}
}

.btn.btn-solid{
	background-color: #fff;
	border-radius: 20px;
	border:0;
	    padding: 10px 35px;
	font-size: 1.1em;
	transition: background-color 0.2s ease-in-out;
	background-color: $brand-color;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	@include font-bold;

	&.active,
	&.acive:hover, 
	&.acive:focus,
	&:active,
	&:active:focus,
	&:hover,
	&:focus{
		background-color: $brand-darkblue;
		box-shadow: none;
		color: #fff;
		outline: none;
	}
}

.btn.btn-dark-solid{
	background-color: #fff;
	border-radius: 20px;
	border:0;
	    padding: 10px 35px;
	font-size: 1.1em;
	transition: background-color 0.2s ease-in-out;
	background-color: $brand-darkblue;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	@include font-bold;

	&.active,
	&.acive:hover, 
	&.acive:focus,
	&:active,
	&:active:focus,
	&:hover,
	&:focus{
		background-color: $brand-color;
		box-shadow: none;
		color: #fff;
		outline: none;
	}
}

.align-center{
	display: flex;
	justify-content: center;
}
.lead-in{
	padding-top: 30px ;
	padding-bottom: 30px ;
	p{
		margin-top: 1em;
		font-size:1.2em;
		line-height: 1.5em;
		margin-bottom: 1.5em;
	}
	a{
		&::after{
			content:'\f30b';
			@include fontawesome-regular;
			padding-left: 10px;
		}
	}
}


.align-bottom{
	display: flex;
	align-items: flex-end;
}

@media( max-width: 580px ){
	.col-xxs-12{
		width: 100%;
	}

	.lead,
	.intro{
		font-size: 20px;
	}
}


.img-container{
	max-width: 100%;
	overflow: hidden;
	position: relative;
	height: 0;
	padding-bottom: 56.5%;
	img,
	iframe{
		max-width: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform:translate(-50%, -50%);
		min-width: 100%;
		min-height: 100%;
		height: 100%;
	}
}


.img-round{
	height: 400px;
	width: auto;
	.img-container{
		height: 100%;
		width: auto;
		margin: auto;
		border-radius: 100%;
	}
}

@media(max-width:991px) {
	.img-round{
		.img-container{
			width: auto;
			
		}
	}
}


ol.feature-list{
	list-style: none;
	padding-left: 0;
	margin-bottom: 40px;
	margin-top: 30px;
	li{
		counter-increment: step-counter;
		position: relative;
		padding-left: 45px;
		margin-bottom: 25px;
		&::before{
			content:counter(step-counter);
			width: 30px;
			height: 30px;
			border-radius: 30px;
			color: $brand-color;
			@include font-regular;
			font-size: 2.5em;
			display: block;
			text-align: center;
			line-height: 30px;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

ul{
	&.ticklist{
		padding-left: 0;
		margin-bottom: 30px;
		li{
			display: inline-block;
    		width: 50%;
			list-style: none;
			padding-left: 30px;
			position: relative;
			margin-bottom: 15px;
			&::before{
				content:"" ;
				font-family: "Font Awesome 5 Pro";
				font-size: 20px;
				line-height: 1em;
				margin-top: 3px;
				color: $brand-color;
				position: absolute;
				left: 0;
				top:0;
			}
		}
	}
}


.divider{
	border-top:1px solid black;
}


.blue-bg{
	background: $brand-color;
	padding-top: 50px;
	padding-bottom: 60px; 

	h1{
		color: #fff;
		text-align: center;
		font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
		font-style: normal;
		font-weight: 200;
	}
	h2{
		color: #fff;
		font-size: 36px;
	}
	h3{
		color: #212B36;
		font-size: 36px;
	}
	p{
		color: #fff;
	}
	
	
}


.darkblue-bg{
	background: $brand-darkblue;
	padding-top: 50px;
	padding-bottom: 60px; 

	h1{
		color: #fff;
		text-align: center;
		font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
		font-style: normal;
		font-weight: 200;
	}
	h2{
		color: #fff;
		font-size: 36px;
	}
	h3{
		color: #fff;
		font-size: 36px;
	}
	p{
		color: #fff;
	}
	
	
}

.link-style{
	color: #212B36;
	@include font-bold;
	i{
		color: #CBDB2A;
		padding-right: 5px;
	}
}

.content{
	padding-bottom: 50px;
	padding-top: 40px;


}	

@media(max-width:991px) {
	.content{
		padding-top: 60px; 
		h1{
			font-size: 2em;
		}
	}
}

.warning{
	color: #FA5E3F;
}
.card-section{
	padding-top: 50px;
	padding-bottom: 60px;

	&.tint-bg,&.blue-bg{
		&:nth-child(odd){
			h1{
				padding-top: 0;
				border-top:0;
			}

		}
	}

	h1{
		font-weight: 200;
    	letter-spacing: 0.02em;
		color: #AAA59E;
		text-align: center;
		margin-bottom: 50px;
		font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;

	}
	h3,
	h2{
		margin-bottom: 2em;
	}

	&:nth-child(odd){
		h1{
			padding-top: 60px;
			border-top:1px solid #F3F2EE;
		}
		
	}
}

.page-content{
	padding: 40px 0;
}



@media(min-width:767px) {

	.d-flex{
		display: flex;
	}

}
