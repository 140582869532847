// modal
////////


.modal-backdrop{
	background: $brand-color ;
	&.in{
		opacity: 0.95;
	}
}


.modal-open{
	padding-right: 0 ;
}
.modal.fade {
	.modal-dialog{
		transform: translate(-50%, -65%) ;
		left: 50%;
		top: 50%;
		margin: 0;
	}
	&.in .modal-dialog {
	transform: translate(-50%, -50%) ;

	}
}
.modal{
	.modal-header{
		padding: 0;
		.close{
	    opacity: 1;
    font-size: 35px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-shadow: none;
    color: #374e59;
    	transition:opacity 0.2s ease-in-out;
			&:active,
			&:focus{
				outline: 0;
				opacity:1;
			}

		}
	}
	.modal-body{
		padding:30px 0 0;
	}
	.modal-content{
		border-radius: 0;
		border:0;
		box-shadow: none;
		padding: 30px 45px;
	}
}


#video-modal{
	.modal-dialog{
		width: 75%;
	}
	.modal-content{
		padding-top:0;
		padding-bottom: 56.8%; 
		position: relative;
	}

	iframe{
		position: absolute;
		width: 100%;
		left: 0;
		height: 100%;
	}
}