.job-offer{

	&:not(:last-of-type) .media{
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom:1px solid #d7deda;
	}
	&:last-of-type .media{
		margin-bottom: 40px;
		
	}
	.media-left{
		float: left;
		padding-right: 30px;
		width: 300px;
	}
	.media-body{
		width: auto;
		float: left;
		width: calc(100% - 340px);

		.media-heading{
			margin-top: 15px;
			margin-bottom: 20px;
		}

		small{
			color: #aab5af;
			@include font-bold;
			span{

			}
		}
	}
}

.job-listing{
	position: relative;
	h2 + a{
		position: absolute;
		right: 15px;
		top: 30px;
		color: #aab5af;
		&:hover{
			color: $brand-lightblue;
		}
	} 
	.btn{
		margin-top: 20px;
	}
}

@media(max-width: 991px){
	.job-listing{
		margin-bottom: 40px;
	}
	.job-offer{
		.media-body{
			width: 100%;
		}
		.media-left {
   			float: left;
    		padding-right: 0;
    		width: 100%;
    		margin-bottom: 20px;
		}
	}
	
}
@media(max-width: 580px){
	.job-listing{
		margin-bottom: 40px;
			h2 + a{
			position: relative;
			right: 0;
			top: 0;
		    display: block;
    		padding-bottom: 24px;
    	}
	}
	.page-info{
		img{
			max-width: 200px;
		}
		img, 
		.fa-ul{
			float: none
		}
		.fa-ul{
			margin-top: 20px;
			margin-left: 2.5em;
		}
	}
}