
.public-website{
	.demographic{
		background-color: #02bde7;
		background-image:url("/images/bg-angles.png");
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		position: relative;
		background-attachment: fixed;
	}

	.areas-of-help{
		text-align: center;
		margin-bottom: 5em;
		.img-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		h2{
			    margin-bottom: 15px;
			transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
		a{
			.overlap-img{
				transition: margin-top 0.2s;
				position: absolute;
				width: 100%;
				img{
					    width: 75%;
				}
			}
			&:hover{
				.overlap-img{
					    margin-top: -15px;
				}
				color: #CBDB2A;
				h2{
					color: #CBDB2A;
				}
					
				}
			
			color: rgba(33,43,54,0.6);
			i{
				color: #CBDB2A;
				margin-right: 5px;
			}
		}
	}


	@media(max-width:991px) {


			.areas-of-help {
				.img-wrap{
					img {
						max-width: 250px;
					}
				}
			} 

	}

	@media(max-width:767px) {


			.areas-of-help {
				.img-wrap{
					img {
						max-width: 200px;
					}
				}
			} 
		}

}