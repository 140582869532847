.featured-document{
	h3{
		margin-top: 0;
		margin-bottom: 0.7em;
	}
	.btn{
		margin-bottom: 30px;
	}
	.additional-report{
		padding-top: 20px;
		a{
			color: $brand-darkblue;
		}
	}

}

@media(max-width: 767px){
	.featured-document{
		.pdf{
			margin-bottom: 25px;
		}
	}
}