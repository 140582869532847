@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville");
.lead,
.intro {
  margin-top: 2em;
  font-size: 22px;
  line-height: 1.5em;
  color: #637381;
  margin-bottom: 2em;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300; }

.btn.btn-primary,
.btn.btn-default {
  background-color: #fff;
  border-radius: 20px;
  border: 0;
  padding: 10px 35px;
  font-size: 1.1em;
  transition: background-color 0.2s ease-in-out;
  color: #00BCE7;
  border: 1px solid #00BCE7;
  display: inline-block;
  font-size: 14px; }
  .btn.btn-primary.active, .btn.btn-primary.acive:hover, .btn.btn-primary.acive:focus, .btn.btn-primary:active, .btn.btn-primary:active:focus, .btn.btn-primary:hover, .btn.btn-primary:focus,
  .btn.btn-default.active,
  .btn.btn-default.acive:hover,
  .btn.btn-default.acive:focus,
  .btn.btn-default:active,
  .btn.btn-default:active:focus,
  .btn.btn-default:hover,
  .btn.btn-default:focus {
    background-color: #212B36;
    border-color: #212B36;
    box-shadow: none;
    color: #fff;
    outline: none; }

.btn.btn-solid {
  background-color: #fff;
  border-radius: 20px;
  border: 0;
  padding: 10px 35px;
  font-size: 1.1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #00BCE7;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }
  .btn.btn-solid.active, .btn.btn-solid.acive:hover, .btn.btn-solid.acive:focus, .btn.btn-solid:active, .btn.btn-solid:active:focus, .btn.btn-solid:hover, .btn.btn-solid:focus {
    background-color: #212B36;
    box-shadow: none;
    color: #fff;
    outline: none; }

.btn.btn-dark-solid {
  background-color: #fff;
  border-radius: 20px;
  border: 0;
  padding: 10px 35px;
  font-size: 1.1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #212B36;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }
  .btn.btn-dark-solid.active, .btn.btn-dark-solid.acive:hover, .btn.btn-dark-solid.acive:focus, .btn.btn-dark-solid:active, .btn.btn-dark-solid:active:focus, .btn.btn-dark-solid:hover, .btn.btn-dark-solid:focus {
    background-color: #00BCE7;
    box-shadow: none;
    color: #fff;
    outline: none; }

.align-center {
  display: flex;
  justify-content: center; }

.lead-in {
  padding-top: 30px;
  padding-bottom: 30px; }
  .lead-in p {
    margin-top: 1em;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-bottom: 1.5em; }
  .lead-in a::after {
    content: '\f30b';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400;
    padding-left: 10px; }

.align-bottom {
  display: flex;
  align-items: flex-end; }

@media (max-width: 580px) {
  .col-xxs-12 {
    width: 100%; }
  .lead,
  .intro {
    font-size: 20px; } }

.img-container {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 56.5%; }
  .img-container img,
  .img-container iframe {
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    height: 100%; }

.img-round {
  height: 400px;
  width: auto; }
  .img-round .img-container {
    height: 100%;
    width: auto;
    margin: auto;
    border-radius: 100%; }

@media (max-width: 991px) {
  .img-round .img-container {
    width: auto; } }

ol.feature-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 40px;
  margin-top: 30px; }
  ol.feature-list li {
    counter-increment: step-counter;
    position: relative;
    padding-left: 45px;
    margin-bottom: 25px; }
    ol.feature-list li::before {
      content: counter(step-counter);
      width: 30px;
      height: 30px;
      border-radius: 30px;
      color: #00BCE7;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2.5em;
      display: block;
      text-align: center;
      line-height: 30px;
      position: absolute;
      left: 0;
      top: 0; }

ul.ticklist {
  padding-left: 0;
  margin-bottom: 30px; }
  ul.ticklist li {
    display: inline-block;
    width: 50%;
    list-style: none;
    padding-left: 30px;
    position: relative;
    margin-bottom: 15px; }
    ul.ticklist li::before {
      content: "";
      font-family: "Font Awesome 5 Pro";
      font-size: 20px;
      line-height: 1em;
      margin-top: 3px;
      color: #00BCE7;
      position: absolute;
      left: 0;
      top: 0; }

.divider {
  border-top: 1px solid black; }

.blue-bg {
  background: #00BCE7;
  padding-top: 50px;
  padding-bottom: 60px; }
  .blue-bg h1 {
    color: #fff;
    text-align: center;
    font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 200; }
  .blue-bg h2 {
    color: #fff;
    font-size: 36px; }
  .blue-bg h3 {
    color: #212B36;
    font-size: 36px; }
  .blue-bg p {
    color: #fff; }

.darkblue-bg {
  background: #212B36;
  padding-top: 50px;
  padding-bottom: 60px; }
  .darkblue-bg h1 {
    color: #fff;
    text-align: center;
    font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 200; }
  .darkblue-bg h2 {
    color: #fff;
    font-size: 36px; }
  .darkblue-bg h3 {
    color: #fff;
    font-size: 36px; }
  .darkblue-bg p {
    color: #fff; }

.link-style {
  color: #212B36;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }
  .link-style i {
    color: #CBDB2A;
    padding-right: 5px; }

.content {
  padding-bottom: 50px;
  padding-top: 40px; }

@media (max-width: 991px) {
  .content {
    padding-top: 60px; }
    .content h1 {
      font-size: 2em; } }

.warning {
  color: #FA5E3F; }

.card-section {
  padding-top: 50px;
  padding-bottom: 60px; }
  .card-section.tint-bg:nth-child(odd) h1, .card-section.blue-bg:nth-child(odd) h1 {
    padding-top: 0;
    border-top: 0; }
  .card-section h1 {
    font-weight: 200;
    letter-spacing: 0.02em;
    color: #AAA59E;
    text-align: center;
    margin-bottom: 50px;
    font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif; }
  .card-section h3,
  .card-section h2 {
    margin-bottom: 2em; }
  .card-section:nth-child(odd) h1 {
    padding-top: 60px;
    border-top: 1px solid #F3F2EE; }

.page-content {
  padding: 40px 0; }

@media (min-width: 767px) {
  .d-flex {
    display: flex; } }

textarea {
  resize: vertical;
  min-height: 150px; }
  textarea.form-control {
    padding: 20px; }

input,
.form-control {
  box-shadow: none; }

label {
  display: block;
  color: #212B36;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-left: 0; }

.checkbox {
  position: relative; }
  .checkbox label::after {
    color: white; }
  .checkbox input:checked ~ label:before {
    background: #00BCE7; }

.radio label::after {
  background: #00BCE7;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  border: 3px #4edeff solid; }

.radio label::after {
  background: #00BCE7;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 3px #4edeff solid; }

input.required::after,
input[required]::after {
  content: '';
  font-family: 'Fontawesome';
  color: red;
  display: block;
  float: right; }

.errmsg {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #E6302F;
  padding: 10px 10px 8px;
  background: #EADFDF;
  margin-bottom: 15px; }

.file-upload {
  clear: both; }
  .file-upload > p {
    margin-bottom: 15px;
    font-size: 0.8em;
    font-style: italic;
    font-weight: bold;
    float: left; }
  .file-upload .btn-file {
    position: relative; }
    .file-upload .btn-file input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0; }

legend {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-weight: 700;
  line-height: 1.4em;
  color: #00BCE7;
  font-size: 1.8em;
  margin-bottom: 30px;
  border-bottom: 0; }
  legend ~ fieldset legend {
    font-size: 1.4em; }

#standard-form {
  margin-bottom: 3em; }
  #standard-form .form-control {
    background: rgba(243, 242, 238, 0.5);
    border-radius: 35px; }

.form-control {
  background: rgba(243, 242, 238, 0.5);
  border-radius: 0;
  border: 0;
  height: 50px; }
  .form-control ::-webkit-input-placeholder {
    color: #aaa; }
  .form-control ::-moz-placeholder {
    color: #aaa; }
  .form-control :-ms-input-placeholder {
    color: #aaa; }
  .form-control :-moz-placeholder {
    color: #aaa; }
  .form-control + small {
    display: block;
    margin-top: 10px;
    color: #00BCE7;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 0.8em;
    font-style: italic; }

button[data-form-add] {
  float: left;
  clear: both;
  margin-bottom: 30px; }

@media (max-width: 767px) {
  .form-control {
    height: 40px; } }

#application-form {
  margin-bottom: 30px; }

.ecs .form-group {
  max-width: 100%; }

.form-group {
  max-width: 50%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden; }
  .form-group .form-group {
    max-width: none; }
  .form-group.col-md-6 + .form-group:not(.col-md-6) {
    clear: both; }
  .form-group .remove {
    position: absolute;
    right: 20px;
    cursor: pointer;
    background: transparent;
    border: 0;
    transition: 0.2s ease-in-out color; }
    .form-group .remove:hover, .form-group .remove:focus {
      color: #212B36; }
  .form-group .consent-information {
    margin-top: 25px;
    display: block;
    font-weight: bold;
    color: #00BCE7;
    font-style: italic; }
  .form-group input:required {
    color: #dc3545; }
  .form-group.invalid input {
    border: #dc3545 1px solid;
    color: #dc3545; }
    .form-group.invalid input:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(220, 53, 69, 0.6); }
  .form-group.is-valid input {
    border: #28a745 1px solid;
    color: #28a745; }
    .form-group.is-valid input:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(40, 167, 69, 0.6); }

.invalid-feedback {
  color: #dc3545 !important;
  font-style: italic !important;
  font-weight: 500 !important;
  font-size: 85% !important; }

.four-col-inputs,
.three-col-inputs,
.half-col-inputs {
  overflow: hidden;
  clear: both;
  counter-reset: section; }
  .four-col-inputs .form-group,
  .three-col-inputs .form-group,
  .half-col-inputs .form-group {
    padding-right: 20px;
    float: left; }

.full-col-inputs .form-group {
  max-width: 100%; }

.half-col-inputs .form-group {
  width: 50%; }

.three-col-inputs .form-group {
  width: 33%; }

.four-col-inputs .form-group {
  width: 25%; }

@media (max-width: 991px) {
  .form-group {
    width: 100%;
    max-width: 100%; } }

@media (max-width: 767px) {
  .four-col-inputs .form-group {
    width: 50%; } }

@media (max-width: 580px) {
  .half-col-inputs .form-group {
    padding-right: 0;
    max-width: 100%;
    width: 100%; } }

.form-section {
  position: relative; }
  .form-section .panel {
    box-shadow: none; }
  .form-section legend ~ .form-group > legend,
  .form-section legend ~ .form-section > legend {
    padding-top: 15px;
    font-size: 18px; }
  .form-section legend + .form-section:first-of-type > legend,
  .form-section legend + .form-group:first-of-type > legend {
    padding-top: 0; }
  .form-section .form-section:last-of-type {
    padding-bottom: 20px; }
  .form-section .form-group legend,
  .form-section .form-group label {
    padding-top: 0px;
    color: #212B36;
    text-transform: uppercase;
    font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px; }
    .form-section .form-group legend ~ fieldset.form-group,
    .form-section .form-group label ~ fieldset.form-group {
      font-size: 18px; }
    .form-section .form-group legend .tooltip-toggle,
    .form-section .form-group label .tooltip-toggle {
      line-height: 0; }
      .form-section .form-group legend .tooltip-toggle:focus,
      .form-section .form-group label .tooltip-toggle:focus {
        outline: 0; }
  .form-section .panel-form {
    margin-bottom: 0; }
    .form-section .panel-form .panel-heading {
      position: relative;
      padding-left: 30px;
      padding-top: 0; }
      .form-section .panel-form .panel-heading legend {
        padding-top: 0;
        font-size: 1.1em; }
      .form-section .panel-form .panel-heading i {
        position: absolute;
        left: 0;
        top: 3px; }
    .form-section .panel-form .panel-collapse {
      overflow: hidden; }
      .form-section .panel-form .panel-collapse .checkbox:last-of-type,
      .form-section .panel-form .panel-collapse .radio:last-of-type,
      .form-section .panel-form .panel-collapse .form-group:last-of-type {
        margin-bottom: 30px; }

form p {
  color: #5a7382; }
  form p a {
    color: #5a7382;
    text-decoration: underline; }
    form p a:hover, form p a:focus, form p a:active {
      color: #212B36; }

.checkbox + .checkbox,
.radio + .radio {
  margin-top: 10px; }

.checkbox + .form-group {
  clear: both; }

.half-checkboxes .checkbox,
.half-checkboxes .radio,
.half-radios .checkbox,
.half-radios .radio {
  width: 50%;
  float: left; }

.third-checkboxes .checkbox,
.third-checkboxes .radio,
.third.radios .checkbox,
.third.radios .radio {
  width: 33%;
  float: left; }

.inline-checks .checkbox,
.inline-checks .radio,
.inline-radios .checkbox,
.inline-radios .radio {
  float: left; }
  .inline-checks .checkbox + .checkbox,
  .inline-checks .checkbox + .radio,
  .inline-checks .radio + .checkbox,
  .inline-checks .radio + .radio,
  .inline-radios .checkbox + .checkbox,
  .inline-radios .checkbox + .radio,
  .inline-radios .radio + .checkbox,
  .inline-radios .radio + .radio {
    margin-left: 20px; }

@media (max-width: 991px) {
  .form-group {
    width: 100%;
    max-width: 100%; } }

@media (max-width: 767px) {
  .four-col-inputs .form-group {
    width: 50%; } }

@media (max-width: 580px) {
  .half-col-inputs .form-group {
    max-width: 100%;
    width: 100%; } }

@media (max-width: 767px) {
  .third-checkboxes .form-group,
  .third.radios .form-group {
    width: 50%;
    float: none; } }

@media (max-width: 580px) {
  .three-col-inputs .form-group,
  .half-col-inputs .form-group,
  .four-col-inputs .form-group {
    width: 100%;
    float: none; } }

.custom-checkbox,
.custom-radio {
  margin-bottom: 20px; }
  .custom-checkbox:last-of-type,
  .custom-radio:last-of-type {
    margin-bottom: 0; }
  .custom-checkbox [type="radio"]:checked,
  .custom-checkbox [type="radio"]:not(:checked),
  .custom-checkbox [type="checkbox"]:checked,
  .custom-checkbox [type="checkbox"]:not(:checked),
  .custom-radio [type="radio"]:checked,
  .custom-radio [type="radio"]:not(:checked),
  .custom-radio [type="checkbox"]:checked,
  .custom-radio [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px; }
    .custom-checkbox [type="radio"]:checked + label,
    .custom-checkbox [type="radio"]:not(:checked) + label,
    .custom-checkbox [type="checkbox"]:checked + label,
    .custom-checkbox [type="checkbox"]:not(:checked) + label,
    .custom-radio [type="radio"]:checked + label,
    .custom-radio [type="radio"]:not(:checked) + label,
    .custom-radio [type="checkbox"]:checked + label,
    .custom-radio [type="checkbox"]:not(:checked) + label {
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      display: inline-block;
      line-height: 19px;
      padding-top: 6px;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700; }
      .custom-checkbox [type="radio"]:checked + label:before,
      .custom-checkbox [type="radio"]:not(:checked) + label:before,
      .custom-checkbox [type="checkbox"]:checked + label:before,
      .custom-checkbox [type="checkbox"]:not(:checked) + label:before,
      .custom-radio [type="radio"]:checked + label:before,
      .custom-radio [type="radio"]:not(:checked) + label:before,
      .custom-radio [type="checkbox"]:checked + label:before,
      .custom-radio [type="checkbox"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: #f6f6f6; }
      .custom-checkbox [type="radio"]:checked + label:after,
      .custom-checkbox [type="radio"]:not(:checked) + label:after,
      .custom-checkbox [type="checkbox"]:checked + label:after,
      .custom-checkbox [type="checkbox"]:not(:checked) + label:after,
      .custom-radio [type="radio"]:checked + label:after,
      .custom-radio [type="radio"]:not(:checked) + label:after,
      .custom-radio [type="checkbox"]:checked + label:after,
      .custom-radio [type="checkbox"]:not(:checked) + label:after {
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        position: absolute;
        line-height: 30px; }
  .custom-checkbox [type="radio"]:not(:checked) + label:after,
  .custom-checkbox [type="checkbox"]:not(:checked) + label:after,
  .custom-radio [type="radio"]:not(:checked) + label:after,
  .custom-radio [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0); }
  .custom-checkbox [type="radio"]:checked + label:after,
  .custom-checkbox [type="checkbox"]:checked + label:after,
  .custom-radio [type="radio"]:checked + label:after,
  .custom-radio [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  .custom-checkbox [type="radio"]:checked + label:after,
  .custom-checkbox [type="radio"]:not(:checked) + label:after,
  .custom-radio [type="radio"]:checked + label:after,
  .custom-radio [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: #212B36;
    top: 5px;
    left: 5px;
    border-radius: 100%; }
  .custom-checkbox [type="checkbox"]:checked + label:before,
  .custom-checkbox [type="checkbox"]:not(:checked) + label:before,
  .custom-radio [type="checkbox"]:checked + label:before,
  .custom-radio [type="checkbox"]:not(:checked) + label:before {
    border-radius: 0;
    background: #f6f6f6; }
  .custom-checkbox [type="checkbox"]:checked + label:after,
  .custom-checkbox [type="checkbox"]:not(:checked) + label:after,
  .custom-radio [type="checkbox"]:checked + label:after,
  .custom-radio [type="checkbox"]:not(:checked) + label:after {
    content: '';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    color: #212B36;
    top: -1px;
    left: 4px;
    border-radius: 3px; }

html {
  min-height: 100%; }

body {
  font-size: 16px;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  overflow-y: scroll; }

p {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1em;
  line-height: 1.5em;
  color: #5a7382; }

img {
  max-width: 100%; }

h1 {
  line-height: 1.3em;
  color: #00BCE7;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

h2, h3 {
  line-height: 1.4em;
  color: #212B36;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

h3 {
  margin-bottom: 1em; }

h4, h5, h6 {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

main h1, main h2, main h3, main h4, main h5, main h6 {
  margin-top: 20px; }

h1 {
  font-size: 3em; }

h2 {
  margin-bottom: 1em; }

strong {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #00BCE7; }

main {
  padding-top: 50px;
  padding-bottom: 50px; }
  main > .tint-bg:first-of-type, main > .card-section:first-of-type {
    margin-top: -50px;
    margin-bottom: -50px; }

a {
  color: #00BCE7;
  transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  a:hover, a:focus {
    color: #CBDB2A;
    text-decoration: none; }
  a.linkedin:hover, a.linkedin:focus {
    color: #0077B5; }
  a.twitter:hover, a.twitter:focus {
    color: #2CAAE1; }
  a.see-more {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #00BCE7; }

li {
  color: #5a7382; }

td {
  color: #5a7382; }

h4 {
  margin-bottom: 1.4em; }

hr {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }

blockquote {
  border-left: 0 none transparent;
  padding: 40px 0 25px;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1.2em;
  text-align: center; }
  blockquote p {
    font-weight: 500;
    font-size: 1em;
    line-height: 1.4em;
    margin-bottom: 30px;
    color: #00BCE7; }

@media (max-width: 767px) {
  h2 {
    margin-bottom: 25px; }
  blockquote {
    max-width: 100%;
    font-size: 1.4em;
    line-height: 1.3em; } }

.nav-trigger {
  display: none; }

.header-wrap {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0; }

.main-nav.navbar.navbar-default {
  border: 0 none transparent;
  margin-bottom: 0; }
  .main-nav.navbar.navbar-default .navbar-nav {
    margin-bottom: 0; }
    .main-nav.navbar.navbar-default .navbar-nav li > .active > a,
    .main-nav.navbar.navbar-default .navbar-nav li > .active > a:hover,
    .main-nav.navbar.navbar-default .navbar-nav li > .active > a:focus {
      background: transparent; }
    .main-nav.navbar.navbar-default .navbar-nav .dropdown {
      position: relative; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .close {
        display: none;
        position: absolute;
        opacity: 1;
        text-shadow: none; }
        .main-nav.navbar.navbar-default .navbar-nav .dropdown .close:hover {
          color: #00BCE7; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown.open .dropdown-menu {
        transform: translateX(0%); }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown.open > a,
      .main-nav.navbar.navbar-default .navbar-nav .dropdown.open a:hover,
      .main-nav.navbar.navbar-default .navbar-nav .dropdown.open a:focus {
        background-color: transparent; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li > a {
        padding: 10px 25px;
        font-size: 21px;
        white-space: nowrap;
        justify-content: left !important; }
        .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li > a:focus, .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li > a:hover {
          text-decoration: none;
          background-color: transparent; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li:hover {
        background-color: transparent;
        color: #00BCE7; }

@media (max-width: 991px) {
  .navbar-brand {
    overflow: hidden;
    display: block;
    float: none;
    padding: 0; }
  .page-wrapper {
    position: relative;
    z-index: 2;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99); }
  .main-nav.navbar.navbar-default .navbar-nav li {
    float: none; }
    .main-nav.navbar.navbar-default .navbar-nav li > a:hover,
    .main-nav.navbar.navbar-default .navbar-nav li > a:focus,
    .main-nav.navbar.navbar-default .navbar-nav li > a:hover:before,
    .main-nav.navbar.navbar-default .navbar-nav li > a:focus:before {
      color: #5a7382; }
  .main-nav.navbar.navbar-default .dropdown-menu {
    position: fixed;
    top: 0;
    transform: translateX(-120%);
    width: 90%;
    height: 100vh;
    transition: all 0.5s cubic-bezier(0.82, 0.01, 0.77, 0.78);
    text-align: left;
    z-index: 99;
    display: block;
    border: 0;
    overflow: auto;
    right: initial; }
    .main-nav.navbar.navbar-default .dropdown-menu > li > a {
      white-space: normal; }
    .main-nav.navbar.navbar-default .dropdown-menu:before {
      content: '';
      display: block;
      position: absolute; }
    .main-nav.navbar.navbar-default .dropdown-menu .close {
      display: block; }
  .navigation-is-open {
    overflow: hidden; }
    .navigation-is-open .page-wrapper {
      transform: translateX(100%); }
    .navigation-is-open .main-nav-trigger {
      transform: rotate(180deg); }
    .navigation-is-open .main-nav {
      visibility: visible; }
      .navigation-is-open .main-nav .main-navigation-wrapper {
        transform: translateX(0);
        transition: transform 0.5s cubic-bezier(0.82, 0.01, 0.77, 0.78);
        width: 100%; }
  .main-nav {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0; }
    .main-nav .main-navigation-wrapper {
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; }
  .main-nav-trigger {
    position: absolute;
    z-index: 11;
    display: block;
    overflow: hidden;
    text-indent: 100%; }
    .main-nav-trigger .main-nav-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: auto;
      right: auto;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      background-color: #ffffff; }
      .main-nav-trigger .main-nav-icon::before, .main-nav-trigger .main-nav-icon:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: inherit;
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform 0.3s, width 0.3s, top .3s; }
      .main-nav-trigger .main-nav-icon::before {
        transform-origin: right top;
        transform: translateY(-10px); }
      .main-nav-trigger .main-nav-icon::after {
        transform-origin: right bottom;
        transform: translateY(10px); }
    .no-touch .main-nav-trigger:hover .main-nav-icon::after {
      top: 2px; }
    .no-touch .main-nav-trigger:hover .main-nav-icon::before {
      top: -2px; }
    .navigation-is-open .main-nav-trigger {
      transform: rotate(180deg); }
      .navigation-is-open .main-nav-trigger .main-nav-icon::after,
      .navigation-is-open .main-nav-trigger .main-nav-icon::before {
        width: 50%;
        transition: transform 0.5s;
        transition: width 0.5s; }
      .navigation-is-open .main-nav-trigger .main-nav-icon::before {
        transform: rotate(45deg);
        top: 1px; }
      .navigation-is-open .main-nav-trigger .main-nav-icon::after {
        transform: rotate(-45deg);
        top: -1px; }
      .no-touch .navigation-is-open .main-nav-trigger:hover .main-nav-icon::after,
      .no-touch .navigation-is-open .main-nav-trigger:hover .main-nav-icon::before {
        top: 0; }
  .header-wrap {
    position: absolute;
    z-index: inherit; }
  .navbar.navbar-offcanvas.navbar-default {
    margin-top: 0;
    background: white; } }

@media (min-width: 768px) {
  .navbar-nav {
    float: none;
    margin: inherit; } }

.header-wrap {
  background-color: #212B36; }
  .header-wrap ~ .page-wrapper {
    margin-top: 145px; }

.main-nav.navbar.navbar-default {
  min-height: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #212B36; }
  .main-nav.navbar.navbar-default .navbar-header {
    width: 100%; }
  .main-nav.navbar.navbar-default .navbar-nav > li > a {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .main-nav.navbar.navbar-default .navbar-nav li {
    position: relative;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    transition: opacity 0.2s ease-in-out; }
    .main-nav.navbar.navbar-default .navbar-nav li.active > a:not(.btn) {
      background: transparent; }
    .main-nav.navbar.navbar-default .navbar-nav li.active::after {
      content: '';
      width: 100%;
      position: absolute;
      height: 4px;
      background: #ffffff; }
    .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn) {
      color: #fff;
      padding: 10px 20px 10px 20px;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
      opacity: hidden;
      display: block; }
      .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn):hover, .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn):focus, .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn):active {
        color: #00BCE7;
        background-color: transparent; }
      .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn):nth-last-child(1) {
        padding: 10px 0 10px 20px; }
  .main-nav.navbar.navbar-default .navbar-nav.opened li:not(.open) {
    opacity: 0.2; }
  .main-nav.navbar.navbar-default .dropdown::before {
    content: '\f0d7';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900;
    color: #00BCE7;
    display: block;
    position: absolute;
    bottom: 8px; }
  .main-nav.navbar.navbar-default .dropdown .close {
    font-size: 35px;
    top: 10px;
    right: 15px;
    color: #ffffff; }
    .main-nav.navbar.navbar-default .dropdown .close:hover {
      color: #00BCE7; }
  .main-nav.navbar.navbar-default .dropdown.open {
    background-color: #212B36;
    color: #fff; }
    .main-nav.navbar.navbar-default .dropdown.open a:not(.btn) {
      color: #fff; }
    .main-nav.navbar.navbar-default .dropdown.open .dropdown-menu {
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      z-index: 2; }
      .main-nav.navbar.navbar-default .dropdown.open .dropdown-menu li {
        opacity: 1; }
  .main-nav.navbar.navbar-default .dropdown .dropdown-menu {
    position: fixed;
    top: 133px;
    left: 0;
    width: 100%;
    background-color: #212B36;
    border: 0;
    overflow: auto;
    padding: 3em 0;
    z-index: 1;
    transition: box-shadow 0.5s 0.1s;
    box-shadow: none; }
    .main-nav.navbar.navbar-default .dropdown .dropdown-menu .dropdown-container {
      list-style: none;
      padding-left: 0; }
      .main-nav.navbar.navbar-default .dropdown .dropdown-menu .dropdown-container small {
        display: block;
        color: #576571; }
      .main-nav.navbar.navbar-default .dropdown .dropdown-menu .dropdown-container .dropdown-category {
        list-style: none;
        padding-left: 0; }
      .main-nav.navbar.navbar-default .dropdown .dropdown-menu .dropdown-container .card a h2 {
        color: #fff;
        font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400; }
    .main-nav.navbar.navbar-default .dropdown .dropdown-menu li {
      text-align: left;
      opacity: 0;
      transition: opacity 0.5s 0.1s;
      position: relative;
      padding: 0 30px 15px 0; }
      .main-nav.navbar.navbar-default .dropdown .dropdown-menu li > a:not(.btn) {
        padding: 10px 25px 10px 0;
        font-size: 1.1em;
        white-space: normal;
        justify-content: left;
        line-height: 1.4em;
        color: #fff; }
        .main-nav.navbar.navbar-default .dropdown .dropdown-menu li > a:not(.btn):focus, .main-nav.navbar.navbar-default .dropdown .dropdown-menu li > a:not(.btn):hover {
          color: #00BCE7; }
      .main-nav.navbar.navbar-default .dropdown .dropdown-menu li.dropdown-heading {
        font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 300;
        width: 100%;
        color: #C4CDD5;
        padding-top: 15px;
        font-size: 2.2em;
        padding-bottom: 12px;
        margin-bottom: 15px;
        flex-basis: 100%; }

.public-website .navigation-wrap form,
.toggle-wrap form {
  max-width: 250px; }
  .public-website .navigation-wrap form .form-control,
  .toggle-wrap form .form-control {
    height: auto;
    border-radius: 31px;
    background-color: #fff;
    padding: 15px; }
    .public-website .navigation-wrap form .form-control:first-child,
    .toggle-wrap form .form-control:first-child {
      border-top-right-radius: 31px;
      border-bottom-right-radius: 31px; }
  .public-website .navigation-wrap form .input-group,
  .toggle-wrap form .input-group {
    position: relative; }
  .public-website .navigation-wrap form .input-group-btn,
  .toggle-wrap form .input-group-btn {
    font-size: 1em;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 9; }
    .public-website .navigation-wrap form .input-group-btn button,
    .toggle-wrap form .input-group-btn button {
      background-image: none;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      border: 0;
      transition: background 0.2s ease-in-out, color;
      font-size: 14px; }
      .public-website .navigation-wrap form .input-group-btn button:hover, .public-website .navigation-wrap form .input-group-btn button:focus,
      .toggle-wrap form .input-group-btn button:hover,
      .toggle-wrap form .input-group-btn button:focus {
        background: #00ACD4;
        color: #fff; }

.navbar-brand .nav-logo {
  width: 90px; }

@media (min-width: 992px) {
  .shrink .toggle-wrap .tools .dropdown .dropdown-menu {
    top: 110px; }
  .shrink.header-wrap ~ .page-wrapper {
    margin-top: 112px; }
  .shrink .toggle-wrap .tools {
    padding-top: 15px;
    transition: all 0.1s; }
  .shrink .main-nav.navbar.navbar-default {
    margin-top: 15px;
    transition: margin 0.1s; }
    .shrink .main-nav.navbar.navbar-default .dropdown .dropdown-menu {
      top: 112px; }
      .shrink .main-nav.navbar.navbar-default .dropdown .dropdown-menu.open {
        display: block; }
  .navbar-nav {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding-left: 0;
    flex-wrap: wrap; }
  .navbar-brand {
    width: 175px;
    transition: all 0.1s;
    margin-top: 20px;
    position: absolute;
    margin-top: 30px;
    padding: 0;
    height: auto;
    z-index: 9; }
  .dropdown-container {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%; }
    .dropdown-container > li:not(.dropdown-heading):not([class^="flex-container"]) {
      flex-basis: 33%; }
    .dropdown-container .dropdown-category {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
      justify-content: space-between; }
      .dropdown-container .dropdown-category .list-heading {
        color: #00BCE7;
        font-size: 0.8em;
        flex-basis: 100%; }
    .dropdown-container .flex-container-66 {
      flex-basis: 66%; }
      .dropdown-container .flex-container-66 li:not(.list-heading) {
        flex-basis: 48%;
        padding-right: 50px;
        max-width: 300px; }
    .dropdown-container .flex-container-50 {
      flex-basis: 48%; }
      .dropdown-container .flex-container-50 li:not(.list-heading) {
        flex-basis: 48%;
        padding-right: 50px; }
    .dropdown-container .flex-container-33 {
      flex-basis: 33%; }
      .dropdown-container .flex-container-33 li:not(.list-heading) {
        flex-basis: 100%; }
    .dropdown-container .flex-container-25 {
      flex-basis: 25%; }
      .dropdown-container .flex-container-25 li:not(.list-heading) {
        flex-basis: 100%; }
  .toggle-wrap .tools {
    max-width: 520px;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    float: right; }
    .toggle-wrap .tools .dropdown-menu {
      position: fixed;
      top: 142px;
      left: 0;
      width: 100%;
      background-color: #212B36;
      border: 0;
      overflow: visible;
      border-radius: 0;
      padding-bottom: 40px; }
      .toggle-wrap .tools .dropdown-menu.open {
        display: block; }
      .toggle-wrap .tools .dropdown-menu .container {
        position: relative; }
        .toggle-wrap .tools .dropdown-menu .container:after {
          content: '\f0d8';
          font-family: "Font Awesome 5 Pro";
          font-style: normal;
          font-weight: 900;
          display: block;
          position: absolute;
          top: 0px;
          right: 40px;
          color: #212B36;
          transition: top 0.2s ease-in-out 0.5s; }
      .toggle-wrap .tools .dropdown-menu li {
        text-align: left; }
        .toggle-wrap .tools .dropdown-menu li small {
          display: block;
          color: #576571; }
        .toggle-wrap .tools .dropdown-menu li > a {
          padding: 10px 25px;
          font-size: 18px;
          white-space: normal;
          justify-content: left;
          line-height: 1.4em;
          color: #fff; }
          .toggle-wrap .tools .dropdown-menu li > a i {
            color: #CFE018; }
          .toggle-wrap .tools .dropdown-menu li > a:focus, .toggle-wrap .tools .dropdown-menu li > a:hover {
            color: #00BCE7; }
        .toggle-wrap .tools .dropdown-menu li:hover {
          background-color: transparent;
          color: #00BCE7; }
      .toggle-wrap .tools .dropdown-menu li {
        position: relative; }
        .toggle-wrap .tools .dropdown-menu li:first-of-type {
          font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: 300;
          width: 100%;
          padding-top: 15px;
          font-size: 1.7em;
          color: #fff;
          padding-bottom: 12px;
          margin-bottom: 15px; }
        .toggle-wrap .tools .dropdown-menu li:not(:first-of-type) {
          float: left;
          width: 33%;
          padding: 20px 0; }
          .toggle-wrap .tools .dropdown-menu li:not(:first-of-type) a:hover::after, .toggle-wrap .tools .dropdown-menu li:not(:first-of-type) a:focus::after {
            color: #00BCE7; }
        .toggle-wrap .tools .dropdown-menu li a {
          padding: 20px 0 20px 0; }
    .toggle-wrap .tools a:not(.btn) {
      color: #00BCE7;
      margin-right: 20px;
      white-space: nowrap; }
      .toggle-wrap .tools a:not(.btn):hover, .toggle-wrap .tools a:not(.btn):focus {
        color: #fff; } }

@media (max-width: 1200px) {
  .navbar-brand .nav-logo {
    width: 60px; } }

@media (max-width: 991px) {
  .public-website .navbar-brand .nav-logo {
    width: 65px; }
  .public-website .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn) {
    padding-left: 0; }
  .toggle-wrap {
    position: fixed;
    width: 100%;
    z-index: 9;
    padding-bottom: 20px;
    height: 70px;
    background: #212B36; }
    .toggle-wrap .tools {
      position: absolute;
      top: 18px;
      right: 55px;
      padding-top: 0; }
      .toggle-wrap .tools > a:not(.btn) {
        font-size: 1.7em;
        padding-right: 20px;
        float: left;
        color: #fff; }
        .toggle-wrap .tools > a:not(.btn):hover {
          color: #CBDB2A; }
      .toggle-wrap .tools .search-toggle {
        font-size: 1.7em;
        padding-top: 0;
        padding-right: 20px; }
  .navbar-brand {
    width: 116px;
    height: 48px;
    margin-top: 15px; }
  .page-wrapper {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    transform: translateZ(0);
    transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99); }
  .main-nav.navbar.navbar-default {
    background-color: #212B36; }
    .main-nav.navbar.navbar-default .main-navigation-wrapper {
      padding: 65px 50px;
      transform: translateX(0%);
      transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99); }
    .main-nav.navbar.navbar-default .navbar-nav li {
      float: none; }
      .main-nav.navbar.navbar-default .navbar-nav li > a:active,
      .main-nav.navbar.navbar-default .navbar-nav li > a:hover,
      .main-nav.navbar.navbar-default .navbar-nav li > a:focus,
      .main-nav.navbar.navbar-default .navbar-nav li > a:hover:before,
      .main-nav.navbar.navbar-default .navbar-nav li > a:focus:before {
        color: #00BCE7;
        background: transparent; }
      .main-nav.navbar.navbar-default .navbar-nav li a:not(.btn) {
        color: #fff; }
    .main-nav.navbar.navbar-default .navbar-nav .dropdown.open {
      background: transparent; }
    .main-nav.navbar.navbar-default .navbar-nav .dropdown li.dropdown-heading {
      font-size: 1.8em; }
    .main-nav.navbar.navbar-default .navbar-nav .dropdown li.list-heading {
      font-size: 0.9em;
      color: #00BCE7; }
    .main-nav.navbar.navbar-default .navbar-nav .dropdown li:not(:first-of-type) {
      width: 100%;
      padding: 10px 0 10px; }
    .main-nav.navbar.navbar-default .navbar-nav .dropdown li.close {
      width: auto;
      display: block;
      padding: 10px;
      transition: color 0.1s ease-in-out; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown li.close:after {
        content: ''; }
    .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu {
      top: 0;
      padding: 0 50px;
      margin-top: 65px; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu li {
        padding: 0; }
        .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu li > a:not(.btn) {
          font-size: 17px; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li > a:not(.btn) {
        padding: 10px 25px;
        font-size: 17px;
        line-height: 20px; }
        .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li > a:not(.btn):hover, .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li > a:not(.btn):focus {
          color: #5a7382; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu > li .card {
        padding: 0; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu:before {
        color: #5a7382;
        left: 19px;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #212B36;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu .sub-category {
        border-top: 1px solid #fff;
        padding-top: 20px;
        margin-top: 20px; }
      .main-nav.navbar.navbar-default .navbar-nav .dropdown .dropdown-menu .card {
        padding: 10px 0;
        border-top: 1px solid #fff;
        margin-top: 10px;
        margin-bottom: 0; }
  .main-nav-trigger {
    right: 15px;
    top: 20px;
    height: 25px;
    width: 30px;
    transition: transform 0.2s; }
    .main-nav-trigger .main-nav-icon {
      left: 50%;
      top: 50%;
      bottom: auto;
      right: auto;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      width: 30px;
      height: 2px;
      background-color: #fff;
      cursor: pointer; }
  .header-wrap {
    width: 100%;
    background-color: #212B36; }
    .header-wrap ~ .page-wrapper {
      margin-top: 65px; }
  .navigation-is-open {
    overflow: hidden; } }

@media (max-width: 768px) {
  .main-nav.navbar.navbar-default .dropdown-menu .card {
    padding: 0; } }

.public-website .header-wrap {
  background-color: #00BCE7;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .public-website .header-wrap + .page-wrapper {
    margin-top: 144px; }

.public-website .toggle-wrap .tools {
  font-size: 1.5em;
  position: relative;
  width: 40px;
  height: 60px;
  overflow: visible;
  z-index: 3; }
  .public-website .toggle-wrap .tools .dropdown {
    float: left; }
  .public-website .toggle-wrap .tools a {
    position: absolute;
    top: 86px;
    display: block; }
  .public-website .toggle-wrap .tools a:not(.btn) {
    color: #fff; }
    .public-website .toggle-wrap .tools a:not(.btn):hover, .public-website .toggle-wrap .tools a:not(.btn):focus {
      color: #212B36; }

.public-website .main-nav.navbar.navbar-default {
  background-color: #00BCE7;
  padding-bottom: 30px; }
  .public-website .main-nav.navbar.navbar-default .navbar-nav {
    padding-left: 175px;
    margin-top: 20px;
    padding-bottom: 0; }
    .public-website .main-nav.navbar.navbar-default .navbar-nav form {
      margin-left: auto; }
    .public-website .main-nav.navbar.navbar-default .navbar-nav li {
      margin-left: auto; }
      .public-website .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn):hover, .public-website .main-nav.navbar.navbar-default .navbar-nav li > a:not(.btn):focus {
        color: #212B36; }
    .public-website .main-nav.navbar.navbar-default .navbar-nav .dropdown.open {
      background-color: #00BCE7; }
    .public-website .main-nav.navbar.navbar-default .navbar-nav .dropdown-menu {
      padding-top: 0;
      background-color: #00BCE7; }

@media (max-width: 991px) {
  .public-website .header-wrap {
    background-color: #00BCE7; }
    .public-website .header-wrap + .page-wrapper {
      margin-top: 70px; }
  .public-website .main-nav.navbar.navbar-default .navbar-nav li {
    margin-bottom: 00px; }
  .public-website .main-nav.navbar.navbar-default .navbar-nav form {
    margin-left: 0;
    padding-top: 60px; }
  .public-website .main-nav.navbar.navbar-default .navbar-nav {
    padding-left: 0; }
  .public-website .toggle-wrap {
    background-color: #00BCE7; }
  .public-website .main-nav.navbar.navbar-default {
    background-color: #00BCE7; } }

.dropdown-backdrop {
  z-index: 0;
  display: none; }

.pagination-container {
  clear: both;
  text-align: center; }

.pagination > .disabled {
  display: none; }

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  border-radius: 50%;
  background-color: #00BCE7;
  border: 0;
  cursor: default; }

.pagination li a,
.pagination li span {
  background-color: transparent;
  color: #00BCE7;
  border: 1px solid #6B7A86;
  border-radius: 50%;
  margin-right: 10px;
  transition: background-color 0.2s ease-in-out;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }
  .pagination li a:last-of-type,
  .pagination li span:last-of-type {
    border-radius: 50%; }
  .pagination li a:hover, .pagination li a:focus,
  .pagination li span:hover,
  .pagination li span:focus {
    z-index: 2;
    color: #fff;
    background-color: #00BCE7;
    border-color: #00BCE7; }

.pagination li i {
  font-size: 12px;
  line-height: 12px; }

.tab-nav {
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef; }
  .tab-nav ul {
    list-style: none;
    padding-left: 0; }
    .tab-nav ul li {
      display: inline-block;
      margin-right: 15px;
      position: relative; }
      .tab-nav ul li a {
        color: #888; }
        .tab-nav ul li a:hover {
          color: #00BCE7; }
      .tab-nav ul li.active a {
        color: #00BCE7; }
      .tab-nav ul li + li {
        margin-left: 15px; }

#home-carousel {
  height: 600px;
  position: relative; }
  #home-carousel h1 {
    margin-top: 0;
    color: #fff;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  #home-carousel .carousel-inner {
    height: 100%; }
    #home-carousel .carousel-inner .item {
      height: 100%; }
      #home-carousel .carousel-inner .item:before {
        content: '';
        height: 100%;
        width: 33%;
        opacity: 0.82;
        background-image: linear-gradient(270deg, rgba(0, 76, 93, 0) 0%, #008dad 100%, #00BCE7 100%);
        display: block;
        position: absolute;
        z-index: 2; }
      #home-carousel .carousel-inner .item img {
        height: 100%;
        max-width: none;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%; }
    #home-carousel .carousel-inner .control-container {
      position: absolute;
      width: 100%;
      top: 30%; }
      #home-carousel .carousel-inner .control-container .carousel-control {
        background: transparent;
        background-image: none;
        width: 40px;
        height: 40px;
        opacity: 1;
        text-shadow: none;
        border: 1px solid #637381;
        border-radius: 50%;
        color: #fff;
        transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out;
        position: relative;
        display: inline-block; }
        #home-carousel .carousel-inner .control-container .carousel-control span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        #home-carousel .carousel-inner .control-container .carousel-control:hover {
          background: #fff;
          color: #00BCE7;
          border-color: #fff; }
        #home-carousel .carousel-inner .control-container .carousel-control.right {
          left: 50px; }
  #home-carousel .text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    padding-bottom: 75px;
    z-index: 3; }
    #home-carousel .text-container::before {
      content: '';
      position: absolute;
      display: block;
      height: 200%;
      width: 100%;
      z-index: 0;
      bottom: 0;
      background: #3c3c3c;
      background: linear-gradient(-180deg, rgba(104, 134, 146, 0) 30%, rgba(55, 78, 89, 0.6) 91%); }
    #home-carousel .text-container .btn-default {
      background-color: #fff;
      color: #00BCE7;
      margin-top: 20px; }
      #home-carousel .text-container .btn-default:hover, #home-carousel .text-container .btn-default:focus {
        background: #212B36;
        color: #fff; }

@media (min-width: 1850px) {
  #home-carousel .carousel-inner .item img {
    width: 100%;
    height: auto; } }

@media (max-width: 991px) {
  #home-carousel {
    height: 400px; }
    #home-carousel h1 {
      font-size: 2.4em; } }

@media (max-width: 768px) {
  #home-carousel {
    height: 400px; }
    #home-carousel .carousel-inner .control-container {
      top: 15px; }
    #home-carousel .carousel-inner .text-container {
      padding-bottom: 20px; }
      #home-carousel .carousel-inner .text-container h1 {
        color: #00BCE7;
        width: 85%; }
      #home-carousel .carousel-inner .text-container::before {
        height: 200%; }
    #home-carousel .carousel-inner .item:before {
      content: '';
      height: 100%;
      width: 100%;
      opacity: 0.85;
      background-image: linear-gradient(-180deg, rgba(254, 254, 254, 0) 30%, #212B36 70%);
      display: block;
      position: absolute;
      z-index: 2; } }

@media (max-width: 520px) {
  #home-carousel .carousel-inner .item img {
    height: 100%;
    width: auto;
    max-width: none; } }

.quote-carousel {
  position: relative; }
  .quote-carousel .carousel-inner {
    padding-top: 20px;
    padding-bottom: 50px; }
    .quote-carousel .carousel-inner blockquote {
      padding: 60px 0; }
    .quote-carousel .carousel-inner h6 {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%); }
    .quote-carousel .carousel-inner .item {
      padding-top: 25px; }
  .quote-carousel .carousel-control {
    text-shadow: none;
    font-size: 30px;
    font-weight: bold;
    height: 30px;
    width: 30px;
    bottom: 80px;
    top: initial; }
    .quote-carousel .carousel-control.right, .quote-carousel .carousel-control.left {
      background-image: none;
      color: #00BCE7;
      border: 1px solid #212B36;
      border-radius: 50%;
      font-size: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s ease-in-out; }
      .quote-carousel .carousel-control.right:hover, .quote-carousel .carousel-control.right:focus, .quote-carousel .carousel-control.left:hover, .quote-carousel .carousel-control.left:focus {
        border: #00BCE7;
        color: #fff;
        background: #00BCE7; }
    .quote-carousel .carousel-control.left {
      left: 46%;
      transform: translateX(-54%); }
    .quote-carousel .carousel-control.right {
      left: 54%;
      transform: translateX(-54%); }

main .carousel-inner .item {
  height: 0;
  padding-bottom: 56.5%; }
  main .carousel-inner .item img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

main .carousel-inner .carousel-control .far {
  position: absolute;
  top: 50%;
  transform: tranlsateY(-50%); }

.carousel-fade .carousel-inner .item {
  transition-property: opacity; }

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0; }

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0); }

.panel-group {
  margin-bottom: 40px; }

.panel-default {
  position: relative;
  border: 0;
  box-shadow: none; }
  .panel-default > .panel-heading {
    position: relative;
    border: 0;
    background: none;
    overflow: hidden;
    width: 100%;
    padding: 20px 0 25px;
    border-bottom: 1px #ededed solid; }
    .panel-default > .panel-heading .panel-title {
      color: #212B36;
      font-size: 20px; }
      .panel-default > .panel-heading .panel-title a {
        display: block;
        overflow: hidden;
        width: 100%; }
        .panel-default > .panel-heading .panel-title a:hover [class^="fa"], .panel-default > .panel-heading .panel-title a:focus [class^="fa"] {
          color: #CBDB2A; }
      .panel-default > .panel-heading .panel-title [class^="fa"] {
        position: absolute;
        right: 0;
        top: 25px;
        font-size: 20px;
        color: #212B36;
        transition: color 0.2s ease-in-out; }
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      padding-top: 25px;
      border-top: 0; }

.panel {
  box-shadow: 0; }
  .panel .panel-body {
    padding: 0; }
  .panel:nth-last-child(1) > .panel-heading {
    border-bottom: 0; }

.img-accordion {
  overflow: hidden;
  width: 100%;
  padding-top: 20px; }
  .img-accordion .images {
    position: relative; }
    .img-accordion .images .img-container {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      transition: opacity 0.15s ease-in-out; }
      .img-accordion .images .img-container.active {
        opacity: 1; }

@media (max-width: 991px) {
  .img-accordion .images {
    overflow: hidden;
    height: 400px;
    margin-bottom: 30px; }
    .img-accordion .images .img-container {
      height: 100%; } }

@media (max-width: 580px) {
  .img-accordion .images {
    height: 250px; } }

.custom-select {
  position: relative;
  float: left;
  margin-right: 20px;
  padding-right: 20px;
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 6px 0; }
  .custom-select select {
    display: none !important;
    /*hide original SELECT element:*/ }

.select-selected {
  background-color: transparent;
  color: #fff;
  /*style the arrow inside the select element:*/
  /*point the arrow upwards when the select box is open (active):*/ }
  .select-selected::after {
    position: absolute;
    content: "\f078";
    top: 8px;
    right: 25px;
    width: 0;
    height: 0;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 300; }
  .select-selected.select-arrow-active::after {
    content: '\f077';
    top: 7px; }

/*style the items (options), including the selected item:*/
.select-selected {
  font-size: 24px;
  color: #212B36;
  padding: 8px 15px;
  cursor: pointer;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

.select-items div {
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

/*style items (options):*/
.select-arrow-active + .select-items div {
  opacity: 1; }

.select-items {
  padding: 10px 0;
  position: absolute;
  width: 100%;
  background-color: #212B36;
  color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  overflow-x: hidden;
  max-height: 300px;
  overflow-y: scroll;
  display: none; }
  .select-items div {
    opacity: 0;
    transition: opacity 0.2s 0.1s;
    color: #fff; }

.select-items div:hover, .same-as-selected {
  background-color: #ffffff;
  color: #212B36; }

.attachments {
  margin: 30px 0; }
  .attachments .download-list {
    list-style: none;
    padding-left: 0; }
    .attachments .download-list li {
      margin-bottom: 25px;
      padding-left: 0;
      position: relative;
      border-bottom: #F2F1F0 solid 1px;
      padding-bottom: 20px; }
      .attachments .download-list li::before {
        position: absolute;
        content: '\f15b';
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: 900;
        right: 0;
        top: 0;
        color: #00BCE7;
        width: 35px;
        height: 35px;
        border-radius: 30px;
        line-height: 25px;
        font-size: 1.5em;
        text-align: center; }
      .attachments .download-list li a {
        color: #00BCE7;
        line-height: 1.5em; }
        .attachments .download-list li a:hover {
          color: #212B36; }
      .attachments .download-list li small {
        display: block;
        color: #888; }

.tooltip-toggle {
  background: none;
  border: 0; }

.tooltip-inner {
  max-width: 200px;
  padding: 8px 8px;
  color: #fff;
  text-align: center;
  background-color: #212B36;
  border-radius: 4px;
  border: 1px solid #212B36; }

.tooltip.left .tooltip-arrow {
  border-left-color: #212B36; }

.tooltip.right .tooltip-arrow {
  border-right-color: #212B36; }

.tooltip.top .tooltip-arrow {
  border-top-color: #212B36; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #212B36; }

.modal-backdrop {
  background: #00BCE7; }
  .modal-backdrop.in {
    opacity: 0.95; }

.modal-open {
  padding-right: 0; }

.modal.fade .modal-dialog {
  transform: translate(-50%, -65%);
  left: 50%;
  top: 50%;
  margin: 0; }

.modal.fade.in .modal-dialog {
  transform: translate(-50%, -50%); }

.modal .modal-header {
  padding: 0; }
  .modal .modal-header .close {
    opacity: 1;
    font-size: 35px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-shadow: none;
    color: #374e59;
    transition: opacity 0.2s ease-in-out; }
    .modal .modal-header .close:active, .modal .modal-header .close:focus {
      outline: 0;
      opacity: 1; }

.modal .modal-body {
  padding: 30px 0 0; }

.modal .modal-content {
  border-radius: 0;
  border: 0;
  box-shadow: none;
  padding: 30px 45px; }

#video-modal .modal-dialog {
  width: 75%; }

#video-modal .modal-content {
  padding-top: 0;
  padding-bottom: 56.8%;
  position: relative; }

#video-modal iframe {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%; }

.map-selector .fa-ul {
  margin-top: 30px; }
  .map-selector .fa-ul li {
    margin-bottom: 20px;
    font-size: 1.2em; }
    .map-selector .fa-ul li.hover a {
      color: #212B36; }
  .map-selector .fa-ul .fa-li .far {
    font-size: 1.6em; }

.map-selector a {
  color: #212B36; }
  .map-selector a:hover, .map-selector a:focus {
    color: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.southland #southland path,
.southland #southland polygon, .southland otago path,
.southland otago polygon, .southland canterbury path,
.southland canterbury polygon, .southland marlborough-nelson path,
.southland marlborough-nelson polygon, .southland wellington path,
.southland wellington polygon, .southland hawkes-bay path,
.southland hawkes-bay polygon, .southland middle-districts path,
.southland middle-districts polygon, .southland waikato path,
.southland waikato polygon, .southland auckland path,
.southland auckland polygon, .southland north-shore path,
.southland north-shore polygon, .southland northland path,
.southland northland polygon, otago #southland path,
otago #southland polygon, otago otago path,
otago otago polygon, otago canterbury path,
otago canterbury polygon, otago marlborough-nelson path,
otago marlborough-nelson polygon, otago wellington path,
otago wellington polygon, otago hawkes-bay path,
otago hawkes-bay polygon, otago middle-districts path,
otago middle-districts polygon, otago waikato path,
otago waikato polygon, otago auckland path,
otago auckland polygon, otago north-shore path,
otago north-shore polygon, otago northland path,
otago northland polygon, canterbury #southland path,
canterbury #southland polygon, canterbury otago path,
canterbury otago polygon, canterbury canterbury path,
canterbury canterbury polygon, canterbury marlborough-nelson path,
canterbury marlborough-nelson polygon, canterbury wellington path,
canterbury wellington polygon, canterbury hawkes-bay path,
canterbury hawkes-bay polygon, canterbury middle-districts path,
canterbury middle-districts polygon, canterbury waikato path,
canterbury waikato polygon, canterbury auckland path,
canterbury auckland polygon, canterbury north-shore path,
canterbury north-shore polygon, canterbury northland path,
canterbury northland polygon, marlborough-nelson #southland path,
marlborough-nelson #southland polygon, marlborough-nelson otago path,
marlborough-nelson otago polygon, marlborough-nelson canterbury path,
marlborough-nelson canterbury polygon, marlborough-nelson marlborough-nelson path,
marlborough-nelson marlborough-nelson polygon, marlborough-nelson wellington path,
marlborough-nelson wellington polygon, marlborough-nelson hawkes-bay path,
marlborough-nelson hawkes-bay polygon, marlborough-nelson middle-districts path,
marlborough-nelson middle-districts polygon, marlborough-nelson waikato path,
marlborough-nelson waikato polygon, marlborough-nelson auckland path,
marlborough-nelson auckland polygon, marlborough-nelson north-shore path,
marlborough-nelson north-shore polygon, marlborough-nelson northland path,
marlborough-nelson northland polygon, wellington #southland path,
wellington #southland polygon, wellington otago path,
wellington otago polygon, wellington canterbury path,
wellington canterbury polygon, wellington marlborough-nelson path,
wellington marlborough-nelson polygon, wellington wellington path,
wellington wellington polygon, wellington hawkes-bay path,
wellington hawkes-bay polygon, wellington middle-districts path,
wellington middle-districts polygon, wellington waikato path,
wellington waikato polygon, wellington auckland path,
wellington auckland polygon, wellington north-shore path,
wellington north-shore polygon, wellington northland path,
wellington northland polygon, hawkes-bay #southland path,
hawkes-bay #southland polygon, hawkes-bay otago path,
hawkes-bay otago polygon, hawkes-bay canterbury path,
hawkes-bay canterbury polygon, hawkes-bay marlborough-nelson path,
hawkes-bay marlborough-nelson polygon, hawkes-bay wellington path,
hawkes-bay wellington polygon, hawkes-bay hawkes-bay path,
hawkes-bay hawkes-bay polygon, hawkes-bay middle-districts path,
hawkes-bay middle-districts polygon, hawkes-bay waikato path,
hawkes-bay waikato polygon, hawkes-bay auckland path,
hawkes-bay auckland polygon, hawkes-bay north-shore path,
hawkes-bay north-shore polygon, hawkes-bay northland path,
hawkes-bay northland polygon, middle-districts #southland path,
middle-districts #southland polygon, middle-districts otago path,
middle-districts otago polygon, middle-districts canterbury path,
middle-districts canterbury polygon, middle-districts marlborough-nelson path,
middle-districts marlborough-nelson polygon, middle-districts wellington path,
middle-districts wellington polygon, middle-districts hawkes-bay path,
middle-districts hawkes-bay polygon, middle-districts middle-districts path,
middle-districts middle-districts polygon, middle-districts waikato path,
middle-districts waikato polygon, middle-districts auckland path,
middle-districts auckland polygon, middle-districts north-shore path,
middle-districts north-shore polygon, middle-districts northland path,
middle-districts northland polygon, waikato #southland path,
waikato #southland polygon, waikato otago path,
waikato otago polygon, waikato canterbury path,
waikato canterbury polygon, waikato marlborough-nelson path,
waikato marlborough-nelson polygon, waikato wellington path,
waikato wellington polygon, waikato hawkes-bay path,
waikato hawkes-bay polygon, waikato middle-districts path,
waikato middle-districts polygon, waikato waikato path,
waikato waikato polygon, waikato auckland path,
waikato auckland polygon, waikato north-shore path,
waikato north-shore polygon, waikato northland path,
waikato northland polygon, auckland #southland path,
auckland #southland polygon, auckland otago path,
auckland otago polygon, auckland canterbury path,
auckland canterbury polygon, auckland marlborough-nelson path,
auckland marlborough-nelson polygon, auckland wellington path,
auckland wellington polygon, auckland hawkes-bay path,
auckland hawkes-bay polygon, auckland middle-districts path,
auckland middle-districts polygon, auckland waikato path,
auckland waikato polygon, auckland auckland path,
auckland auckland polygon, auckland north-shore path,
auckland north-shore polygon, auckland northland path,
auckland northland polygon, north-shore #southland path,
north-shore #southland polygon, north-shore otago path,
north-shore otago polygon, north-shore canterbury path,
north-shore canterbury polygon, north-shore marlborough-nelson path,
north-shore marlborough-nelson polygon, north-shore wellington path,
north-shore wellington polygon, north-shore hawkes-bay path,
north-shore hawkes-bay polygon, north-shore middle-districts path,
north-shore middle-districts polygon, north-shore waikato path,
north-shore waikato polygon, north-shore auckland path,
north-shore auckland polygon, north-shore north-shore path,
north-shore north-shore polygon, north-shore northland path,
north-shore northland polygon, northland #southland path,
northland #southland polygon, northland otago path,
northland otago polygon, northland canterbury path,
northland canterbury polygon, northland marlborough-nelson path,
northland marlborough-nelson polygon, northland wellington path,
northland wellington polygon, northland hawkes-bay path,
northland hawkes-bay polygon, northland middle-districts path,
northland middle-districts polygon, northland waikato path,
northland waikato polygon, northland auckland path,
northland auckland polygon, northland north-shore path,
northland north-shore polygon, northland northland path,
northland northland polygon {
  fill: #5a7382; }

.map-container .region path,
.map-container .region polygon {
  cursor: pointer;
  transition: fill 0.1s ease-in-out; }

.map-container .region.hover path,
.map-container .region.hover polygon, .map-container .region:hover path,
.map-container .region:hover polygon, .map-container .region:focus path,
.map-container .region:focus polygon {
  fill: #5a7382; }

.card {
  margin-bottom: 50px;
  padding: 0 30px; }
  .card .img-container {
    margin-bottom: 20px;
    padding-bottom: 0;
    height: auto;
    min-height: 75px; }
    .card .img-container img {
      position: relative;
      width: auto;
      height: auto;
      transform: none;
      min-width: 0;
      left: 0;
      top: 0; }
  .card h2 {
    font-size: 1.5em;
    transition: color 0.2s ease-in-out;
    line-height: 1.4em;
    margin: 10px 0 15px; }
  .card p {
    margin-bottom: 20px; }
  .card a {
    font-weight: bold; }
    .card a:hover h2 {
      color: #00BCE7; }
  .card .category {
    margin-bottom: 5px; }
  .card small {
    color: #00BCE7; }
    .card small + .card-title,
    .card small + .card-title h2 {
      margin-top: 5px; }
  .card .img-container {
    margin-bottom: 15px; }
    .card .img-container + p {
      margin-bottom: 0; }
      .card .img-container + p + h3 {
        margin-top: 5px; }
  .card .card-tags {
    padding-left: 0;
    list-style: none; }
    .card .card-tags li {
      display: inline-block;
      color: #637381;
      margin-right: 10px; }
      .card .card-tags li.emphasis {
        color: #FA5E3F; }
      .card .card-tags li.early-bird {
        color: #06D6A0; }
  .card.card-news {
    padding: 0; }
    .card.card-news small {
      color: #AAA59E;
      text-transform: uppercase;
      font-size: 70%; }
    .card.card-news h2 {
      font-size: 20px; }
    .card.card-news a {
      color: #00BCE7; }
      .card.card-news a:hover {
        color: #212B36; }
    .card.card-news .img-container {
      padding-bottom: 100%;
      margin-bottom: 0;
      min-height: 0;
      height: 0; }
      .card.card-news .img-container img {
        max-width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        height: 100%; }
    .card.card-news small {
      color: #AAA59E; }
      .card.card-news small + .card-title,
      .card.card-news small + .card-title h2 {
        margin-top: 5px; }
    .card.card-news .card-block {
      background: #fff;
      padding: 15px 15px 40px;
      margin-bottom: 40px; }
  .card .date {
    color: #5a7382;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .card.card-resources {
    padding: 0; }
    .card.card-resources h2 {
      color: #00BCE7;
      font-size: 18px;
      margin-bottom: 15px; }
    .card.card-resources img {
      width: 100%; }
    .card.card-resources .img-container {
      padding-bottom: 100%;
      margin-bottom: 0;
      min-height: 0;
      height: 0; }
      .card.card-resources .img-container img {
        position: absolute; }
    .card.card-resources small {
      color: #AAA59E;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 70%; }
      .card.card-resources small + .card-title,
      .card.card-resources small + .card-title h2 {
        margin-top: 5px; }
    .card.card-resources .category {
      margin-bottom: 20px; }
    .card.card-resources .card-block {
      min-height: 250px;
      background: #fff;
      padding: 15px 15px 40px;
      margin-bottom: 40px; }
    .card.card-resources .information {
      color: #AAA59E;
      font-size: 14px;
      padding: 0;
      list-style: none; }
  .card.card-magazine {
    padding: 0; }
    .card.card-magazine h2 {
      color: #00BCE7;
      font-size: 1.4em;
      margin-bottom: 15px; }
    .card.card-magazine .img-container {
      padding-bottom: 100%;
      margin-bottom: 0;
      min-height: 0;
      height: 0; }
      .card.card-magazine .img-container img {
        position: absolute; }
    .card.card-magazine img {
      width: 100%; }
    .card.card-magazine small {
      color: #AAA59E;
      font-weight: bold; }
      .card.card-magazine small + .card-title,
      .card.card-magazine small + .card-title h2 {
        margin-top: 5px; }
    .card.card-magazine .category {
      margin-bottom: 20px; }
    .card.card-magazine .card-block {
      background: #fff;
      padding: 15px 15px 40px;
      margin-bottom: 40px; }
    .card.card-magazine .information {
      color: #AAA59E;
      font-size: 14px;
      padding: 0;
      list-style: none; }
  .card.card-jobs .img-container {
    margin-bottom: 25px;
    min-height: auto;
    height: 55px; }
    .card.card-jobs .img-container img {
      height: 100%; }
  .card.card-jobs a {
    font-weight: bold;
    color: #212B36; }
    .card.card-jobs a:hover {
      color: #00BCE7; }
  .card.card-events a {
    font-style: normal;
    font-weight: 400;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    color: #212B36; }
    .card.card-events a:hover {
      color: #00BCE7; }

@keyframes scale-down-center {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.85); }
  100% {
    transform: scale(1); } }

.share-container {
  position: relative;
  padding-top: 30px; }
  .share-container .share-toggle {
    border: 0;
    background: #e6e5dd;
    color: #212B36;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 14px;
    z-index: 1;
    position: absolute;
    right: 0; }
  .share-container ul {
    display: inline-block;
    list-style: none;
    background: #f3f3f3;
    margin-left: -35px;
    z-index: 0;
    position: absolute;
    border-radius: 35px;
    padding-left: 0;
    padding-top: 40px;
    right: 0; }
    .share-container ul li {
      display: block;
      padding: 7px 10px; }
      .share-container ul li a {
        color: #5a7382; }
        .share-container ul li a:hover, .share-container ul li a:active {
          color: #212B36; }
        .share-container ul li a:focus {
          animation: scale-down-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (max-width: 1199px) {
  .share-container {
    padding-bottom: 30px; }
    .share-container .share-toggle {
      left: 0px;
      right: initial;
      position: absolute; }
    .share-container ul {
      left: 35px;
      padding-left: 35px;
      padding-top: 0;
      right: initial; }
      .share-container ul li {
        display: inline-block; } }

.tint-bg {
  background-color: #F3F2EE;
  padding: 50px 0; }

.subscription-form {
  padding-top: 40px; }
  .subscription-form label {
    font-size: 14px;
    color: #aab5af;
    font-style: italic;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    padding-left: 20px; }
  .subscription-form .form-control {
    border-radius: 30px;
    padding-left: 20px;
    border: 0;
    border-bottom: 1px;
    height: 45px;
    background: #ffffff; }
  .subscription-form .btn {
    margin-top: 20px; }

footer {
  padding: 50px 0 20px;
  background: #212B36;
  position: relative;
  z-index: 3; }
  footer .footer-logo {
    margin-bottom: 20px; }
    footer .footer-logo img {
      width: 100px; }
  footer .movement-logo img {
    width: 80px; }
  footer a {
    color: #fff;
    display: inline-block;
    margin-right: 20px; }
    footer a:hover, footer a:focus {
      color: #00BCE7; }
    footer a i {
      font-weight: normal; }
  footer ul {
    list-style: none;
    padding-left: 0; }
    footer ul li {
      display: inline-block;
      margin-right: 30px; }
      footer ul li a {
        font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400; }
  footer .footer-nav {
    border-bottom: 1px solid #4A5054;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 30px; }
  footer .social-nav {
    font-size: 1.5em; }
  footer p {
    font-size: 0.8em;
    color: #73838b; }
    footer p small a {
      color: #73838b; }
  footer .legal {
    float: right;
    color: #fff; }

@media (max-width: 991px) {
  footer ul li a {
    font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
    font-weight: 300;
    margin-bottom: 10px; }
  footer .social-nav {
    font-size: 1.2em;
    margin-right: 15px; } }

#search-modal {
  margin-bottom: 2em;
  margin-top: 1em; }
  #search-modal .modal-header {
    border-bottom: 0; }
  #search-modal .modal-content {
    background-color: transparent; }
  #search-modal .input-group-addon {
    border: none; }
  #search-modal .form-control {
    padding: 0 0 0 10px;
    background-color: transparent;
    padding: 30px 8px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px #212B36 solid;
    font-size: 2.4em;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300; }
    #search-modal .form-control:focus {
      box-shadow: none;
      outline: 0; }
  #search-modal form {
    margin-top: 2em; }
    #search-modal form .input-group-addon {
      background: transparent; }
      #search-modal form .input-group-addon button {
        width: 100%;
        height: 100%;
        border: 0;
        font-size: 2em;
        background: transparent;
        color: #212B36;
        transition: color 0.2s ease-in; }
        #search-modal form .input-group-addon button:hover {
          color: #5a7382; }

.to-edge {
  position: relative;
  background-color: #f5f7f6; }
  .to-edge:nth-of-type(2n) {
    background-color: #fff; }
  .tint-bg + .to-edge {
    background: #fff; }
    .tint-bg + .to-edge:nth-of-type(2n) {
      background-color: #f5f7f6; }
  .to-edge .container {
    display: flex;
    align-items: center; }
  .to-edge .to-edge-image {
    position: absolute;
    top: 0; }
    .to-edge .to-edge-image.right {
      right: 0; }
    .to-edge .to-edge-image.left {
      left: 0; }
  .to-edge .img-container {
    height: 100%;
    padding-bottom: 0;
    position: static; }
  .to-edge .text {
    padding: 100px 60px; }
  .to-edge + .tint-bg {
    margin-top: 0; }

@media (max-width: 991px) {
  .to-edge .text {
    padding: 50px 30px; } }

@media (max-width: 767px) {
  .to-edge .to-edge-image {
    position: relative; }
    .to-edge .to-edge-image .img-container {
      height: 100%;
      padding-bottom: 56.5%;
      position: relative; } }

.cta {
  text-align: center; }
  .cta h1 {
    text-align: center;
    margin-bottom: 1em; }
  .cta h2 {
    margin-bottom: 1em;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300; }

.no-banner {
  height: 10px;
  background: #00BCE7; }

.branch-header {
  background-color: #00BCE7;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 100px 0 50px;
  position: relative;
  min-height: 400px;
  display: flex;
  align-items: flex-end; }
  .branch-header.light-bg h1 {
    color: #212B36; }
  .branch-header.light-bg .countdown div {
    color: #212B36; }
    .branch-header.light-bg .countdown div span {
      color: #212B36;
      border-color: #212B36; }
  .branch-header.light-bg .event-details ul {
    color: #212B36; }
  .branch-header span.subheading {
    color: #fff;
    font-size: 28px;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
    .branch-header span.subheading + h1 {
      margin-top: 0; }
  .branch-header h1 {
    color: #fff;
    margin-bottom: 20px; }
  .branch-header .event-details ul {
    color: #fff;
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px; }
    .branch-header .event-details ul li {
      color: #ffffff; }
  .branch-header:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.82;
    background-image: linear-gradient(270deg, rgba(0, 76, 93, 0) 0%, #00BCE7 100%); }

.search-banner {
  padding-top: 50px;
  padding-bottom: 60px; }
  .search-banner h1 {
    text-align: center;
    font-weight: 200; }
  .search-banner form {
    padding-top: 30px; }
    .search-banner form .form-section {
      display: flex;
      justify-content: center; }
    .search-banner form .form-group {
      margin-top: 20px;
      max-width: 50%;
      margin-bottom: 40px;
      position: relative;
      width: 33%;
      float: left;
      padding: 0 20px;
      overflow: visible; }
      .search-banner form .form-group label {
        color: rgba(33, 43, 54, 0.4); }
    .search-banner form .form-control {
      background-color: transparent;
      border-bottom: 1px #ffffff solid;
      font-weight: bold;
      color: #212B36;
      font-size: 24px;
      background: #00BCE7;
      border-bottom: 1px solid #fff;
      font-size: 26px;
      margin-bottom: 30px;
      min-height: 52px; }
    .search-banner form ::-webkit-input-placeholder {
      color: #207D98; }
    .search-banner form ::-moz-placeholder {
      color: #207D98; }
    .search-banner form :-ms-input-placeholder {
      color: #207D98; }
    .search-banner form :-moz-placeholder {
      color: #207D98; }
  .search-banner h1 {
    text-align: center; }
  .search-banner + .tint-bg {
    margin-top: -20px; }
  .search-banner .form-group {
    margin-top: 20px;
    max-width: 50%;
    margin-bottom: 40px;
    position: relative;
    width: 33%;
    float: left;
    padding: 0 20px; }
    .search-banner .form-group label {
      color: rgba(33, 43, 54, 0.4); }
    .search-banner .form-group .form-control {
      background-color: transparent;
      border-bottom: 1px #ffffff solid;
      font-weight: bold;
      color: #212B36;
      font-size: 24px; }

@media (max-width: 580px) {
  .search-banner form .form-section {
    flex-direction: column; }
  .search-banner form .form-group {
    width: 100%;
    margin: 0 0 30px;
    max-width: 100%; }
    .search-banner form .form-group .form-control {
      margin-bottom: 0; } }

@media (min-width: 767px) {
  .search-banner .form-section {
    display: flex;
    justify-content: center; } }

@media (max-width: 991px) {
  .search-banner .form-section .form-group {
    width: 50%; } }

@media (max-width: 767px) {
  .search-banner form {
    padding-top: 15px; }
    .search-banner form .form-control {
      margin-bottom: 20px; }
    .search-banner form .form-section {
      display: inline-block; }
      .search-banner form .form-section .form-group {
        max-width: 100%;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px; }
    .search-banner form .btn-default {
      margin-top: 30px; } }

.page-header {
  background-color: #00BCE7;
  padding-top: 75px;
  padding-bottom: 75px;
  border-bottom: 0;
  margin: 0; }
  .page-header h1 {
    text-align: center;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.8em;
    color: #fff; }
  .page-header p {
    text-align: center;
    color: #212B36;
    font-size: 1.5em;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300; }
  .page-header + .tint-bg,
  .page-header + .blue-bg {
    margin-top: -20px; }

.content-header {
  position: relative;
  min-height: 600px;
  background-repeat: no-repeat;
  background-color: #00bce7;
  background-size: cover;
  padding: 50px 0 50px;
  position: relative; }
  .content-header .text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    z-index: 3; }
    .content-header .text-container span.subheading {
      font-size: 28px;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700; }
      .content-header .text-container span.subheading + h1 {
        margin-top: 0; }
    .content-header .text-container .btn-solid {
      border-radius: 35px;
      padding: 15px 40px; }
    .content-header .text-container h1 {
      margin-bottom: 25px;
      text-align: left;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 2.8em; }
    .content-header .text-container h1, .content-header .text-container .subheading {
      color: #fff; }
    .content-header .text-container:before {
      content: '';
      position: absolute;
      display: block;
      height: 200%;
      width: 100%;
      z-index: 0;
      bottom: 0;
      background-image: linear-gradient(-180deg, rgba(33, 43, 54, 0) 20%, rgba(33, 43, 54, 0.9) 99%); }
    .content-header .text-container .img-container {
      height: 100%; }
      .content-header .text-container .img-container img {
        width: 100%;
        position: absolute;
        transform: translate(-50%, -20%);
        left: 50%;
        top: 20%; }

@media (max-width: 991px) {
  .page-header {
    margin-top: 0px;
    padding-top: 75px; }
  .content-header {
    min-height: 400px; } }

@media (max-width: 767px) {
  .page-header {
    margin-top: 0px;
    padding-top: 40px;
    padding-bottom: 40px; } }

.hero .page-header {
  padding-bottom: 50px; }

.hero .img-container {
  width: 100%; }

.hero .video {
  margin-bottom: 40px;
  cursor: pointer; }
  .hero .video .overlay {
    z-index: 2;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgba(78, 78, 78, 0.5);
    transition: opacity 0.2s ease-in-out; }
  .hero .video .fa-play {
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    font-size: 8em;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease-in-out;
    opacity: 1; }
  .hero .video:hover .overlay {
    opacity: 0.5; }
  .hero .video:hover .fa-play {
    opacity: 0.5; }

.hero.video .page-header {
  padding-bottom: 70px; }

@media (max-width: 991px) {
  .hero .page-header {
    padding-top: 40px; } }

#meganav {
  position: absolute;
  top: 62px;
  background: #5a7382;
  right: 0;
  width: 100%;
  transition-property: height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  min-height: 0;
  border: 0; }
  #meganav::after, #meganav::before {
    display: block;
    height: 100%;
    background: #5a7382;
    content: '';
    position: absolute;
    top: 0;
    width: 30px; }
  #meganav:after {
    left: -30px; }
  #meganav:before {
    right: -30px; }
  #meganav h1 {
    color: #ffffff;
    border-bottom: 0; }
  #meganav h2 {
    font-size: 1.4em;
    color: #ffffff;
    border-bottom: 2px solid #fff;
    padding-bottom: 20px;
    margin-bottom: 0; }
  #meganav h3 {
    font-size: 1.2em;
    color: #ffffff; }
  #meganav p {
    color: #fff; }
  #meganav a {
    color: #ffffff;
    padding: 6px 0; }
    #meganav a:hover, #meganav a:focus {
      background-color: transparent;
      color: #00BCE7; }
  #meganav .navbar-nav > li {
    float: none;
    padding: 6px 0; }
  #meganav .arrow {
    position: absolute;
    display: block;
    padding: 3px;
    transform: rotate(-135deg);
    background: #5a7382;
    border: solid #5a7382;
    border-width: 0 10px 10px 0;
    visibility: hidden;
    right: 42px;
    top: 8px;
    transition: top 0.3s ease-in-out, visibility 0.1s ease-in 0.1s; }
  #meganav.open .arrow {
    top: -4px;
    visibility: visible; }
  #meganav .user-type {
    line-height: 25px;
    color: #ffffff;
    margin: 30px 0 25px; }
  #meganav .mega-nav {
    overflow: scroll; }
    #meganav .mega-nav .navbar-nav li {
      float: none;
      padding: 6px 0; }
    #meganav .mega-nav ul ul {
      list-style: none;
      padding-left: 0; }

@media (min-width: 992px) {
  #meganav .mega-nav {
    height: 100%; }
    #meganav .mega-nav .container {
      margin-bottom: 100px;
      padding-top: 50px; }
    #meganav .mega-nav .fa-close {
      display: none; }
  #meganav.closed {
    height: 0; }
  #meganav.open {
    height: 100vh;
    width: 100%; }
    #meganav.open .arrow {
      top: -4px;
      visibility: visible; } }

@media (max-width: 991px) {
  #meganav {
    top: 62px;
    min-height: 100vh;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out; }
    #meganav.closed {
      right: -110%; }
    #meganav.open {
      right: 0; }
      #meganav.open .arrow {
        top: -8px;
        right: 14px; }
    #meganav .arrow {
      right: 14px; }
    #meganav .mega-nav {
      overflow: hidden; }
      #meganav .mega-nav .close {
        color: #ffffff;
        opacity: 1;
        position: absolute;
        top: 15px;
        right: 30px;
        font-size: 35px; }
      #meganav .mega-nav .dropdown {
        top: 77px;
        right: -100%;
        width: 90%;
        padding: 30px;
        height: 90%;
        background-color: #212B36;
        position: fixed;
        z-index: 2;
        transition: right 0.2s ease-in-out; }
        #meganav .mega-nav .dropdown.open {
          right: 0; } }

#schedule-container-outer {
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  border-radius: 5px; }

#schedule-container-inner {
  overflow: hidden;
  position: relative;
  padding-left: 65px;
  padding-top: 50px; }
  #schedule-container-inner .times-container {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-top: 50px;
    top: 0;
    left: 0;
    overflow: hidden; }
  #schedule-container-inner #times {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%; }
    #schedule-container-inner #times .line {
      position: relative;
      width: 100%;
      height: 90px;
      border-top: 1px solid #e4e4e4; }
      #schedule-container-inner #times .line::after {
        content: attr(data-time);
        position: absolute;
        top: 0;
        left: 0;
        width: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-right: 1px solid #e4e4e4;
        color: #00BCE7;
        text-align: center; }
  #schedule-container-inner .room-titles-container {
    width: 100%;
    overflow: hidden;
    padding-left: 65px;
    position: absolute;
    top: 0;
    height: 100%; }
  #schedule-container-inner #room-titles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: visible;
    padding-left: 55px; }
    #schedule-container-inner #room-titles .room-name {
      display: block;
      position: absolute;
      width: 180px;
      height: 50px;
      text-align: center;
      border-left: 1px solid #e4e4e4;
      border-bottom: 1px #e4e4e4 solid;
      background: #fff;
      z-index: 9; }
      #schedule-container-inner #room-titles .room-name p {
        color: #292F36;
        margin-top: 12px; }

#schedule::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
  background: #F5F6F6; }

#schedule::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #D8D8D8; }

#schedule {
  position: relative;
  background: transparent;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  cursor: -webkit-grabbing;
  overflow-x: scroll;
  z-index: 1; }
  #schedule .room {
    text-align: center;
    position: absolute;
    width: 180px;
    height: 100%; }
    #schedule .room.active {
      display: block;
      visibility: visible; }
    #schedule .room h1 {
      font-size: 1em;
      margin: 0 auto 3px;
      color: #00BCE7;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 400; }
    #schedule .room .speaker {
      font-size: 0.9em;
      margin-bottom: 3px; }
    #schedule .room time {
      color: #292F36;
      font-size: 0.8em;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700; }
    #schedule .room .event {
      border: 1px solid rgba(196, 205, 213, 0.4);
      min-width: 180px;
      background: #F6F6F7;
      z-index: 2;
      position: absolute; }
      #schedule .room .event a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 15px 5px; }
    #schedule .room .concussion a {
      border-left: 5px solid #26b8e6; }
    #schedule .room .physical a {
      border-left: 5px solid #b8082d; }
    #schedule .room .musculoskeletal a {
      border-left: 5px solid #f6a100; }
    #schedule .room .acuteCare a {
      border-left: 5px solid #c3d500; }
    #schedule .room .modelsOfCare a {
      border-left: 5px solid #000000; }
    #schedule .room .other a {
      border-left: 5px solid #F6F6F7; }
    #schedule .room .time-short .speaker,
    #schedule .room .time-short time,
    #schedule .room .time-Vshort .speaker,
    #schedule .room .time-Vshort time {
      display: none; }
    #schedule .room .time-Vshort a {
      padding: 0 5px; }
    #schedule .room .time-Vshort h1 {
      font-size: 12px; }
    #schedule .room .time-med {
      height: 60px; }
      #schedule .room .time-med .speaker {
        display: none; }
  #schedule .timetable {
    position: relative;
    height: 100%;
    width: auto;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    transition: opacity .3s ease-in, visibility 0s; }
    #schedule .timetable.active {
      opacity: 1;
      visibility: visible;
      overflow: visible; }

#schedule-controls {
  width: 100%;
  padding-bottom: 30px;
  clear: both; }
  #schedule-controls .room-filters {
    z-index: 4; }
    #schedule-controls .room-filters .btn {
      position: relative;
      margin-right: 25px; }
      #schedule-controls .room-filters .btn:not(.active):not(:hover):not(:focus) {
        background: #C4CDD5;
        color: #fff;
        border: 1px solid #C4CDD5; }
      #schedule-controls .room-filters .btn:after {
        content: '\f0d7';
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: 900;
        color: #C4CDD5;
        font-size: 2.2em;
        transition: color 0.2s ease-in-out, bottom 0.2s ease-in-out;
        z-index: -1; }
      #schedule-controls .room-filters .btn:hover::after, #schedule-controls .room-filters .btn:focus::after, #schedule-controls .room-filters .btn.active::after {
        color: #212B36;
        bottom: -25px; }

.break {
  position: absolute;
  background: #E4E4E6;
  width: 100%;
  height: 90px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  z-index: 1; }
  .break p {
    margin-bottom: 0;
    color: #2d2d2d; }
  .break.lunch {
    top: 360px; }

.key {
  display: inline-block; }
  .key p {
    padding: 0 15px; }
  .key::before {
    width: 5px;
    height: 20px;
    content: '';
    display: inline-block;
    float: left; }
  .key.event-type-1::before {
    background-color: #26b8e6; }
  .key.event-type-2::before {
    background-color: #b8082d; }
  .key.event-type-3::before {
    background-color: #f6a100; }
  .key.event-type-4::before {
    background-color: #c3d500; }
  .key.event-type-5::before {
    background-color: #000000; }
  .key.event-type-6::before {
    background-color: #F6F6F7; }

@media (max-width: 767px) {
  #schedule-container-inner #times .line::after {
    left: 0; }
  #schedule-controls .room-filters .btn {
    margin: 5px 5px;
    padding: 8px 15px; }
  .key {
    display: inline-block; }
    .key p {
      padding: 0 15px;
      margin: 0 8px; } }

.single-col,
.two-col {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }
  .single-col + footer,
  .two-col + footer {
    margin-top: 40px; }

@media (max-width: 767px) {
  .single-col,
  .two-col {
    padding-top: 10px !important;
    padding-bottom: 10px !important; } }

@keyframes outToTop {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

@keyframes inFromBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

#keyword-search .input-group .form-control {
  font-size: 18px; }

.search-options-container {
  margin: 60px 0; }

.search-banner-content {
  background-image: linear-gradient(-180deg, rgba(254, 254, 254, 0) 37%, #107590 100%);
  position: relative; }
  .search-banner-content h1 {
    text-align: center; }
  .search-banner-content:after {
    content: '\f0d7';
    position: absolute;
    color: #007591;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900;
    bottom: -37px;
    font-size: 3em;
    z-index: 1; }

#multi-step-filter {
  position: relative; }
  #multi-step-filter .filter-steps {
    overflow: hidden; }
    #multi-step-filter .filter-steps .step {
      padding: 10px 15px;
      width: 50%;
      float: left;
      color: #035365;
      text-align: center;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
      font-size: 1em; }
      #multi-step-filter .filter-steps .step.disabled {
        opacity: 0.6;
        border-bottom: #6ad2eb; }
      #multi-step-filter .filter-steps .step:not(.disabled) {
        cursor: pointer;
        border-bottom: 3px solid #98def1; }
        #multi-step-filter .filter-steps .step:not(.disabled):hover, #multi-step-filter .filter-steps .step:not(.disabled):focus {
          color: #CBDB2A; }
      #multi-step-filter .filter-steps .step.active {
        border-bottom: solid 3px #CBDB2A; }
      #multi-step-filter .filter-steps .step span {
        display: block;
        height: 30px;
        overflow: hidden;
        font-size: 1.1em;
        color: #fff; }
        #multi-step-filter .filter-steps .step span p {
          font-size: 1.1em;
          font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: 700; }
          #multi-step-filter .filter-steps .step span p.set {
            animation: inFromBottom 0.4s both cubic-bezier(0.7, 0, 0.3, 1); }
          #multi-step-filter .filter-steps .step span p.unset {
            animation: outToTop 0.4s both cubic-bezier(0.7, 0, 0.3, 1); }
  #multi-step-filter .filter-options {
    background: rgba(33, 43, 54, 0.4);
    padding: 15px 15px 20px;
    height: 350px;
    position: relative;
    z-index: 1;
    border-radius: 0 0 25px 25px;
    overflow: hidden; }
    #multi-step-filter .filter-options .filter-wrap {
      height: 317px;
      position: relative;
      z-index: 1;
      padding-top: 0;
      /* Track */
      /* Handle */
      /* Handle on hover */
      overflow-x: hidden; }
      #multi-step-filter .filter-options .filter-wrap::-webkit-scrollbar {
        width: 10px; }
      #multi-step-filter .filter-options .filter-wrap::-webkit-scrollbar-track {
        background: transparent;
        z-index: 9; }
      #multi-step-filter .filter-options .filter-wrap::-webkit-scrollbar-thumb {
        background: #00BCE7;
        z-index: 9;
        border-radius: 5px; }
      #multi-step-filter .filter-options .filter-wrap::-webkit-scrollbar-thumb:hover {
        background: #4edeff; }
    #multi-step-filter .filter-options .filter-title {
      font-weight: 700; }
    #multi-step-filter .filter-options .regions,
    #multi-step-filter .filter-options .districts,
    #multi-step-filter .filter-options .suburbs {
      position: absolute;
      top: 15px;
      left: 15px;
      display: none; }
      #multi-step-filter .filter-options .regions.inactive,
      #multi-step-filter .filter-options .districts.inactive,
      #multi-step-filter .filter-options .suburbs.inactive {
        display: block;
        animation: outToTop 0.4s both cubic-bezier(0.7, 0, 0.3, 1); }
      #multi-step-filter .filter-options .regions.active,
      #multi-step-filter .filter-options .districts.active,
      #multi-step-filter .filter-options .suburbs.active {
        display: block;
        animation: inFromBottom 0.4s both cubic-bezier(0.7, 0, 0.3, 1); }
    #multi-step-filter .filter-options ul {
      list-style: none;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 280px;
      padding-left: 0;
      display: flex; }
      #multi-step-filter .filter-options ul li {
        display: inline-block;
        text-align: left;
        padding: 10px;
        color: #fff;
        transition: color 0.2s ease-in-out;
        width: 100%;
        margin-right: 20px; }
        #multi-step-filter .filter-options ul li:hover {
          cursor: pointer;
          color: #CBDB2A; }
  #multi-step-filter button {
    position: absolute;
    left: 50%;
    bottom: -80px;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 1;
    transition: 0.2s ease-in-out opacity;
    margin: 0; }
  #multi-step-filter.closed button {
    opacity: 0; }

#tags-filter {
  z-index: 0; }
  #tags-filter .tag-options {
    border-bottom: #0396B6 solid 2px;
    padding-top: 40px; }
    #tags-filter .tag-options ul {
      list-style: none; }
      #tags-filter .tag-options ul li {
        display: inline-block;
        margin-right: 25px; }
        #tags-filter .tag-options ul li.active a {
          color: #035365; }
        #tags-filter .tag-options ul li a:hover, #tags-filter .tag-options ul li a:focus, #tags-filter .tag-options ul li a:active {
          color: #035365; }
  #tags-filter .panel {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    margin-bottom: 0; }
    #tags-filter .panel ul {
      border-bottom: 2px #eee solid;
      padding-bottom: 50px;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      flex-wrap: wrap;
      height: 250px;
      margin-bottom: 0; }
      #tags-filter .panel ul li {
        text-align: left;
        font-size: 1em;
        padding: 5px 0; }
        #tags-filter .panel ul li a {
          font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: 400;
          color: #212B36; }
          #tags-filter .panel ul li a:hover {
            color: #00BCE7; }

.filter-btn {
  text-align: center; }
  .filter-btn .btn {
    margin-top: 30px; }

@media (max-width: 991px) {
  #tags-filter .panel ul {
    max-height: 350px; }
  .filter-options .filter-wrap {
    overflow-y: scroll; } }

@media (max-width: 767px) {
  #tags-filter .panel ul {
    height: auto; }
  #multi-step-filter .filter-steps .step {
    font-size: 0.8em; }
    #multi-step-filter .filter-steps .step span p {
      font-size: 1.1em; }
  #multi-step-filter .filter-options {
    height: 350px;
    padding: 15px; }
    #multi-step-filter .filter-options .filter-wrap {
      overflow-y: scroll; }
    #multi-step-filter .filter-options ul {
      display: block;
      max-height: 100%; }
    #multi-step-filter .filter-options:after, #multi-step-filter .filter-options:before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      height: 20px;
      z-index: 2; }
    #multi-step-filter .filter-options:before {
      background: white;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #247f9a 100%);
      top: 15px; }
    #multi-step-filter .filter-options:after {
      background: white;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1c6279 100%);
      bottom: 15px; } }

.tab-hide {
  display: none; }

.top5 {
  padding-top: 5px; }

.top7 {
  padding-top: 7px; }

.top10 {
  padding-top: 10px; }

.top15 {
  padding-top: 15px; }

.top17 {
  padding-top: 17px; }

.top30 {
  padding-top: 30px; }

.top60 {
  padding-top: 60px; }

.bottom5 {
  padding-bottom: 5px; }

.bottom7 {
  padding-bottom: 7px; }

.bottom10 {
  padding-bottom: 10px; }

.bottom15 {
  padding-bottom: 15px; }

.bottom17 {
  padding-bottom: 17px; }

.bottom30 {
  padding-bottom: 30px; }

.bottom60 {
  padding-bottom: 60px; }

.dont-say-oh {
  position: relative;
  background-repeat: no-repeat;
  background-position: top right;
  padding-bottom: 50px;
  overflow: hidden; }
  .dont-say-oh img {
    position: absolute;
    right: 0;
    z-index: 0; }
  .dont-say-oh p,
  .dont-say-oh h1,
  .dont-say-oh h2,
  .dont-say-oh h3 {
    z-index: 3; }
  .dont-say-oh .right-content::before, .dont-say-oh .right-content::after {
    content: '';
    position: absolute;
    top: -50px;
    background-repeat: no-repeat; }
  .dont-say-oh .right-content::before {
    left: -15px;
    width: 200%;
    height: calc(100% + 110px);
    background-image: url("/images/bg-angle-small.png"); }
  .dont-say-oh .right-content::after {
    right: -30px;
    width: 327px;
    height: 387px;
    background-size: contain;
    background-image: url("/images/dont-say-oh-lady-small.png"); }
  .dont-say-oh .left-content .left-text:first-of-type {
    margin-bottom: 30px; }

@media (max-width: 991px) {
  .dont-say-oh {
    padding-top: 385px;
    background-position: 90% top;
    background-position: top center; }
    .dont-say-oh .right-content::before {
      top: -385px;
      width: 150%;
      left: -121px;
      background-size: cover; }
    .dont-say-oh .right-content::after {
      top: -385px;
      left: 50%;
      transform: translate(-50%); }
    .dont-say-oh h2 {
      margin-top: 40px; } }

@media (max-width: 768px) {
  .dont-say-oh h2 {
    margin-top: 20px; }
  .dont-say-oh .btn {
    margin-bottom: 30px; } }

.enquiries {
  padding-top: 50px; }

.enquiry {
  margin-bottom: 30px; }
  .enquiry:last-of-type {
    margin-bottom: 75px; }
  .enquiry a {
    color: #212B36; }
    .enquiry a:hover, .enquiry a:focus {
      color: #5a7382; }

.webinar {
  margin-bottom: 40px; }
  .webinar .social {
    font-size: 1.7em;
    margin-bottom: 30px; }
    .webinar .social i {
      margin-right: 15px; }
  .webinar .register-block {
    text-align: center;
    margin-top: 25px; }
    .webinar .register-block i {
      margin-right: 15px; }

.event-info .event-info-list {
  text-align: left;
  margin-bottom: 30px; }
  .event-info .event-info-list li {
    margin-bottom: 15px; }
  .event-info .event-info-list + .btn {
    margin-bottom: 40px; }

#event-details {
  margin-top: 4em; }

.speaker {
  margin: 15px 0 40px;
  overflow: hidden; }
  .modal-header .speaker {
    margin: 15px 0; }
  .speaker .img-round {
    height: 150px;
    width: 150px;
    margin-right: 15px;
    float: left; }
    .speaker .img-round .img-container {
      padding-bottom: 0; }
  .speaker .speaker-info {
    float: left; }
    .speaker .speaker-info h5 {
      font-size: 1.4em;
      margin-top: 0;
      color: #212B36;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 400; }
    .speaker .speaker-info small {
      display: block;
      color: #888; }
    .speaker .speaker-info a {
      font-size: 0.8em;
      cursor: pointer; }
  .speaker.item .img-round {
    float: none;
    margin-bottom: 10px; }
  .speaker.item small {
    margin-bottom: 15px; }

.programme .programme-controls {
  padding-bottom: 30px; }
  .programme .programme-controls .btn.btn-default {
    background: #d7deda;
    position: relative;
    margin-right: 20px; }
    .programme .programme-controls .btn.btn-default:after {
      content: '\f0d7';
      display: block;
      font-family: "Font Awesome 5 Pro";
      font-style: normal;
      font-weight: 900;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      color: #212B36;
      opacity: 0; }
    .programme .programme-controls .btn.btn-default.active {
      background: #212B36; }
      .programme .programme-controls .btn.btn-default.active:hover {
        color: #fff; }
      .programme .programme-controls .btn.btn-default.active:after {
        bottom: -14px;
        opacity: 1; }

.programme .panel-group {
  display: none; }
  .programme .panel-group.active {
    display: block; }

.programme .panel-default > .panel-heading .panel-title [class^="fa"] {
  color: #5a7382; }
  a:hover .programme .panel-default > .panel-heading .panel-title [class^="fa"],
  a:focus .programme .panel-default > .panel-heading .panel-title [class^="fa"] {
    color: #212B36; }

.programme .panel-default > .panel-heading .panel-title .time {
  font-weight: normal;
  margin-right: 20px; }

.modal .modal-header .speaker-info {
  margin-left: 15px; }

.modal .modal-header .contact-details {
  margin-top: 4px;
  font-size: 0.9em; }

.presenters {
  margin-bottom: 60px;
  overflow: hidden; }

.sponsor h2 {
  margin-top: 0; }

@media (max-width: 580px) {
  .programme .programme-controls .btn.btn-default {
    margin-right: 10px;
    margin-bottom: 10px; } }

.event-card .img-container .event-alert {
  display: block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background: #5a7382;
  z-index: 3;
  text-align: center; }
  .event-card .img-container .event-alert:after {
    display: block;
    margin-top: 6px;
    font-size: 1.2em;
    color: #fff;
    width: 100%;
    height: 100%; }
  .event-card .img-container .event-alert.end-soon:after {
    content: '\f017';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400; }
  .event-card .img-container .event-alert.early-bird:after {
    content: '\f535';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900; }
  .event-card .img-container .event-alert.video:after {
    content: '\f03d';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900; }

.event-card .alert-message {
  color: #212B36; }

.event-card .location {
  color: #5a7382; }

.event-card .alert-message,
.event-card .location {
  margin-bottom: 8px;
  font-size: 0.8em;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

.featured-document h3 {
  margin-top: 0;
  margin-bottom: 0.7em; }

.featured-document .btn {
  margin-bottom: 30px; }

.featured-document .additional-report {
  padding-top: 20px; }
  .featured-document .additional-report a {
    color: #212B36; }

@media (max-width: 767px) {
  .featured-document .pdf {
    margin-bottom: 25px; } }

.team-member {
  margin-top: 30px;
  margin-bottom: 30px; }
  .team-member .img-circle {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden; }

.job-description,
.contact-details {
  list-style: none;
  padding-left: 0; }
  .job-description li,
  .contact-details li {
    display: inline-block; }
    .job-description li + li,
    .contact-details li + li {
      padding-left: 5px; }
      .job-description li + li::before,
      .contact-details li + li::before {
        content: '|';
        margin-right: 5px; }

.job-description {
  margin-bottom: 25px;
  color: #bbb; }

.contact-details {
  margin-top: 25px;
  color: #00BCE7; }

.product-features {
  text-align: center;
  margin-bottom: 100px; }
  .product-features .price {
    font-size: 2.5em;
    display: block;
    color: #212B36;
    padding-bottom: 20px;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300; }
  .product-features .benefit-list {
    list-style: none;
    margin: 30px 0;
    padding-left: 0; }
    .product-features .benefit-list li {
      padding-bottom: 15px; }
  .product-features .btn {
    padding: 8px 40px; }

.directory-filters {
  margin-top: -21px;
  background: #212B36;
  padding: 15px 0; }
  .directory-filters select {
    color: #212B36;
    background: #00BCE7;
    border: 0; }
    .directory-filters select + select {
      margin-left: 30px; }
  .directory-filters + .tint-bg {
    margin-top: 0; }
  .directory-filters .form-group {
    margin-bottom: 0; }
    .directory-filters .form-group .form-control {
      background: transparent;
      border-radius: 0;
      border: 0; }
    .directory-filters .form-group .input-group-addon {
      background: transparent;
      color: #fff;
      border: 0; }

.category-nav {
  list-style: none;
  padding-left: 0;
  margin: 30px 0 45px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(170, 165, 158, 0.3);
  position: relative;
  text-align: center;
  padding-bottom: 30px; }
  .category-nav li {
    display: inline-block;
    padding-bottom: 3px;
    margin-right: 20px; }
    .category-nav li a {
      color: #AAA59E;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700; }
      .category-nav li a:hover, .category-nav li a:focus {
        color: #00BCE7; }
    .category-nav li.active a {
      color: #00BCE7; }
      .category-nav li.active a:hover, .category-nav li.active a:focus {
        color: #AAA59E; }
    .category-nav li span {
      color: #b3b9b6;
      transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .category-nav a {
    color: #212B36; }
    .category-nav a:hover {
      color: #00BCE7; }
      .category-nav a:hover span {
        color: #00BCE7; }

.directory-controls {
  position: absolute;
  right: 30px;
  top: 0;
  padding-top: 15px; }
  .directory-controls a {
    font-size: 1.8em;
    background: #00BCE7;
    color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    text-align: center;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    transition: background-color 0.2s ease-in-out; }
    .directory-controls a:hover, .directory-controls a:focus {
      background: #212B36; }

.media.document {
  margin-bottom: 50px; }
  .media.document .media-left {
    width: 33%;
    display: block;
    float: left; }
  .media.document .media-body {
    width: 65%;
    display: block;
    float: left;
    padding-left: 15px;
    padding-bottom: 30px;
    position: relative; }
    .media.document .media-body small {
      display: block;
      color: #00BCE7;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 10px; }
    .media.document .media-body h3 {
      margin-bottom: 15px; }
  .media.document .media-footer {
    overflow: hidden;
    width: calc(100% - 20px);
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0; }
    .media.document .media-footer a + a {
      margin-left: 30px; }
    .media.document .media-footer small {
      color: #999; }

@media (max-width: 580px) {
  .category-nav li {
    margin-bottom: 10px; }
  .media.document .media-left {
    text-align: left;
    width: 100px; }
  .media.document .media-body {
    padding-top: 15px;
    width: 100%;
    padding-left: 0; } }

#search-bar .form-group {
  overflow: visible; }

.search-result {
  border-top: 1px solid #d4d4d4;
  padding-top: 15px;
  margin-bottom: 15px;
  overflow: hidden; }
  .search-result h3 {
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 1.3em;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .search-result p {
    font-size: 0.9em;
    margin-bottom: 7px; }
  .search-result .tag-list {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0; }
    .search-result .tag-list li {
      font-size: 0.8em; }
    .search-result .tag-list li:first-of-type {
      padding-left: 0; }
    .search-result .tag-list li:last-of-type {
      position: relative;
      font-weight: normal; }
      .search-result .tag-list li:last-of-type:after {
        content: '';
        display: none; }
  .search-result small {
    font-style: italic; }
  .search-result a {
    display: block;
    overflow: hidden; }
    .search-result a small,
    .search-result a h3 {
      color: #333;
      transition: color 0.2s ease-in-out; }
    .search-result a:hover small,
    .search-result a:hover h3 {
      color: #00BCE7; }

@media (min-width: 992px) {
  .five-col {
    width: 20%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left; } }

.card-resources .card-block {
  position: relative; }

.card-resources .icon {
  position: absolute;
  bottom: 15px;
  left: 15px; }

.directory-link {
  color: #212B36;
  float: right;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }
  .directory-link i {
    color: #CBDB2A;
    margin-right: 5px; }

.summary-panel {
  margin-bottom: 0; }
  .summary-panel .list-contacts, .summary-panel dl {
    margin-top: 2em; }

.presenters .media-list .media-object {
  max-width: 180px;
  float: right;
  margin-left: 2em; }

.presenters .media-list .list-inline li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@media screen and (max-width: 480px) {
  .presenters .media-list .media-object {
    max-width: 100%;
    float: none;
    margin-bottom: 2em;
    margin-left: 0; } }

.featured-journal .featured-information {
  float: right; }
  .featured-journal .featured-information h1 {
    text-align: left;
    font-weight: bold;
    margin-bottom: 0; }
  .featured-journal .featured-information h2 {
    margin-top: 0; }
  .featured-journal .featured-information small {
    text-transform: uppercase;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .featured-journal .featured-information ul {
    color: #ffffff;
    padding-left: 17px; }
    .featured-journal .featured-information ul li {
      color: #ffffff; }

.featured-journal .featured-img {
  float: left;
  max-width: 350px; }
  .featured-journal .featured-img img {
    max-width: 100%;
    box-shadow: 10px 10px 54px 0px rgba(0, 0, 0, 0.3); }

.featured-journal .icons {
  list-style: none;
  padding: 0; }
  .featured-journal .icons li {
    width: 50%;
    float: left;
    margin-top: 40px;
    padding-right: 20px; }
    .featured-journal .icons li i {
      color: #fff;
      margin-right: 10px;
      font-size: 2.8em; }
    .featured-journal .icons li a {
      display: flex;
      align-items: center;
      color: #292F36;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700; }
      .featured-journal .icons li a:hover {
        color: #ffffff; }

.logged-out .featured-journal .icons li:not(:last-of-type) {
  opacity: 0.5; }

@media (max-width: 580px) {
  .featured-journal .featured-information {
    float: none; }
  .featured-journal .featured-img {
    max-width: 150px;
    margin-bottom: 30px; } }

.login {
  padding-top: 0;
  padding-bottom: 0; }
  .login .form-group {
    max-width: 100%; }
  .login .remember-me-checkbox {
    float: right; }
    .login .remember-me-checkbox label {
      color: #ffffff; }

.form-page {
  background-image: url("./../images/homepage-banner-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: center center;
  min-height: 100vh; }
  .form-page a {
    color: #ffffff; }
    .form-page a:hover {
      color: #212B36; }
    .form-page a.form-link {
      display: block;
      float: right;
      width: 50%;
      font-weight: 600; }
      .form-page a.form-link span {
        font-style: italic;
        font-weight: 400; }
  .form-page .tint-bg {
    background-color: rgba(0, 188, 231, 0.9);
    margin-top: 0;
    padding: 30px 50px;
    min-height: 100vh;
    position: relative; }
  .form-page .form-header a {
    display: block;
    float: right;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .form-page .form {
    padding: 60px 0; }
    .form-page .form legend {
      color: #212B36;
      padding: 20px 0;
      font-size: 3em;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      border-bottom: 0; }
    .form-page .form .form-group {
      position: relative;
      margin-bottom: 25px; }
      .form-page .form .form-group.col-md-6 {
        padding: 0 15px 0 0;
        float: left; }
        .form-page .form .form-group.col-md-6:nth-of-type(2n) {
          padding: 0 0 0 15px; }
      .form-page .form .form-group:last-of-type {
        margin-bottom: 60px; }
      .form-page .form .form-group label {
        color: #212B36;
        text-transform: uppercase;
        font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px; }
      .form-page .form .form-group .form-control {
        border: 0;
        background-color: #fff;
        border-radius: 25px;
        padding: 6px 20px; }
        .form-page .form .form-group .form-control ::-webkit-input-placeholder {
          color: #eee; }
        .form-page .form .form-group .form-control ::-moz-placeholder {
          color: #eee; }
        .form-page .form .form-group .form-control :-ms-input-placeholder {
          color: #eee; }
        .form-page .form .form-group .form-control :-moz-placeholder {
          color: #eee; }
      .form-page .form .form-group .view-toggle {
        color: #5A7382;
        position: absolute;
        width: 30px;
        height: 30px;
        z-index: 6;
        right: 0;
        top: 32px;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.1s ease-in; }
        .form-page .form .form-group .view-toggle:before {
          line-height: 34px; }
        .form-page .form .form-group .view-toggle:hover {
          opacity: 1; }
    .form-page .form .btn {
      float: left;
      margin-right: 30px; }
  .form-page .form-footer {
    font-weight: 600; }
    .form-page .form-footer a {
      display: block; }
      .form-page .form-footer a span {
        font-style: italic;
        font-weight: 400; }

@media (max-width: 991px) {
  .form-page {
    height: auto; }
    .form-page .tint-bg {
      height: auto; }
    .form-page .form .form-group.col-md-6 {
      padding: 0; }
      .form-page .form .form-group.col-md-6:nth-of-type(2n) {
        padding: 0; } }

@media (max-width: 768px) {
  .form-page .form .form-group .view-toggle {
    top: 27px; } }

@media (max-width: 580px) {
  .form-page .form {
    padding: 35px 0; }
    .form-page .form .form-group:last-of-type {
      margin-bottom: 35px; }
  .form-page .tint-bg {
    padding: 30px 25px; }
  .form-page .form-header a {
    float: none;
    margin-top: 25px; }
  .form-page a.form-link {
    float: none;
    padding: 50px 0 0;
    clear: both;
    width: 100%; }
  .form-page .form-footer a {
    width: 100%;
    margin-bottom: 35px; } }

@media (max-height: 800px) {
  .form-page {
    height: auto; }
    .form-page .ting-bg {
      height: auto; } }

.dashboard-header {
  background-image: url("/images/dashboard-header.jpg");
  padding-top: 180px;
  background-position: center; }
  .dashboard-header .banner-content {
    position: relative;
    padding-bottom: 40px; }
  .dashboard-header .cta-counter {
    width: 170px;
    height: 170px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center; }
    .dashboard-header .cta-counter .cta-content {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .dashboard-header .cta-counter .cta {
      color: #06D6A0;
      font-size: 4em;
      line-height: 1em;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      padding-bottom: 20px; }
  .dashboard-header h3,
  .dashboard-header h1 {
    color: #fff;
    margin: 10px 0; }
  .dashboard-header h3 {
    font-size: 1.3em; }
  .dashboard-header p {
    color: #fff;
    opacity: 0.58;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em; }
  .dashboard-header .nav-container {
    background: rgba(33, 43, 54, 0.5);
    padding: 25px 0; }
    .dashboard-header .nav-container .col-xs-12 {
      padding: 0 15px; }
    .dashboard-header .nav-container ul {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0; }
      .dashboard-header .nav-container ul li {
        display: inline-block;
        margin-right: 20px; }
        .dashboard-header .nav-container ul li a {
          color: #fff; }
          .dashboard-header .nav-container ul li a:hover, .dashboard-header .nav-container ul li a:focus {
            color: #00BCE7; }
        .dashboard-header .nav-container ul li.active a {
          color: #00BCE7; }

.notification {
  background-color: #06D6A0;
  color: #212B36;
  padding: 25px 0 25px;
  position: relative; }
  .notification .col-xs-12 {
    position: relative;
    padding-left: 40px; }
  .notification .fa-bell {
    color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.7em; }
  .notification p {
    color: #212B36;
    margin-bottom: 4px; }
    .notification p + a {
      font-size: 0.8em; }
  .notification a {
    color: #212B36; }
    .notification a:hover, .notification a:focus {
      color: #fff; }
  .notification .close {
    opacity: 1;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 0;
    transition: opacity 0.2s ease-in-out; }
    .notification .close:hover, .notification .close:focus {
      opacity: 0.5; }
  .notification + .notification .col-xs-12 {
    padding-top: 20px;
    margin-top: -25px;
    border-top: #47D5AF 2px solid; }

.dashboard .card-section small {
  color: #AFABA3; }

.dashboard .card-section h2 {
  margin-top: 5px;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }

.dashboard .card-section + .card-section {
  padding: 50px 0; }

.dashboard .blue-bg small {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500; }

.dashboard .blue-bg h2 {
  margin-top: 5px;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #212B36;
  margin-bottom: 30px; }

.dashboard .blue-bg h3 {
  margin-top: 0;
  margin-bottom: 10px; }

.dashboard .blue-bg a {
  color: #fff;
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500; }
  .dashboard .blue-bg a:hover, .dashboard .blue-bg a:focus {
    color: #CFE018; }

.dashboard .blue-bg .sig {
  margin-bottom: 50px; }

.dashboard .blue-bg .far {
  color: #212B36; }

@media (max-width: 991px) {
  .dashboard .tint-bg {
    margin-top: 0; } }

.tag-list {
  border-bottom: #F2F1F0 2px solid;
  list-style: none;
  padding-left: 0;
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 40px; }
  .tag-list li {
    display: inline-block;
    padding-right: 20px;
    font-size: 0.9em;
    color: #AAA59E; }
    .tag-list li:first-of-type {
      padding-left: 30px;
      position: relative; }
      .tag-list li:first-of-type:before {
        position: absolute;
        left: 0;
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: 300;
        color: #CFE018; }
    .tag-list li:last-of-type {
      position: absolute;
      padding-right: 20px;
      right: 0;
      top: 0;
      font-weight: 700; }
      .tag-list li:last-of-type:after {
        content: '\f054';
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: 900;
        color: #00BCE7; }
  .tag-list.tags li:first-of-type:before {
    content: "\f02c"; }
  .tag-list.preferences li:first-of-type:before {
    content: "\f0ae"; }

@media (max-width: 991px) {
  .tag-list {
    margin-bottom: 20px; }
    .tag-list li:not(:first-of-type):not(:last-of-type) {
      display: none; } }

.member-dropdown-toggle {
  background-color: #171F26;
  padding: 17px;
  border-radius: 30px;
  display: block;
  width: 175px; }
  .member-dropdown-toggle .mypnz {
    color: #fff;
    margin-right: 15px;
    transition: color 0.2s ease-in-out; }
    .member-dropdown-toggle .mypnz i {
      margin-right: 10px;
      transition: transform 0.2s ease-in-out;
      transform: rotate(0deg); }
  .member-dropdown-toggle .initials {
    color: #CFE018; }
  .member-dropdown-toggle:hover .mypnz, .member-dropdown-toggle:focus .mypnz {
    color: #CFE018; }

@media (max-width: 991px) {
  .tools .dropdown {
    float: left;
    display: block; }
    .tools .dropdown.open .dropdown-menu {
      transform: translateX(0);
      z-index: 8;
      padding-left: 25px; }
    .tools .dropdown .dropdown-menu {
      position: fixed;
      top: 67px;
      left: 0;
      width: 100%;
      height: calc(100vh - 70px);
      overflow-y: scroll;
      background-color: #212B36;
      border: 0;
      padding: 2em 0;
      z-index: 1;
      transition: box-shadow 0.5s 0.1s;
      box-shadow: none;
      transition: height 0.2s ease-in-out, transform 0.4s ease-in-out;
      display: block;
      transform: translateX(100%); }
      .tools .dropdown .dropdown-menu.open {
        transform: translateX(0);
        z-index: 8; }
      .tools .dropdown .dropdown-menu li {
        text-align: left;
        position: relative;
        padding: 0 0 15px; }
        .tools .dropdown .dropdown-menu li > a:not(.btn) {
          padding: 13px 25px 13px 0;
          font-size: 1.2em;
          white-space: normal;
          justify-content: left;
          line-height: 1.4em;
          color: #fff; }
          .tools .dropdown .dropdown-menu li > a:not(.btn):focus, .tools .dropdown .dropdown-menu li > a:not(.btn):hover {
            color: #00BCE7; }
        .tools .dropdown .dropdown-menu li.dropdown-heading {
          font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
          font-style: normal;
          font-weight: 300;
          width: 100%;
          padding-top: 15px;
          font-size: 1.7em;
          color: #fff;
          padding-bottom: 12px;
          margin-bottom: 15px;
          flex-basis: 100%; }
        .tools .dropdown .dropdown-menu li i {
          color: #CFE018; }
        .tools .dropdown .dropdown-menu li small {
          display: block; }
  .tools .search-toggle {
    padding-right: 15px;
    padding-top: 10px; }
    .tools .search-toggle a {
      color: #fff; }
      .tools .search-toggle a:hover, .tools .search-toggle a:focus {
        color: #00BCE7; }
  .member-dropdown-toggle {
    background-color: #171F26;
    padding: 10px 15px;
    border-radius: 30px;
    display: block;
    width: 122px; }
  .toggle-wrap form {
    max-width: none;
    width: 100%;
    padding-right: 50px; }
    .toggle-wrap form .input-group {
      width: 100%; } }

.sponsor {
  margin-bottom: 50px; }
  .sponsor .sponsor-logo {
    margin-bottom: 50px;
    max-height: 60px;
    max-width: 300px; }
  .sponsor h2 {
    font-size: 1.7em;
    margin-bottom: 20px; }

@media (max-width: 580px) {
  margin-bottom: 30px; }

.conference-header {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 50px;
  position: relative; }
  .conference-header.light-bg h1 {
    color: #212B36; }
  .conference-header.light-bg .countdown div {
    color: #212B36; }
    .conference-header.light-bg .countdown div span {
      color: #212B36;
      border-color: #212B36; }
  .conference-header.light-bg .event-details ul {
    color: #212B36; }
  .conference-header h1 {
    color: #fff;
    margin-bottom: 20px; }
  .conference-header .event-details ul {
    color: #fff;
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px; }
    .conference-header .event-details ul li {
      color: #ffffff; }
  .conference-header:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.82;
    background-image: linear-gradient(270deg, rgba(0, 76, 93, 0) 0%, #00BCE7 100%); }

.countdown {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  margin-top: -14px;
  position: absolute;
  bottom: 0;
  right: 30px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out; }
  .countdown.visible {
    opacity: 1; }
  .countdown div {
    color: #fff;
    display: inline-block;
    margin-left: 25px;
    font-size: 45px;
    font-weight: bold;
    line-height: 1.3;
    text-align: right; }
    .countdown div:first-child {
      margin-left: 0; }
    .countdown div span {
      text-align: center;
      display: block;
      color: #fff;
      border-top: solid 5px #fff;
      min-width: 60px;
      padding-top: 3px;
      font-size: 20px;
      font-weight: normal;
      text-transform: uppercase;
      text-align: left;
      text-align: center; }

/* IE7 inline-block hack */
@media (max-width: 1200px) {
  .countdown {
    margin-top: -8px; }
    .countdown div {
      font-size: 40px; } }

@media (max-width: 991px) {
  .countdown {
    margin-top: -1px; }
    .countdown div {
      font-size: 35px;
      margin-left: 15px; } }

@media (max-width: 768px) {
  .conference-header {
    padding-bottom: 50px; }
  .countdown {
    display: block;
    position: relative;
    padding-top: 20px; }
    .countdown div {
      font-size: 40px;
      margin-left: 20px; } }

@media (max-width: 480px) {
  .countdown {
    margin-top: -1px; }
    .countdown div {
      font-size: 30px;
      margin-left: 15px; }
      .countdown div span {
        font-size: 15px; } }

.conference-content {
  padding-bottom: 60px; }
  .conference-content .key-colours {
    padding: 0;
    list-style: none; }
    .conference-content .key-colours li {
      margin-bottom: 20px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      float: left; }
      .conference-content .key-colours li.concussion:before {
        background-color: #26b8e6; }
      .conference-content .key-colours li.physical-activity:before {
        background-color: #b8082d; }
      .conference-content .key-colours li.musculoskeletal:before {
        background-color: #f6a100; }
      .conference-content .key-colours li.acture-care:before {
        background-color: #c3d500; }
      .conference-content .key-colours li.models-of-care:before {
        background-color: #000000; }
      .conference-content .key-colours li.other:before {
        background-color: #F6F6F7; }
      .conference-content .key-colours li:before {
        content: "";
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 15px;
        background-color: #eee; }
  .conference-content .content {
    padding: 30px;
    background: #fff;
    overflow: hidden; }
  .conference-content .tab-nav ul li a {
    color: #888; }
    .conference-content .tab-nav ul li a:hover {
      color: #D9182B; }
  .conference-content .tab-nav ul li.active a {
    color: #00BCE7; }
  .conference-content .lead,
  .conference-content .intro {
    margin-bottom: 40px; }
  .conference-content .feature-list li::before {
    color: #D9182B; }
  .conference-content .speaker .speaker-info h5 {
    color: #D9182B; }

@media (max-width: 580px) {
  .conference-content .speaker .img-round {
    max-width: 100%; } }

.branch-intro {
  padding-top: 60px; }

.key-date-tile {
  margin-bottom: 50px; }
  .key-date-tile .key-date-date {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    color: #212B36;
    background: #f5f7f6;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .key-date-tile .key-date-date p {
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 1.2em; }

@media (max-width: 767px) {
  .branch-intro {
    padding-top: 30px; }
    .branch-intro img {
      max-height: 200px;
      margin-bottom: 30px; } }

.branch-info {
  display: flex;
  height: 100%; }
  .branch-info .member-count .number {
    border: 1px solid #fff;
    color: #fff; }
  .branch-info .member-count h3 {
    color: #fff; }

.member-count {
  overflow: hidden; }
  .member-count .number {
    height: 55px;
    width: 55px;
    border: 1px solid #212B36;
    border-radius: 50%;
    float: left;
    text-align: center;
    padding-top: 10px;
    margin-right: 20px;
    color: #212B36;
    font-size: 1.4em;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .member-count h3 {
    float: left;
    margin-top: 10px; }

.branch-list h3 {
  text-align: center;
  font-size: 1.6em;
  margin-bottom: 2em; }

.branch-list ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }
  .branch-list ul li {
    display: inline-block;
    position: relative;
    font-size: 1.3em;
    padding: 0 10px; }
    .branch-list ul li a {
      color: #212B36;
      font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 400; }
      .branch-list ul li a:hover {
        color: #5a7382; }
    .branch-list ul li + li {
      margin-bottom: 15px; }

.no-flexbox .branch-list ul {
  list-style: none;
  padding-left: 0; }
  .no-flexbox .branch-list ul a {
    color: #212B36; }
    .no-flexbox .branch-list ul a:hover {
      color: #5a7382; }
  .no-flexbox .branch-list ul + li {
    padding-left: 20px;
    margin-left: 15px;
    margin-bottom: 15px; }
    .no-flexbox .branch-list ul + li::before {
      content: '●';
      position: absolute;
      left: 0; }

.job-offer:not(:last-of-type) .media {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d7deda; }

.job-offer:last-of-type .media {
  margin-bottom: 40px; }

.job-offer .media-left {
  float: left;
  padding-right: 30px;
  width: 300px; }

.job-offer .media-body {
  width: auto;
  float: left;
  width: calc(100% - 340px); }
  .job-offer .media-body .media-heading {
    margin-top: 15px;
    margin-bottom: 20px; }
  .job-offer .media-body small {
    color: #aab5af;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }

.job-listing {
  position: relative; }
  .job-listing h2 + a {
    position: absolute;
    right: 15px;
    top: 30px;
    color: #aab5af; }
    .job-listing h2 + a:hover {
      color: #5a7382; }
  .job-listing .btn {
    margin-top: 20px; }

@media (max-width: 991px) {
  .job-listing {
    margin-bottom: 40px; }
  .job-offer .media-body {
    width: 100%; }
  .job-offer .media-left {
    float: left;
    padding-right: 0;
    width: 100%;
    margin-bottom: 20px; } }

@media (max-width: 580px) {
  .job-listing {
    margin-bottom: 40px; }
    .job-listing h2 + a {
      position: relative;
      right: 0;
      top: 0;
      display: block;
      padding-bottom: 24px; }
  .page-info img {
    max-width: 200px; }
  .page-info img,
  .page-info .fa-ul {
    float: none; }
  .page-info .fa-ul {
    margin-top: 20px;
    margin-left: 2.5em; } }

.reasons-to-join {
  margin-bottom: 40px; }
  .reasons-to-join h2 {
    color: #fff;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 106px;
    margin-bottom: 10px;
    line-height: 1em; }
  .reasons-to-join h3 {
    margin-top: 0;
    color: #212B36;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .reasons-to-join a {
    color: #00BCE7; }

.hero.sig-banner {
  position: relative;
  height: 430px;
  background-size: cover; }
  .hero.sig-banner h1 {
    z-index: 1;
    color: #fff;
    position: absolute;
    padding-left: 15px;
    max-width: 80%; }
    .hero.sig-banner h1 span {
      font-size: 0.7em;
      display: block; }

.pwcc-link {
  transition: opacity 0.2s ease-in-out;
  overflow: hidden;
  opacity: 1; }
  .pwcc-diagram:hover .pwcc-link:not(:hover) {
    opacity: 0.5; }

.pwcc-section {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  padding-bottom: 100px; }
  .fade-content .pwcc-section {
    opacity: 0 !important; }
  .pwcc-section .text-center {
    margin-bottom: 50px; }

#page-section-wrap #title {
  display: block;
  width: 100%; }
  #page-section-wrap #title h3 {
    font-size: 1.1em;
    max-width: 65%;
    color: #fff;
    margin: 15px 0; }

#page-section-wrap #sub-title {
  display: block;
  width: 100%; }
  #page-section-wrap #sub-title h4 {
    font-size: 1.1em;
    max-width: 45%;
    margin: 15px 0;
    color: #fff; }

.back-to-site {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: flex-end; }
  .back-to-site .back-img {
    width: 75px;
    height: 75px; }
  .back-to-site .back-text {
    color: #fff;
    padding-left: 15px; }
  .back-to-site:hover .back-text {
    text-decoration: underline; }

.stop-scroll {
  overflow-y: hidden; }

.flex-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .flex-row .side-col {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    display: block;
    position: relative;
    padding-right: 20px; }
  .flex-row .main-col {
    max-width: 66.666%;
    flex: 0 0 66.666%;
    display: block;
    position: relative;
    padding: 0 30px;
    background-color: rgba(255, 255, 255, 0.75); }

#pwcc-nav {
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  align-content: baseline;
  overflow: scroll; }
  #pwcc-nav .mini-svg {
    width: 250px;
    overflow: hidden;
    padding-top: 15px; }
    #pwcc-nav .mini-svg a {
      transition: 0.2s ease-in-out opacity;
      opacity: 0.8; }

[data-active-section="overview"] {
  background-color: #fff;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="overview"] .side-col {
    background-color: #fff;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79);
    visibility: hidden; }
  [data-active-section="overview"] h1, [data-active-section="overview"] h2, [data-active-section="overview"] h3, [data-active-section="overview"] p, [data-active-section="overview"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="overview"] #pwcc-nav .mini-svg a[href="#overview"] {
    opacity: 0.5; }
    [data-active-section="overview"] #pwcc-nav .mini-svg a[href="#overview"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#overview"]:hover path {
  fill: #fff; }

[data-active-section="building-relationships"] {
  background-color: #773e90;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="building-relationships"] .side-col {
    background-color: #773e90;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="building-relationships"] h1, [data-active-section="building-relationships"] h2, [data-active-section="building-relationships"] h3, [data-active-section="building-relationships"] p, [data-active-section="building-relationships"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="building-relationships"] #pwcc-nav .mini-svg a[href="#building-relationships"] {
    opacity: 0.5; }
    [data-active-section="building-relationships"] #pwcc-nav .mini-svg a[href="#building-relationships"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#building-relationships"]:hover path {
  fill: #773e90; }

[data-active-section="seeing-patient"] {
  background-color: #2657a4;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="seeing-patient"] .side-col {
    background-color: #2657a4;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="seeing-patient"] h1, [data-active-section="seeing-patient"] h2, [data-active-section="seeing-patient"] h3, [data-active-section="seeing-patient"] p, [data-active-section="seeing-patient"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="seeing-patient"] #pwcc-nav .mini-svg a[href="#seeing-patient"] {
    opacity: 0.5; }
    [data-active-section="seeing-patient"] #pwcc-nav .mini-svg a[href="#seeing-patient"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#seeing-patient"]:hover path {
  fill: #2657a4; }

[data-active-section="tailoring-organising"] {
  background-color: #2c7cbf;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="tailoring-organising"] .side-col {
    background-color: #2c7cbf;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="tailoring-organising"] h1, [data-active-section="tailoring-organising"] h2, [data-active-section="tailoring-organising"] h3, [data-active-section="tailoring-organising"] p, [data-active-section="tailoring-organising"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="tailoring-organising"] #pwcc-nav .mini-svg a[href="#tailoring-organising"] {
    opacity: 0.5; }
    [data-active-section="tailoring-organising"] #pwcc-nav .mini-svg a[href="#tailoring-organising"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#tailoring-organising"]:hover path {
  fill: #2c7cbf; }

[data-active-section="utilising-communication"] {
  background-color: #63c6e4;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="utilising-communication"] .side-col {
    background-color: #63c6e4;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="utilising-communication"] h1, [data-active-section="utilising-communication"] h2, [data-active-section="utilising-communication"] h3, [data-active-section="utilising-communication"] p, [data-active-section="utilising-communication"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="utilising-communication"] #pwcc-nav .mini-svg a[href="#utilising-communication"] {
    opacity: 0.5; }
    [data-active-section="utilising-communication"] #pwcc-nav .mini-svg a[href="#utilising-communication"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#utilising-communication"]:hover path {
  fill: #63c6e4; }

[data-active-section="sharing-info"] {
  background-color: #74b457;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="sharing-info"] .side-col {
    background-color: #74b457;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="sharing-info"] h1, [data-active-section="sharing-info"] h2, [data-active-section="sharing-info"] h3, [data-active-section="sharing-info"] p, [data-active-section="sharing-info"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="sharing-info"] #pwcc-nav .mini-svg a[href="#sharing-info"] {
    opacity: 0.5; }
    [data-active-section="sharing-info"] #pwcc-nav .mini-svg a[href="#sharing-info"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#sharing-info"]:hover path {
  fill: #74b457; }

[data-active-section="health-literacy"] {
  background-color: #a6bc3b;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="health-literacy"] .side-col {
    background-color: #a6bc3b;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="health-literacy"] h1, [data-active-section="health-literacy"] h2, [data-active-section="health-literacy"] h3, [data-active-section="health-literacy"] p, [data-active-section="health-literacy"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="health-literacy"] #pwcc-nav .mini-svg a[href="#health-literacy"] {
    opacity: 0.5; }
    [data-active-section="health-literacy"] #pwcc-nav .mini-svg a[href="#health-literacy"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#health-literacy"]:hover path {
  fill: #a6bc3b; }

[data-active-section="empowering-person"] {
  background-color: #f4b91c;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="empowering-person"] .side-col {
    background-color: #f4b91c;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="empowering-person"] h1, [data-active-section="empowering-person"] h2, [data-active-section="empowering-person"] h3, [data-active-section="empowering-person"] p, [data-active-section="empowering-person"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="empowering-person"] #pwcc-nav .mini-svg a[href="#empowering-person"] {
    opacity: 0.5; }
    [data-active-section="empowering-person"] #pwcc-nav .mini-svg a[href="#empowering-person"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#empowering-person"]:hover path {
  fill: #f4b91c; }

[data-active-section="sharing-power"] {
  background-color: #ee7134;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="sharing-power"] .side-col {
    background-color: #ee7134;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="sharing-power"] h1, [data-active-section="sharing-power"] h2, [data-active-section="sharing-power"] h3, [data-active-section="sharing-power"] p, [data-active-section="sharing-power"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="sharing-power"] #pwcc-nav .mini-svg a[href="#sharing-power"] {
    opacity: 0.5; }
    [data-active-section="sharing-power"] #pwcc-nav .mini-svg a[href="#sharing-power"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#sharing-power"]:hover path {
  fill: #ee7134; }

[data-active-section="enabling-encouraging"] {
  background-color: #e7467c;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="enabling-encouraging"] .side-col {
    background-color: #e7467c;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="enabling-encouraging"] h1, [data-active-section="enabling-encouraging"] h2, [data-active-section="enabling-encouraging"] h3, [data-active-section="enabling-encouraging"] p, [data-active-section="enabling-encouraging"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="enabling-encouraging"] #pwcc-nav .mini-svg a[href="#enabling-encouraging"] {
    opacity: 0.5; }
    [data-active-section="enabling-encouraging"] #pwcc-nav .mini-svg a[href="#enabling-encouraging"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#enabling-encouraging"]:hover path {
  fill: #e7467c; }

[data-active-section="recognising-person"] {
  background-color: #e56c67;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="recognising-person"] .side-col {
    background-color: #e56c67;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="recognising-person"] h1, [data-active-section="recognising-person"] h2, [data-active-section="recognising-person"] h3, [data-active-section="recognising-person"] p, [data-active-section="recognising-person"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="recognising-person"] #pwcc-nav .mini-svg a[href="#recognising-person"] {
    opacity: 0.5; }
    [data-active-section="recognising-person"] #pwcc-nav .mini-svg a[href="#recognising-person"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#recognising-person"]:hover path {
  fill: #e56c67; }

[data-active-section="engaging-goal-oriented"] {
  background-color: #b31c3c;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="engaging-goal-oriented"] .side-col {
    background-color: #b31c3c;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="engaging-goal-oriented"] h1, [data-active-section="engaging-goal-oriented"] h2, [data-active-section="engaging-goal-oriented"] h3, [data-active-section="engaging-goal-oriented"] p, [data-active-section="engaging-goal-oriented"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="engaging-goal-oriented"] #pwcc-nav .mini-svg a[href="#engaging-goal-oriented"] {
    opacity: 0.5; }
    [data-active-section="engaging-goal-oriented"] #pwcc-nav .mini-svg a[href="#engaging-goal-oriented"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#engaging-goal-oriented"]:hover path {
  fill: #b31c3c; }

[data-active-section="system-level"] {
  background-color: #bfbfbe;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="system-level"] .side-col {
    background-color: #bfbfbe;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="system-level"] h1, [data-active-section="system-level"] h2, [data-active-section="system-level"] h3, [data-active-section="system-level"] p, [data-active-section="system-level"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="system-level"] #pwcc-nav .mini-svg a[href="#system-level"] {
    opacity: 0.5; }
    [data-active-section="system-level"] #pwcc-nav .mini-svg a[href="#system-level"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#system-level"]:hover path {
  fill: #bfbfbe; }

[data-active-section="values"] {
  background-color: #89cce2;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="values"] .side-col {
    background-color: #89cce2;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="values"] h1, [data-active-section="values"] h2, [data-active-section="values"] h3, [data-active-section="values"] p, [data-active-section="values"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="values"] #pwcc-nav .mini-svg a[href="#values"] {
    opacity: 0.5; }
    [data-active-section="values"] #pwcc-nav .mini-svg a[href="#values"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#values"]:hover path {
  fill: #89cce2; }

[data-active-section="behaviours"] {
  background-color: #828282;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="behaviours"] .side-col {
    background-color: #828282;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="behaviours"] h1, [data-active-section="behaviours"] h2, [data-active-section="behaviours"] h3, [data-active-section="behaviours"] p, [data-active-section="behaviours"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="behaviours"] #pwcc-nav .mini-svg a[href="#behaviours"] {
    opacity: 0.5; }
    [data-active-section="behaviours"] #pwcc-nav .mini-svg a[href="#behaviours"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#behaviours"]:hover path {
  fill: #828282; }

[data-active-section="foot-notes"] {
  background-color: #828282;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="foot-notes"] .side-col {
    background-color: #828282;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="foot-notes"] h1, [data-active-section="foot-notes"] h2, [data-active-section="foot-notes"] h3, [data-active-section="foot-notes"] p, [data-active-section="foot-notes"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="foot-notes"] #pwcc-nav .mini-svg a[href="#foot-notes"] {
    opacity: 0.5; }
    [data-active-section="foot-notes"] #pwcc-nav .mini-svg a[href="#foot-notes"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#foot-notes"]:hover path {
  fill: #828282; }

[data-active-section="person-whanau"] {
  background-color: #72b847;
  transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="person-whanau"] .side-col {
    background-color: #72b847;
    transition: background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79); }
  [data-active-section="person-whanau"] h1, [data-active-section="person-whanau"] h2, [data-active-section="person-whanau"] h3, [data-active-section="person-whanau"] p, [data-active-section="person-whanau"] li {
    transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
    color: #333; }
  [data-active-section="person-whanau"] #pwcc-nav .mini-svg a[href="#person-whanau"] {
    opacity: 0.5; }
    [data-active-section="person-whanau"] #pwcc-nav .mini-svg a[href="#person-whanau"]:hover {
      opacity: 1; }

#pwcc-nav .mini-svg a[href="#person-whanau"]:hover path {
  fill: #72b847; }

#pwcc-collapsable-nav .nav-toggler {
  display: none; }

#pwcc-collapsable-nav ul {
  list-style: none; }
  #pwcc-collapsable-nav ul ul {
    margin-top: 3px;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden; }
  #pwcc-collapsable-nav ul li {
    line-height: 1.2em;
    font-size: 0.95em;
    position: relative;
    padding: 5px 0 2px;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500; }
    #pwcc-collapsable-nav ul li a {
      color: #fff;
      display: block;
      width: 100%;
      position: relative; }
      #pwcc-collapsable-nav ul li a:hover:after, #pwcc-collapsable-nav ul li a:focus:after {
        width: 100%; }
      #pwcc-collapsable-nav ul li a:after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 0;
        height: 1px;
        background: #fff;
        transition: width 0.2s ease-in-out; }
    #pwcc-collapsable-nav ul li.active > a:after {
      width: 100%; }
    #pwcc-collapsable-nav ul li.active ul {
      max-height: 500px; }
    #pwcc-collapsable-nav ul li ul {
      padding-left: 15px; }
      #pwcc-collapsable-nav ul li ul li {
        padding: 5px 0; }

#pwcc-collapsable-nav > ul {
  padding-left: 0; }

.video-wrapper {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%; }
  .video-wrapper iframe {
    position: absolute;
    width: 100%;
    height: 100%; }

@media (max-width: 991px) {
  .back-to-site {
    height: 30px;
    position: absolute; }
    .back-to-site .back-img {
      display: none; }
  .pwcc-section h1 {
    font-size: 2.5em; }
  .flex-row {
    display: block; }
    .flex-row .side-col {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      display: block;
      position: fixed;
      left: 0;
      z-index: 2;
      padding: 0 30px;
      transition: bottom ease-in-out 0.5s, background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79);
      border-top: 1px #00000059 solid;
      height: 225px; }
      .flex-row .side-col.open {
        height: 100%; }
    .flex-row .main-col {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0 15px; }
      .flex-row .main-col h1 {
        font-size: 2em; }
      .flex-row .main-col h2 {
        font-size: 1.6em;
        line-height: 1.1em; }
      .flex-row .main-col ul {
        padding-left: 20px; }
      .flex-row .main-col blockquote {
        font-size: 1.3em; }
  [data-active-section] .flex-row .side-col {
    bottom: 0; }
  [data-active-section="overview"] .flex-row .side-col {
    bottom: -251px; }
  #page-section-wrap #sub-title {
    padding-top: 10px;
    text-align: center; }
    #page-section-wrap #sub-title h4 {
      font-size: 0.9em;
      margin-top: 0;
      margin: 0;
      max-width: 100%; }
  #pwcc-nav {
    flex-direction: column;
    position: static; }
    #pwcc-nav .mini-svg {
      width: 280px;
      height: 145px;
      max-width: 100%;
      margin: 0 auto -6px; }
  #pwcc-collapsable-nav .nav-toggler {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    display: block;
    border: 0;
    background: transparent;
    padding: 0; }
    #pwcc-collapsable-nav .nav-toggler:focus {
      outline: none; }
    #pwcc-collapsable-nav .nav-toggler .nav-icon {
      display: block;
      width: 20px;
      height: 1px;
      background-color: #fff;
      transform: rotate(0deg);
      transition: background 0.2s ease-in-out; }
      #pwcc-collapsable-nav .nav-toggler .nav-icon:after, #pwcc-collapsable-nav .nav-toggler .nav-icon:before {
        content: '';
        display: block;
        width: 20px;
        height: 1px;
        background-color: #fff;
        transition: transform 0.2s ease-in-out;
        transform-origin: center center; }
      #pwcc-collapsable-nav .nav-toggler .nav-icon:before {
        transform: translateY(-6px) rotate(0deg);
        transition: transform 0.2s ease-in-out; }
      #pwcc-collapsable-nav .nav-toggler .nav-icon:after {
        transform: translateY(5px);
        transition: transform 0.2s ease-in-out; }
  #pwcc-collapsable-nav ul li:not(.active) {
    display: none; }
  #pwcc-collapsable-nav ul ul li {
    font-size: 0.9em; }
  .side-col.open {
    transform: translateZ(1px);
    overflow-y: scroll; }
    .side-col.open:after {
      opacity: 0; }
    .side-col.open #pwcc-nav .mini-svg {
      width: 100px;
      height: 70px; }
    .side-col.open #pwcc-collapsable-nav .nav-toggler .nav-icon {
      background: transparent; }
      .side-col.open #pwcc-collapsable-nav .nav-toggler .nav-icon:before {
        transform: rotate(45deg); }
      .side-col.open #pwcc-collapsable-nav .nav-toggler .nav-icon:after {
        transform: rotate(-45deg); }
    .side-col.open #pwcc-collapsable-nav ul li {
      display: block; } }

.foot-notes {
  padding-left: 15px; }
  .foot-notes li {
    font-size: 0.8em; }

.public-website .dont-say-oh {
  background-image: url("/images/bg-angles.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  padding-top: 30em;
  padding-bottom: 0;
  background-attachment: fixed; }
  .public-website .dont-say-oh.fade-out:before {
    opacity: 0;
    transition: none; }
  .public-website .dont-say-oh:before {
    opacity: 1;
    -webkit-transition: opacity 2s;
    transition: opacity 2s;
    content: "";
    background-image: url("/images/home-hero-telehealth.png");
    background-repeat: no-repeat;
    background-position: center 50px;
    background-size: 720px;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }
  .public-website .dont-say-oh a {
    color: #fff;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
    .public-website .dont-say-oh a i {
      color: #CBDB2A;
      margin-right: 5px; }
  .public-website .dont-say-oh img {
    position: absolute;
    right: 0;
    z-index: 0; }
  .public-website .dont-say-oh .d-flex {
    display: flex;
    justify-content: space-between; }
  .public-website .dont-say-oh .external-links {
    padding-top: 80px;
    text-align: center; }
    .public-website .dont-say-oh .external-links h2 {
      color: #212B36;
      font-size: 1.4em; }
    .public-website .dont-say-oh .external-links .see-more {
      font-size: 0.9em;
      display: block;
      position: relative;
      transition: color 0.2s ease-in-out; }
      .public-website .dont-say-oh .external-links .see-more:before {
        content: "\f30b";
        display: inline-block;
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: 400;
        padding-right: 10px;
        color: #CFE018; }
    .public-website .dont-say-oh .external-links a:hover .see-more {
      color: #CFE018; }
  .public-website .dont-say-oh p,
  .public-website .dont-say-oh h1,
  .public-website .dont-say-oh h2,
  .public-website .dont-say-oh h3 {
    z-index: 3; }
  .public-website .dont-say-oh h1 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #212B36; }
  .public-website .dont-say-oh h4 {
    font-size: 26px; }
    .public-website .dont-say-oh h4 a {
      color: #222c37; }
      .public-website .dont-say-oh h4 a:hover {
        color: #CBDB2A; }
  .public-website .dont-say-oh .content {
    text-align: center; }

.public-website .search-section {
  padding-top: 50px;
  padding-bottom: 55px; }
  .public-website .search-section h1 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #212B36; }
  .public-website .search-section h4 {
    font-size: 26px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px; }
    .public-website .search-section h4 a {
      color: #222c37; }
      .public-website .search-section h4 a:hover {
        color: #CBDB2A; }
      .public-website .search-section h4 a.active {
        border-bottom: 1px solid #fff; }
  .public-website .search-section .content {
    text-align: center; }

.public-website .home-tile {
  text-align: center;
  margin: 6em 0; }
  .public-website .home-tile h4 {
    font-size: 26px; }
  .public-website .home-tile a {
    color: #fff;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
    .public-website .home-tile a i {
      color: #CBDB2A;
      margin-right: 5px; }
    .public-website .home-tile a:hover {
      color: #CBDB2A; }

#keyword-search {
  display: none; }

.search-options-container .form-group {
  overflow: visible; }

.search-options-container .input-group .form-control {
  height: 100%; }

.search-options-container .input-group .input-group-btn {
  font-size: 1.2em;
  font-size: 1em;
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 60px;
  z-index: 9; }
  .search-options-container .input-group .input-group-btn button {
    border: 0;
    background: #212B36;
    border-radius: 0 30px 30px 0;
    background-image: none;
    width: 100%;
    height: 100%;
    border: 0;
    transition: background 0.2s ease-in-out, color;
    font-size: 14px;
    color: #fff;
    padding-right: 30px;
    padding-left: 30px;
    font-style: italic; }
    .search-options-container .input-group .input-group-btn button i {
      margin-right: 5px; }
    .search-options-container .input-group .input-group-btn button:hover {
      background: #CFE018; }

.search-options-container .custom-select {
  border-radius: 30px;
  padding: 0;
  background-color: #fff; }
  .search-options-container .custom-select .select-items div,
  .search-options-container .custom-select .select-selected {
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    padding: 17px 30px;
    color: #9c9c9c; }
  .search-options-container .custom-select .select-selected:after {
    content: "";
    font-weight: 700;
    color: #00BCE7;
    top: 15px; }
  .search-options-container .custom-select .select-items {
    overflow-y: auto;
    padding: 0;
    margin-top: 10px;
    border-radius: 30px;
    background-color: #fff;
    color: #212b36; }
    .search-options-container .custom-select .select-items div {
      padding: 15px 30px; }
      .search-options-container .custom-select .select-items div:hover {
        background-color: #212B36; }

.search-options-container .input-group {
  width: 100%; }
  .search-options-container .input-group .form-control {
    background-color: #fff;
    border-radius: 30px;
    font-size: 18px;
    padding: 15px 30px;
    height: 60px; }

.search-options-container .form-section input {
  margin-bottom: 20px; }
  .search-options-container .form-section input::placeholder {
    font-style: italic; }

.search-options-container .half-col-inputs {
  overflow: visible; }
  .search-options-container .half-col-inputs .form-group:nth-child(even) {
    padding-right: 0; }

.search-options-container .filter-btn .btn.btn-dark-solid {
  padding: 15px 50px;
  border-radius: 30px; }

.search-options-container .filter-btn .btn.btn-dark-solid.customized {
  padding: 15px 50px;
  border-radius: 30px;
  background-color: #aeefff;
  color: #333 !important; }

.search-options-container .filter-btn .btn.btn-dark-solid.customized:hover {
  padding: 15px 50px;
  border-radius: 30px;
  background-color: #cfe018; }

.search-options-container .filter-btn .btn.btn-dark-solid.customized-green:hover {
  padding: 15px 50px;
  border-radius: 30px;
  background-color: #cfe018; }

.search-results-container {
  background-color: #fff;
  padding: 20px 15px 0 20px; }
  .search-results-container > h4 {
    padding-left: 30px;
    padding-right: 30px; }
  .search-results-container h4 {
    margin-bottom: 0;
    font-weight: 200; }
  .search-results-container .search-results-list {
    margin-bottom: 100px;
    height: 700px;
    max-height: 100%;
    position: relative; }
    .search-results-container .search-results-list h2, .search-results-container .search-results-list p {
      color: #212b36; }
    .search-results-container .search-results-list h4 {
      font-weight: 200; }
    .search-results-container .search-results-list .search-results-content {
      margin: 0;
      padding: 15px 30px;
      height: 100%;
      overflow-y: scroll;
      z-index: 1; }
      .search-results-container .search-results-list .search-results-content .panel-default {
        border-top: 2px solid #ededed;
        border-radius: 0;
        margin-top: 0;
        padding: 20px 0; }
        .search-results-container .search-results-list .search-results-content .panel-default > .panel-heading {
          border-bottom: 0;
          padding: 0; }
          .search-results-container .search-results-list .search-results-content .panel-default > .panel-heading + .panel-collapse > .panel-body {
            padding-top: 10px;
            padding-left: 30px; }
          .search-results-container .search-results-list .search-results-content .panel-default > .panel-heading .panel-title a {
            padding-left: 30px; }
            .search-results-container .search-results-list .search-results-content .panel-default > .panel-heading .panel-title a.collapsed::before {
              color: #00BCE7; }
            .search-results-container .search-results-list .search-results-content .panel-default > .panel-heading .panel-title a::before {
              content: "";
              font-family: "Font Awesome 5 Pro";
              font-style: normal;
              font-weight: 900;
              position: absolute;
              left: 0;
              color: #CFE018;
              transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
            .search-results-container .search-results-list .search-results-content .panel-default > .panel-heading .panel-title a:hover::before {
              color: #CFE018; }
      .search-results-container .search-results-list .search-results-content .more {
        color: #00BCE7;
        display: inline-flex;
        vertical-align: text-bottom;
        line-height: 1.6rem;
        cursor: pointer;
        position: relative;
        padding: 12px 0; }
        .search-results-container .search-results-list .search-results-content .more svg {
          display: none; }
        .search-results-container .search-results-list .search-results-content .more:after {
          display: none;
          font-family: "Font Awesome 5 Pro";
          font-style: normal;
          font-weight: 300;
          content: '\f178';
          font-size: 1.8rem;
          line-height: 0.8em;
          transform: translateX(0);
          right: -7px;
          padding-left: 8px;
          display: inline-block;
          transition: transform 0.15s ease-out; }
        .search-results-container .search-results-list .search-results-content .more:hover, .search-results-container .search-results-list .search-results-content .more:focus {
          color: #CFE018; }
          .search-results-container .search-results-list .search-results-content .more:hover:after, .search-results-container .search-results-list .search-results-content .more:focus:after {
            transform: translateX(7px); }
        .search-results-container .search-results-list .search-results-content .more + a {
          margin-left: 15px; }
    .search-results-container .search-results-list::before {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%);
      background-size: 100% 100%;
      height: 50px;
      z-index: 2;
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; }
    .search-results-container .search-results-list::after {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%);
      background-size: 100% 100%;
      height: 50px;
      z-index: 2;
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; }

#search-results-list .panel-default {
  background-color: transparent; }

.physio-card a h4 {
  color: #333;
  transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.physio-card a:hover h4 {
  color: #CBDB2A; }

.physio-card h4 {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  margin-bottom: 10px;
  position: relative; }
  .physio-card h4:before {
    content: '\f3c5';
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 300;
    color: #00BCE7;
    position: absolute;
    left: -25px; }

.physio-card ul {
  list-style: none;
  padding-left: 0; }
  .physio-card ul li {
    padding: 6px 0;
    position: relative; }
    .physio-card ul li:before {
      position: absolute;
      left: -25px;
      color: #00BCE7;
      font-family: "Font Awesome 5 Pro";
      font-style: normal;
      font-weight: 300; }
    .physio-card ul li.address:before {
      content: '\f5a0'; }
    .physio-card ul li.phone:before {
      content: '\f095'; }
    .physio-card ul li.more:before {
      content: '\f061'; }

#your-address,
#address-search {
  display: none; }
  #your-address.active,
  #address-search.active {
    display: block;
    max-height: 425px; }

@media (min-width: 767px) {
  .physio-members {
    display: block !important; } }

@media (min-width: 991px) {
  .public-website .header-wrap .navbar-brand {
    height: 100px;
    overflow: hidden; }
  .public-website .header-wrap .main-nav {
    margin-top: 30px;
    padding-bottom: 30px; }
  .public-website .header-wrap.shrink .navbar-brand {
    margin-top: 12px;
    height: 66px; } }

@media (max-width: 1200px) {
  .public-website .dont-say-oh {
    background-position: center top; }
    .public-website .dont-say-oh:before {
      background-size: 500px;
      margin-top: 3em; }
    .public-website .dont-say-oh .home-tile h4 {
      font-size: 20px;
      line-height: 1.2em; } }

@media (max-width: 991px) {
  .public-website .dont-say-oh .home-tile h4 {
    font-size: 20px;
    line-height: 1.2em; } }

@media (max-width: 767px) {
  .public-website .search-section {
    padding-top: 15px;
    padding-bottom: 15px; }
  .public-website .search-options-container {
    margin: 30px 0 15px; }
  .public-website .home-tile {
    text-align: center;
    margin: 2em 0; }
  .public-website .dont-say-oh {
    padding-top: 15em; }
    .public-website .dont-say-oh:before {
      background-size: 330px;
      margin-top: 0; }
    .public-website .dont-say-oh h4 {
      font-size: 25px;
      line-height: 1.4em; }
    .public-website .dont-say-oh .d-flex {
      display: block; }
    .public-website .dont-say-oh .external-links + .external-links {
      padding-top: 15px; }
    .public-website .dont-say-oh .external-links {
      padding-top: 100px; }
      .public-website .dont-say-oh .external-links .see-more {
        font-size: 1.2em; }
      .public-website .dont-say-oh .external-links h2 {
        font-size: 1.1em; }
  ul.ticklist li {
    width: 100%; }
  .physio-members {
    display: none; }
    .physio-members .fa-li {
      display: none; }
    .physio-members > li {
      padding: 20px 0;
      border-top: 1px rgba(170, 165, 158, 0.3) solid;
      margin-top: 20px;
      display: flex;
      align-items: center; }
      .physio-members > li:nth-last-child(1) {
        border-bottom: 0;
        padding-bottom: 20px; }
      .physio-members > li ul {
        padding-left: 0; }
        .physio-members > li ul li {
          display: inline-block;
          width: 100%; }
          .physio-members > li ul li:after {
            display: none; }
  .physio-member-heading h2 {
    margin: 0;
    display: flex;
    align-items: center; }
  .physio-member-heading.open h2:after {
    padding-top: 10px;
    content: ""; } }

@media (max-width: 580px) {
  .public-website .dont-say-oh .form .form-group {
    max-width: 100%; }
  .public-website .dont-say-oh .d-flex {
    display: block; }
  .public-website .dont-say-oh .external-links + .external-links {
    padding-top: 15px; }
  .public-website .search-section .form-group {
    max-width: 100%; }
  .public-website .search-options-container .input-group .input-group-btn button {
    padding-right: 15px;
    padding-left: 15px; }
    .public-website .search-options-container .input-group .input-group-btn button span {
      display: none; } }

.public-website .demographic {
  background-color: #02bde7;
  background-image: url("/images/bg-angles.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  background-attachment: fixed; }

.public-website .areas-of-help {
  text-align: center;
  margin-bottom: 5em; }
  .public-website .areas-of-help .img-wrap {
    display: flex;
    align-items: center;
    justify-content: center; }
  .public-website .areas-of-help h2 {
    margin-bottom: 15px;
    transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .public-website .areas-of-help a {
    color: rgba(33, 43, 54, 0.6); }
    .public-website .areas-of-help a .overlap-img {
      transition: margin-top 0.2s;
      position: absolute;
      width: 100%; }
      .public-website .areas-of-help a .overlap-img img {
        width: 75%; }
    .public-website .areas-of-help a:hover {
      color: #CBDB2A; }
      .public-website .areas-of-help a:hover .overlap-img {
        margin-top: -15px; }
      .public-website .areas-of-help a:hover h2 {
        color: #CBDB2A; }
    .public-website .areas-of-help a i {
      color: #CBDB2A;
      margin-right: 5px; }

@media (max-width: 991px) {
  .public-website .areas-of-help .img-wrap img {
    max-width: 250px; } }

@media (max-width: 767px) {
  .public-website .areas-of-help .img-wrap img {
    max-width: 200px; } }

.public-website .page-header {
  background-color: #00BCE7;
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0;
  border: 0; }
  .public-website .page-header span.subheading {
    color: rgba(33, 43, 54, 0.6);
    font-size: 28px;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
    .public-website .page-header span.subheading + h1 {
      margin-top: 0; }
  .public-website .page-header:not(.search-banner) h1 {
    margin-bottom: 25px;
    text-align: left;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.8em;
    color: #212B36; }
  .public-website .page-header p {
    text-align: left;
    color: #212B36;
    font-size: 1.5em;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300; }

.public-website .shrink .navbar-brand {
  margin-top: 15px; }

.public-website .shrink .main-nav.navbar.navbar-default {
  padding-bottom: 5px; }
  .public-website .shrink .main-nav.navbar.navbar-default .navbar-nav {
    margin-top: 0; }

.public-website .company-details {
  border-top: 1px rgba(33, 43, 54, 0.2) solid;
  padding-top: 55px; }
  .public-website .company-details p {
    font-size: 16px;
    font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
    font-weight: 500;
    color: #fff; }
  .public-website .company-details h3 {
    margin-top: 0; }
  .public-website .company-details .time-details {
    padding-left: 0;
    list-style: none; }
    .public-website .company-details .time-details li {
      color: #fff;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 80%;
      margin-bottom: 10px; }
  .public-website .company-details .company-contact-details {
    padding: 0;
    list-style: none; }
    .public-website .company-details .company-contact-details li {
      margin-bottom: 30px;
      position: relative;
      padding-left: 40px; }
      .public-website .company-details .company-contact-details li p {
        padding-top: 20px;
        font-size: 16px;
        font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #fff; }
      .public-website .company-details .company-contact-details li a {
        color: #fff;
        font-weight: bold;
        font-size: 24px; }
        .public-website .company-details .company-contact-details li a i {
          color: #212B36;
          margin-right: 10px;
          position: absolute;
          left: 0;
          top: 8px; }
        .public-website .company-details .company-contact-details li a:hover {
          color: #CBDB2A; }

@media (max-width: 767px) {
  .public-website .company-details {
    padding-top: 35px; }
    .public-website .company-details .time-details li {
      width: 100%; }
    .public-website .company-details .company-contact-details {
      margin-bottom: 30px; } }

.physio-members {
  margin-left: 0;
  display: block; }
  .physio-members h3 {
    font-size: 20px;
    margin-bottom: 0.5em; }
  .physio-members .fa-li {
    left: 0; }
    .physio-members .fa-li i {
      font-size: 36px;
      color: rgba(245, 246, 246, 0.5); }
  .physio-members > li {
    padding-left: 3.5em;
    border-top: 1px rgba(170, 165, 158, 0.3) solid;
    margin-top: 20px;
    display: flex;
    align-items: center; }
    .physio-members > li:nth-last-child(1) {
      border-bottom: 1px rgba(170, 165, 158, 0.3) solid;
      padding-bottom: 20px; }
    .physio-members > li ul {
      padding-left: 0; }
      .physio-members > li ul li {
        list-style: none;
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        color: #C4CDD5; }
        .physio-members > li ul li:nth-last-child(1):after {
          display: none; }
        .physio-members > li ul li:after {
          content: "/";
          padding-left: 10px;
          padding-right: 10px; }

.content-footer {
  border-top: 1px #EEEEEC solid;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  align-items: center; }
  .content-footer h4 {
    color: rgba(33, 43, 54, 0.4); }
  .content-footer .rating {
    border: none;
    float: left; }
  .content-footer .rating > input {
    display: none; }
  .content-footer .rating > label:before {
    margin: 0 5px;
    font-size: 1.25em;
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    content: "\f005"; }
  .content-footer .rating > .half:before {
    content: "\f089";
    position: absolute; }
  .content-footer .rating > label {
    color: #a7abaf;
    float: right;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300; }
  .content-footer .rating > input:checked ~ label,
  .content-footer .rating:not(:checked) > label:hover,
  .content-footer .rating:not(:checked) > label:hover ~ label {
    color: #FFD700;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .content-footer .rating > input:checked + label:hover,
  .content-footer .rating > input:checked ~ label:hover,
  .content-footer .rating > label:hover ~ input:checked ~ label,
  .content-footer .rating > input:checked ~ label:hover ~ label {
    color: #FFED85;
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700; }
  .content-footer .social-nav {
    float: left; }
    .content-footer .social-nav ul {
      padding: 0;
      list-style: none; }
      .content-footer .social-nav ul li {
        display: inline-block;
        margin-right: 20px; }
        .content-footer .social-nav ul li a {
          color: rgba(33, 43, 54, 0.4); }
          .content-footer .social-nav ul li a:hover {
            color: #212B36; }

@media (max-width: 767px) {
  .content-footer {
    display: inline-block;
    width: 100%; }
    .content-footer .social-nav {
      float: left;
      width: 100%; } }
