.featured-journal{

    .featured-information{
        float: right;
        h1 {
            text-align: left;
            font-weight: bold;
            margin-bottom: 0;
        }
        h2{
            margin-top: 0;
        }
        small {
            text-transform: uppercase;
            @include font-bold;
        }
        ul{
            color: #ffffff;
            padding-left: 17px;
            li{
                color: #ffffff;
            }
        }
    }
    .featured-img{
        float: left;
        max-width: 350px;
        img{
            max-width: 100%;
            box-shadow: 10px 10px 54px 0px rgba(0,0,0,0.3);
        }
    }

    .icons {
        list-style: none;
        padding: 0;
        li {
            width: 50%;
            float: left;
            margin-top: 40px;
            padding-right: 20px;

            i{
                color: #fff;
                margin-right: 10px;
                font-size: 2.8em;
            }
            a{
                display: flex;
                align-items:center;
                color: #292F36;
                @include font-bold;
                &:hover{
                    color: #ffffff;
                }
            }
        }
    }
    .logged-out &{
        .icons li:not(:last-of-type){
            opacity: 0.5;
        }
    }

}


@media(max-width: 580px){
    .featured-journal{
        .featured-information{
            float: none;
        }
         .featured-img{
            max-width: 150px;
            margin-bottom: 30px;
         }
    }

}