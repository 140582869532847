.team-member{
	margin-top: 30px;
	margin-bottom: 30px;
	.img-circle{
		width: 75px;
		height:75px;
		border-radius:50%;
		overflow: hidden;
	}

	

}
.job-description,
	.contact-details{
			list-style: none;
		padding-left: 0;
			li{
				display: inline-block;
				& + li{
					padding-left: 5px;
					&::before{
						content: '|';
						margin-right: 5px;
					}
				}
			}
	}	
	.job-description{
		margin-bottom: 25px;
		color: #bbb;
	}
	.contact-details{
		margin-top: 25px;
		color: $brand-color;
	}
	