@keyframes outToTop {
	from {
		opacity: 1;
		transform: translate3d(0 , 0, 0 );
	}
	to{
		opacity: 0;
		transform: translate3d(0 , 100%, 0 );
	}
}

@keyframes inFromBottom {
	from {
		opacity: 0;
		transform: translate3d(0 , 100%, 0 );
	}
	to{
		opacity: 1;
		transform: translate3d(0 , 0, 0 );
	}
}

#keyword-search {
	.input-group{
		.form-control{
			font-size: 18px;
		}
	}
}

.search-options-container{
	margin: 60px 0;
}

.search-banner-content{
	background-image: linear-gradient(-180deg, rgba(254, 254, 254, 0) 37%, #107590 100%);
	position: relative;
	h1{
		text-align: center;
	}
	&:after{
		content: '\f0d7';
		position: absolute;
		color: #007591;
		left: 50%;
		transform:translateX(-50%);
		@include fontawesome-solid;
		bottom: -37px;
		font-size: 3em;
		z-index: 1;

	}
}

#multi-step-filter{
	position: relative;
	.filter-steps{

		overflow: hidden;

		.step{
			padding: 10px 15px;
			width: 50%;
			float: left;
			color: #035365;
			text-align: center;
			@include font-bold;
			transition:opacity 0.2s ease-in-out, color 0.2s ease-in-out;
			font-size: 1em;


			&.disabled{
				opacity: 0.6;
				border-bottom: #6ad2eb;
			}
			&:not(.disabled){
				cursor: pointer;
				border-bottom: 3px solid #98def1 ;
				&:hover,
				&:focus{
					color: #CBDB2A;
				}
			}
			&.active{
				border-bottom: solid 3px #CBDB2A;
			}
			span{
				display: block;
				height: 30px;
				overflow: hidden;
				font-size: 1.1em;
				color:#fff;
				p{
					font-size: 1.1em;
					@include font-bold;
					&.set{
						animation: inFromBottom 0.4s both cubic-bezier(0.7, 0, 0.3, 1) ;
					}
					&.unset{
						animation: outToTop 0.4s both cubic-bezier(0.7, 0, 0.3, 1) ;
					}
				}
			}
		}
	}

	.filter-options{
		background: rgba(33,43,54,0.40);
		padding: 15px 15px 20px; ;
		height: 350px;
		position: relative;
		z-index: 1;
		border-radius: 0 0 25px 25px;
		overflow: hidden;
		
		.filter-wrap{
		height: 317px;
		position: relative;
		z-index: 1;
		padding-top: 0;
		@include custom-scrollbar($brand-color, lighten($brand-color, 20%), transparent, 10px);
	
		overflow-x:hidden;
		}
		.filter-title{
			font-weight: 700;
		}
		.regions,
		.districts,
		.suburbs{
			position: absolute;
			top: 15px;
			left: 15px;
			display: none;
			

			&.inactive{
				display: block;
				animation: outToTop 0.4s both cubic-bezier(0.7, 0, 0.3, 1) ;
			}
			&.active{
				display: block;
				animation: inFromBottom 0.4s both cubic-bezier(0.7, 0, 0.3, 1) ;
			}
		}

		ul{

			list-style: none;
			flex-direction:column;
			flex-wrap:wrap;
			max-height: 280px;
			padding-left: 0;
			display: flex;

			li{
				display: inline-block;
				text-align: left;
				padding: 10px ;
				color: #fff;
				transition:color 0.2s ease-in-out;
				// width: 190px;
				width: 100%;
				margin-right: 20px;
				&:hover{
					cursor: pointer;
					color: #CBDB2A;
				}
			}
		}

	}
	button{
		position: absolute;
		left: 50%;
		bottom: -80px;
		transform:translateX(-50%);
		z-index: 2;
		opacity: 1;
		transition:0.2s ease-in-out opacity;
		margin:0;
	}
	&.closed{
		button{
			opacity: 0
		}
	}
}

#tags-filter{
	z-index: 0;

	.tag-options{
		border-bottom: #0396B6 solid 2px;
		padding-top: 40px;

		ul{
			list-style: none;

			li{
				display: inline-block;
				margin-right: 25px;

				&.active{
					a{
						color: #035365;
					}
				}
				a{
					&:hover,
					&:focus,
					&:active{
						color: #035365;
					}	
				}

			}
		}

	}
	.panel{
		background-color: transparent;
		border:0;
		box-shadow: none;
		margin-bottom: 0;
		ul{
			border-bottom: 2px #eee solid;

			padding-bottom: 50px;
			list-style: none;
			display: flex;
			flex-direction:column;
			padding-top: 50px;
			flex-wrap:wrap;
			height: 250px;
			margin-bottom: 0;
			li{
				text-align: left;
				font-size: 1em;
				padding: 5px 0;
				a{
					@include font-regular;
					color: $brand-darkblue;
					&:hover{
						color: $brand-color;
					}
				}
			}
		}
	}
}

.filter-btn{
	text-align: center;
	.btn{
		margin-top: 30px;
	}

}
@media(max-width: 991px){
	#tags-filter .panel ul{
		max-height: 350px;
	}
		.filter-options {
			.filter-wrap{
					overflow-y: scroll;
			}
		}

}
@media(max-width: 767px){

	#tags-filter .panel ul {
		height: auto;
	}

	#multi-step-filter{
		.filter-steps{
			.step{
				font-size: 0.8em;
				span{
					p{
						font-size: 1.1em;
					}
				}
			}
		}
		.filter-options {
			height: 350px;
			padding: 15px;
			.filter-wrap{
					overflow-y: scroll;
			}
			ul{
				display: block;
				max-height: 100%;
				li{

				}
			}
			&:after, 
		&:before{
			content:'';
			position: absolute;
			width: 100%;
			left: 0;
			height: 20px;
			z-index: 2;

		}
		&:before{
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,0) 0%,  #247f9a 100%);
			top:15px;
		}
		&:after{
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%,  #1c6279 100%);
			bottom:15px;
		}
		}


	}

}
