.sponsor{
	margin-bottom: 50px;
	.sponsor-logo{
		margin-bottom: 50px;
		max-height: 60px;
		max-width: 300px;
	}
	h2{
		font-size: 1.7em;
		margin-bottom: 20px;
	}
}

@media(max-width: 580px){
	margin-bottom: 30px;
}