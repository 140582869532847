////////
//footer
////////
footer{
	padding: 50px 0 20px;
	background: $brand-darkblue;
	position: relative;
	z-index: 3;
	
	.footer-logo{
		margin-bottom: 20px;
		img{
			width: 100px;
		}

	}
	.movement-logo{
		img{
			width: 80px;
		}

	}
	a{
		color: #fff;
		
		display: inline-block;
		margin-right: 20px;
		&:hover,
		&:focus{
			color:$brand-color;
		}
		i{
			font-weight: normal;
		}
	}
	ul{
		list-style:none;
		padding-left: 0;

		li{
			display: inline-block;
			margin-right: 30px;
			a{
				@include font-regular;
			}

		}
	}
	.footer-nav{
		border-bottom: 1px solid #4A5054;
		padding-bottom: 10px;
		margin-bottom: 20px;
		margin-top: 30px;
	}
	.social-nav{
		font-size: 1.5em;
	}
	p{
		font-size: 0.8em;
		color: #73838b;
		small a{
			color: #73838b;
		}
	}
	.legal{
		float: right;
		color: #fff;
	}
}

@media (max-width: 991px){
	footer {
		ul {
			li{
				a {
					font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
					font-weight: 300;
					margin-bottom: 10px;
				}
			}
		}
		.social-nav{
			font-size: 1.2em;
			margin-right: 15px;
		}
	}
}