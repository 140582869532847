.reasons-to-join{
	margin-bottom: 40px;
	
	h2{
		color: #fff;
		@include font-bold;
		font-size: 106px;
		margin-bottom: 10px;
		line-height: 1em;
	}
	h3{
		margin-top: 0;
		color: $brand-darkblue;
		@include font-bold;
	}
	a{
		color: $brand-color;
	}
}