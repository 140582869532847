////
//Variables
////

//color variables
$darker-grey :#d2d0d0;
$grey-text: rgba(33, 43, 54, 0.4);
$lighter-grey : #EEEEEC;

$linkedin-color:#0077B5;
$twitter-color:#2CAAE1;


$brand-color: #00BCE7;
$brand-highlight: #00ACD4;
$brand-lightblue: #5a7382; 
$brand-darkblue: #212B36;
$brand-lightgreen: #CFE018;

$link-color: $brand-color;
$link-highlight: #CBDB2A;

$heading-color: $brand-darkblue;
$error-color: #E6302F;



//fonts
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville");


@mixin fontawesome-light(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 300;

}
@mixin fontawesome-solid(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 900;
}
@mixin fontawesome-regular(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 400;
}
@mixin fontawesome-brand(){
	font-family: "Font Awesome 5 Brands ";
	font-style: normal;
	font-weight: 400;
}

@mixin font-light(){
	font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 300;
}
@mixin font-regular(){
	font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}

@mixin font-med(){
	font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
}

@mixin font-bold(){
	font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
}

