	.tab-nav{
		padding-bottom: 20px;
		border-bottom: 1px solid #efefef;
		ul{
			list-style: none;
			padding-left: 0;
			li{
				display: inline-block;
				margin-right: 15px;
				position: relative;
				a{
					color: #888;
					&:hover{
						color: $brand-color;
					}
				}
				&.active{
					a{
						color: $brand-color;
					}
				}
				& + li{
					margin-left: 15px;
				}

			}
		}
	}
