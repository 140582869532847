.webinar{
	margin-bottom: 40px;
	.social{
		font-size: 1.7em;
		margin-bottom: 30px;
		i{
			margin-right: 15px;
		}
	}
	.register-block{
		text-align: center;
		margin-top: 25px;
		i{
			margin-right: 15px;
		}
	}
}

.event-info{
	.event-info-list{
		text-align: left;
		margin-bottom: 30px;
		li{
			margin-bottom: 15px;
		}
		& + .btn{
			margin-bottom: 40px;
		}
	}
}

#event-details{
	    margin-top: 4em;
}

.speaker{
	margin: 15px 0 40px;
	overflow: hidden;
	.modal-header &{
		margin: 15px 0;
	}
	.img-round{
		height: 150px;
		width: 150px;
		margin-right: 15px;
		float: left;
		.img-container{
			padding-bottom: 0;
		}
	}
	.speaker-info{
		float: left;
		h5{
			font-size: 1.4em;
			margin-top: 0;
			color: $brand-darkblue;
			@include font-regular;
		}
		small{
			display: block;
			color: #888;
		}
		a{
			font-size: 0.8em;
			cursor: pointer;
		}
	}
	&.item{
		.img-round{
			float: none;
			margin-bottom: 10px;
		}
		small{
			margin-bottom: 15px;
		}
	}
}

.programme{

	.programme-controls{
		padding-bottom: 30px;
		.btn.btn-default{
			background: #d7deda;
			position: relative;
			margin-right: 20px;
			&:after{
				content:'\f0d7';	
				display: block;
				@include fontawesome-solid;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform:translateX(-50%);
				color: $brand-darkblue;
				opacity: 0;
			}
			&.active{
				background: $brand-darkblue;
				&:hover{
					color: #fff;
				}
				&:after{
					bottom: -14px;
					opacity: 1;
				}
			}
		} 
	}
	.panel-group{
		display: none;
		&.active{
			display: block;
		}
	}
	.panel-default > .panel-heading .panel-title{
		[class^="fa"]{
			color: $brand-lightblue;

			a:hover &, 
			a:focus &{
				color: $brand-darkblue;
			}
		}	
		.time{
			font-weight: normal;
			margin-right: 20px;
		}	
	} 

}


.modal{

	.modal-header{
		.speaker-info{
			margin-left: 15px;
		}
		.contact-details{
			margin-top: 4px;
			font-size: 0.9em;
		}
	}

}

.presenters{
	margin-bottom: 60px;
	overflow: hidden;
}
.sponsor{
	h2{
		margin-top: 0;
	}
}

@media(max-width: 580px){

	.programme{
		.programme-controls{
			.btn.btn-default{
				margin-right: 10px;
				margin-bottom: 10px;
			} 
		}
	}

	
}


//---
.event-card{
	.img-container{
		.event-alert{
			display: block;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			position: absolute;
			left: 10px;
			bottom: 10px;
			background: $brand-lightblue;
			z-index: 3;
			text-align: center;
			&:after{
				display: block;
				margin-top: 6px;
				font-size: 1.2em;
				color: #fff;
				width: 100%;
				height: 100%;

			}
			&.end-soon{
				&:after{
					content:'\f017';
					@include fontawesome-regular;
				}
			}
			&.early-bird{
				&:after{
					content: '\f535';
					@include fontawesome-solid;
				}
			}
			&.video{
				&:after{
					content: '\f03d';
					@include fontawesome-solid;
				}
			}
		}
	}
	.alert-message{
		color: $brand-darkblue;
	}
	.location{
		color: $brand-lightblue;
	}
	.alert-message,
	.location{
		margin-bottom: 8px;
		font-size: 0.8em;
		@include font-bold;
	}
}