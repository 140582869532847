$conference-color: #D9182B;

.conference-header{
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0 50px;
	position: relative;
	&.light-bg{
		h1{
			color: $brand-darkblue;
		}	
		.countdown {
			div{
				color: $brand-darkblue;
				span{
					color: $brand-darkblue;
					border-color: $brand-darkblue;
				}
			}
		}
		.event-details{
			ul{
				color: $brand-darkblue;
			}
		}
	}
	
	h1{
		color: #fff;
		margin-bottom: 20px;
	}

	.event-details{
		ul{
			color: #fff;
			list-style: none;
			padding-left: 0;
			margin-bottom: 20px;
			li{
				color: #ffffff;
			}
		}
		
	}
	&:before{
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.82;
		background-image: linear-gradient(270deg, rgba(0,76,93,0.00) 0%, #00BCE7 100%);
	}
}
.countdown {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	margin-top: -14px;
	position: absolute;
	bottom: 0;
	right: 30px;

	opacity: 0;
	transition:opacity 0.2s ease-in-out;

	&.visible{
		opacity: 1;
	}
	div {
		color: #fff;
		display: inline-block;
		margin-left: 25px;
		font-size: 45px;
		font-weight: bold;
		line-height: 1.3;
		text-align: right;
		&:first-child {
			margin-left: 0;
		}
		span {
			text-align: center;
			display: block;
			color: #fff;
			border-top: solid 5px #fff;
			min-width: 60px;
			padding-top: 3px;
			font-size: 20px;
			font-weight: normal;
			text-transform: uppercase;
			text-align: left;
			text-align: center;
		}
	}
}




/* IE7 inline-block hack */


@media(max-width: 1200px) {
	.countdown {
		margin-top: -8px;
		div {
			font-size: 40px;
		}
	}
}

@media(max-width: 991px) {
	.countdown {
		margin-top: -1px;
		div {
			font-size: 35px;
			margin-left: 15px;
		}
	}
}

@media(max-width: 768px) {
	.conference-header{
		padding-bottom: 50px;
	}
	.countdown {
		display: block;
		position: relative;
		padding-top: 20px;

		div {
			font-size: 40px;
			margin-left: 20px;
		}
	}
}

@media(max-width: 480px) {
	.countdown {
		margin-top: -1px;
		div {
			font-size: 30px;
			margin-left: 15px;
			span {
				font-size: 15px;
			}
		}
	}
}

.conference-content{
	padding-bottom: 60px;

	.key-colours {
    padding: 0;
    list-style: none;
    li{
    	margin-bottom: 20px;
    	margin-right: 20px;
    	display: flex;
    	align-items: center;
    	float: left;
    	&.concussion{
    		&:before {
			background-color: #26b8e6;
        }
    	}
        &.physical-activity{
        	&:before {
			background-color: #b8082d ;
        }
    	}
        &.musculoskeletal{
        	&:before {
			background-color: #f6a100;
        }
    	}
        &.acture-care{
        	&:before {
			background-color: #c3d500;
        }
    	}
        &.models-of-care{
        	&:before {
			background-color: #000000;
        }
    	}
        &.other{
        	&:before {
			background-color: #F6F6F7;
        }
    	}
    	&:before {
    content: "";
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 15px;
    background-color: #eee;
}
}
}
	.content{
		padding: 30px;
		background: #fff;
		overflow: hidden;
	}

	.tab-nav{
		ul{
			li{
				a{
					color: #888;
					&:hover{
						color: $conference-color;
					}
				}
				&.active{
					a{
						color: $brand-color;
					}
				}

			}
		}
	}
	.lead, 
	.intro{
		margin-bottom: 40px;
	}
	.feature-list{
		li{
			&::before{
				color: $conference-color;
			}
		}
		
	}
	.speaker{
		.speaker-info{
			h5{
				color: $conference-color;
			}
		}
	}
}
@media(max-width: 580px){
	.conference-content{
		.speaker{
			.img-round{
				max-width: 100%;
			}
		}
	}
}