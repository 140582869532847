.dont-say-oh{
	position: relative;
	background-repeat: no-repeat;
	background-position: top right;
	padding-bottom: 50px;
	overflow: hidden;
	img{
		position: absolute;
		right: 0;
		z-index: 0;
	}
	p, 
	h1, 
	h2, 
	h3{
		z-index: 3;
	}
	.right-content{
		&::before,
		&::after{
			content: '';
			position: absolute;
			top: -50px;
			background-repeat: no-repeat;
		}
		&::before{
			left: -15px;
			
			width: 200%;
			height: calc(100% + 110px);
			background-image: url('/images/bg-angle-small.png'); 
		}
		&::after{
			right: -30px;
			width: 327px;
			height: 387px;
			background-size: contain;
			background-image: url("/images/dont-say-oh-lady-small.png");
		}
	}
	.left-content{
		.left-text:first-of-type{
			margin-bottom: 30px;
		}
	}
}




@media(max-width: 991px){

	.dont-say-oh{
		padding-top: 385px;
		background-position: 90% top;
		background-position: top center;
.right-content{
		&::before{
			top:-385px;
			width: 150%;
			left: -121px;
			background-size: cover;
		}
		&::after{
			top:-385px;
			left: 50%;
			transform:translate(-50%);
		}
	}

		h2{
			margin-top: 40px;
		}
	}	
}

@media(max-width: 768px){
	.dont-say-oh{
		h2{
			margin-top: 20px
		}
		.btn{
			margin-bottom: 30px;
		}
	}
}