
@keyframes scale-down-center {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.85);
	}
	100% {
		transform: scale(1);
	}
}


.share-container{
	position: relative;
	padding-top: 30px;
	.share-toggle{
		border: 0;
		background: #e6e5dd;
		color: $brand-darkblue;
		border-radius: 50%; 
		width: 35px;
		height: 35px;
		font-size: 14px;
		z-index: 1;
		position: absolute;
		right:0;
	}
	ul{
		display: inline-block;
		list-style: none;
		background: #f3f3f3;
		margin-left: -35px;
		z-index: 0;
		position: absolute;
		border-radius:35px;
		padding-left: 0;
		padding-top:40px;
		right: 0;
		li{
			display: block;
			padding: 7px 10px;

			a{
				color: $brand-lightblue;

				
				&:hover,
				&:active{
					color: $brand-darkblue;
				}
				&:focus{
					animation: scale-down-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
				}
			}
		}
	}
}

@media( max-width: 1199px){

	.share-container{
		padding-bottom: 30px;

		.share-toggle{
			left: 0px;
			right: initial;
			position: absolute;
		}
		ul{
			left: 35px;
			padding-left: 35px;
			padding-top: 0;
			right: initial;
			li{
				display: inline-block;
			}
		}
	}

}