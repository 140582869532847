.directory-filters{
	margin-top: -21px;
	background: $brand-darkblue;
	padding: 15px 0;
	select{
		color: $brand-darkblue;
		background: $brand-color;
		border:0;
		& + select{
			margin-left: 30px;
		}
	}
	& + .tint-bg{
		margin-top: 0;
	}
	.form-group{
		margin-bottom: 0;
		.form-control{
			background: transparent;
			border-radius: 0;
			border:0;
		}
		.input-group-addon{
			background: transparent;
			color: #fff;
			border:0;
		}
	}
}


.category-nav{
	list-style: none;
	padding-left: 0;
	margin: 30px 0 45px;
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(170, 165, 158, 0.30);
	position: relative;
	text-align: center;
	padding-bottom: 30px;
	li{
		display: inline-block;
		padding-bottom: 3px;
		margin-right: 20px;
		a{
			color: #AAA59E;
			@include font-bold;
			&:hover,
			&:focus{
				color: $brand-color;
			}
		}
		&.active{
			a{
				color: $brand-color;
				&:hover,
				&:focus{
					color: #AAA59E;
				}
			}

		}

		span{
			color: #b3b9b6;
			transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
	}
	a{
		color: $brand-darkblue;

		&:hover{
			color: $brand-color;
			span{
				color: $brand-color;

			}
		}
	}

}
.directory-controls{
	position: absolute;
	right: 30px;
	top: 0;
	padding-top: 15px;
	a{
		font-size: 1.8em;
		background: $brand-color;
		color: #fff;
		border-radius: 50%;
		width: 48px;
		height: 48px;
		
		
		text-align: center;
		margin-right: 10px;
		display: flex;
		justify-content:center;
		align-items:center;
		float: left;
		transition:background-color 0.2s ease-in-out;
		&:hover,
		&:focus{
			background:$brand-darkblue;
		}
	}
}


.media.document{
	margin-bottom: 50px;

	.media-left{
		width: 33%;
		display: block;
		float: left;
	}
	.media-body{
		width: 65%;

		display: block;
		float: left;
		padding-left: 15px;
		padding-bottom: 30px;
		position: relative;

		small{
			display: block;
			color: $brand-color;
			@include font-bold;
			margin-bottom: 10px;
		}
		h3{
			margin-bottom: 15px;
		}
	}

	.media-footer{
		overflow: hidden;
		width: calc(100% - 20px);
		position: absolute;
		display: flex;
		justify-content:space-between;
		bottom: 0;
		a + a{
			margin-left: 30px;
		}
		small{
			color: #999;
		}
	}
}




@media(max-width: 580px){
	.category-nav {
		li {
			margin-bottom: 10px;
		}	
	}

	.media.document{

		.media-left{
			text-align: left;
			width: 100px;
		}
		.media-body{
			padding-top: 15px;
			width: 100%;
			padding-left: 0;
		}
	}

}
#search-bar{
	.form-group{
		overflow: visible;
	}
}

//- search results

.search-result{

	border-top: 1px solid #d4d4d4;
	padding-top: 15px;
	margin-bottom: 15px;
	overflow: hidden;

	h3{
		margin-top: 0;
		margin-bottom: 7px;
		font-size: 1.3em;
		@include font-bold;
	}
	p{
		font-size: 0.9em;
		margin-bottom: 7px;
	}
	.tag-list{
		margin-bottom: 0;
		padding-bottom: 0;
		border:0;
		li{
			font-size: 0.8em;
		}
		li:first-of-type{
			padding-left: 0;
		}
		li:last-of-type{
			position: relative;
			font-weight: normal;
			&:after{
				content: '';
				display: none;
			}
		}
	}
	small{
		font-style: italic;
	}
	a{
		display: block;
		overflow: hidden;
		small, 
		h3{
			color: #333;
			transition:color 0.2s ease-in-out;
		}
		&:hover{
			small, 
			h3{
				color: $brand-color;
			}
		}
	}
}



