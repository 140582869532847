.content-footer {

  border-top: 1px $lighter-grey solid;
    padding-top: 20px;
    padding-bottom: 40px;
  display: flex;
  align-items: center;

  h4{
    color: $grey-text;
  }

.rating { 
  border: none;
  float: left;
}

.rating > input { 
  display: none;
 } 

.rating > label:before { 
  margin: 0 5px;
  font-size: 1.25em;
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  content: "\f005";
}

.rating > .half:before { 
  content: "\f089";
  position: absolute; 
}

.rating > label { 
  color: #a7abaf; 
 float: right; 
 @include font-light;
}

.rating > input:checked ~ label, 
.rating:not(:checked) > label:hover, 
.rating:not(:checked) > label:hover ~ label { 
  color: #FFD700; 
  @include font-bold;
 } 

.rating > input:checked + label:hover, 
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, 
.rating > input:checked ~ label:hover ~ label { 
  color: #FFED85; 
  @include font-bold; 
} 



.social-nav {
  float: left;
  ul {
        padding: 0;
    list-style: none;
    li {
    display: inline-block;
    margin-right: 20px;
    a{
      color: $grey-text;
      &:hover{
        color: $brand-darkblue;
      }
    }
}
}
}
}

@media(max-width:767px) {
   .content-footer {
  display: inline-block;
  width: 100%;
  .social-nav {
  float: left;
  width: 100%;
}
}
  }
