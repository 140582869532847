.dashboard-header{

	background-image: url('/images/dashboard-header.jpg') ;
	padding-top: 180px;
	background-position: center;
	.banner-content{
		position: relative;
		padding-bottom: 40px;
	}
	.cta-counter{
		width: 170px;
		height: 170px;
		background: #fff;
		border-radius: 50%;
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		.cta-content{
			
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction:column;
			justify-content:center;
			align-items:center;

		}
		.cta{
			color: #06D6A0;
			font-size: 4em;
			line-height: 1em;
			@include font-bold;
			padding-bottom: 20px;
		}
	}

	h3, 
	h1{
		color: #fff;
		margin: 10px 0;
	}
	h3{
		font-size: 1.3em;
	}
	p{
		color: #fff;
		opacity: 0.58;
		@include font-regular;
		font-size: 1.2em;
	}

	.nav-container{
		background: rgba($brand-darkblue, 0.5);
		padding:25px 0;
		.col-xs-12{
			padding: 0 15px;
		}
		ul{
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
			li{
				display: inline-block;
				margin-right: 20px;

				a{
					color: #fff;
					&:hover,
					&:focus{
						color: $brand-color;
					}
				}
				&.active{
					a{
						color: $brand-color;
					}
				}
			}
		}
	}

}

.notification{
	background-color: #06D6A0;
	color: #212B36;
	padding: 25px 0 25px;
	position: relative;



	.col-xs-12{
		position: relative;
		padding-left: 40px;
	}
	.fa-bell{
		color: #fff;
		position: absolute;
		left: 0;
		top: 50%;
		transform:translateY(-50%);
		font-size: 1.7em;
	}
	p{
		color: #212B36;
		margin-bottom: 4px;
		& + a{
			font-size: 0.8em;
		}
	}
	a{
		color: #212B36;

		&:hover,
		&:focus{
			color: #fff;
		}
	}
	.close{
		opacity: 1;
		color: #fff;
		position: absolute;
		top: 50%;
		right: 0;
		transition:opacity 0.2s ease-in-out;

		&:hover,
		&:focus{
			opacity: 0.5;
		}
	}
	& + .notification{

		.col-xs-12{
			padding-top: 20px;
			margin-top:-25px;
			border-top: #47D5AF 2px solid;
		}
	}

}


.dashboard{
	.card-section{

		small{
			color: #AFABA3;
		}
		h2{
			margin-top: 5px;
			@include font-regular;
		}

		& + .card-section{
			padding: 50px 0;
		}

	}
	.blue-bg{
		small{
			color: #fff;
			text-transform: uppercase;
			font-weight: 500;
		}
		h2{
			margin-top: 5px;
			@include font-regular;
			color: #212B36;
			margin-bottom: 30px;
		}
		h3{
			margin-top: 0;
			margin-bottom: 10px;
		}
		a{
			color: #fff;
			@include font-med;
			&:hover,
			&:focus{
				color: $brand-lightgreen;
			}
		}
		.sig{
			margin-bottom: 50px;
		
		}
		.far{
			color: #212B36;
		}
	}
}

@media(max-width:991px){
	.dashboard .tint-bg{
		margin-top: 0;
	}
}

.tag-list{
	border-bottom: #F2F1F0 2px solid;
	list-style: none;
	padding-left: 0;
	padding-bottom: 15px;
	position: relative;
	margin-bottom: 40px;
	li{
		display: inline-block;
		padding-right: 20px;
		font-size: 0.9em;
		color: #AAA59E;
		&:first-of-type{
			padding-left: 30px;
			position: relative;


			&:before{
				position: absolute;
				left: 0;
				@include fontawesome-light;
				color: $brand-lightgreen;
			}
		}
		&:last-of-type{
			position: absolute;
			padding-right: 20px;
			right: 0;
			top: 0;
			font-weight: 700;
			&:after{
				content:'\f054';
				@include fontawesome-solid();
				color: $brand-color;

			}
		}
	}
	&.tags{
		li{
			&:first-of-type{
				&:before{
					content: "\f02c";

				}
			}
		}
	}
	&.preferences{
		li{
			&:first-of-type{
				&:before{
					content: "\f0ae";

				}
			}
		}
	}
}

@media(max-width:991px){
	.tag-list{
		margin-bottom: 20px;
		li{
			&:not(:first-of-type):not(:last-of-type){
				display: none;
			}
		}
	}
}


.member-dropdown-toggle{
	background-color: #171F26;
	padding: 17px;
	border-radius: 30px;
	display: block;
	width: 175px;
	.mypnz{
		color: #fff;
		margin-right: 15px;
		transition:color 0.2s ease-in-out;
		i{
			margin-right: 10px;
			transition:transform 0.2s ease-in-out;
			transform:rotate(0deg);
		}
	}
	.initials{
		color: #CFE018;
	}
	&:hover,
	&:focus{
		.mypnz{
			color: #CFE018;

		}
	}
}

@media(max-width:991px){
	.tools{
		.dropdown{
			float: left;
			display: block;
			&.open{
				.dropdown-menu{
					transform:translateX(0);
					z-index: 8;
					padding-left: 25px;
				}
			}
			.dropdown-menu{
				position: fixed;
				top: 67px;
				left: 0;
				width:100%;
				height: calc(100vh - 70px);
				overflow-y:scroll; 
				background-color: $bg-color;
				border:0;
				
				padding: 2em 0;
				z-index: 1;
				transition: box-shadow 0.5s 0.1s;
				box-shadow: none;
				transition: height 0.2s ease-in-out, transform 0.4s ease-in-out;
				display: block;
				transform:translateX(100%);
				&.open{
					transform:translateX(0);
					z-index: 8;
				}
				li {
					text-align: left;
					position: relative;
					padding: 0 0 15px;
					& > a:not(.btn) {
						padding: 13px 25px 13px 0;
						font-size: 1.2em;
						white-space: normal;
						justify-content: left;
						line-height: 1.4em;
						color: #fff;

						&:focus,
						&:hover {
							color: $brand-color;

						}
					}
					&.dropdown-heading{
						@include font-light;
						width: 100%;
						padding-top: 15px;
						font-size: 1.7em;
						color: #fff;
						padding-bottom: 12px;
						margin-bottom: 15px;
						flex-basis:100%;
					}
					i{
						color: #CFE018;
					}
					small{
						display: block;
					}
				}
			}

		}
		.search-toggle{
			padding-right: 15px;
			padding-top: 10px;
			a{
				color: #fff;
				&:hover,
				&:focus{
					color: $brand-color;
				}
			}
		}
	}
	.member-dropdown-toggle{
		background-color: #171F26;
		padding: 10px 15px;
		border-radius: 30px;
		display: block;
		width: 122px;
	}
	.toggle-wrap{
		form{
			max-width: none;
			width: 100%;
			padding-right: 50px;
			.input-group{
				width: 100%;
			}
		}
	}
}