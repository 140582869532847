

.public-website{
	.dont-say-oh{
		background-image: url("/images/bg-angles.png");
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		position: relative;
		padding-top: 30em;
		padding-bottom: 0;
		background-attachment: fixed;

		&.fade-out{
			&:before{
				opacity: 0;
				transition: none;
			}
		}
		&:before{
		    opacity: 1;
		    -webkit-transition: opacity 2s;
		    transition: opacity 2s;
		    content: "";
		    background-image: url("/images/home-hero-telehealth.png");
		    background-repeat: no-repeat;
		    background-position: center 50px;
		    background-size: 720px;
		    position: absolute;
		    height: 100%;
		    width: 100%;
		    top: 0;
		    left: 0;
		}

		a{
			color: #fff;
			@include font-bold;
			i{
				color: #CBDB2A;
				margin-right: 5px;
			}
		}
		img{
			position: absolute;
			right: 0;
			z-index: 0;
		}
		.d-flex{
			display: flex;
			justify-content:space-between;
		}
		.external-links{
			padding-top: 80px;
			text-align: center;

			h2{
				color: #212B36;
				font-size: 1.4em;
			}

			.see-more{
				font-size: 0.9em;
				display: block;
				position: relative;
				transition:color 0.2s ease-in-out;
				&:before{
					content: "\f30b";
					display: inline-block;
					@include fontawesome-regular;
					padding-right: 10px;
					color: #CFE018;
				}
			}
			a{
				&:hover{
					.see-more{
						color: #CFE018;

					}
				}
			}
		}
		p, 
		h1, 
		h2, 
		h3{
			z-index: 3;
		}
		h1{
			@include font-bold;
			color: $brand-darkblue;
		}
		h4{
			font-size: 26px;
			a{
				color: #222c37;
				&:hover{
					color: #CBDB2A;
				}
			}
		}

		.content {
			text-align: center;
		}

	}
	.search-section {
		padding-top: 50px;
		padding-bottom: 55px;

		h1{
			@include font-bold;
			color: $brand-darkblue;
		}
		h4{
			font-size: 26px;text-align: center;
			margin-top: 20px;
			margin-bottom: 50px;
			a{
				color: #222c37;
				&:hover{
					color: #CBDB2A;
				}
				&.active{
					border-bottom: 1px solid #fff;
				}
			}
		}

		.content {
			text-align: center;
		}

	}

	.home-tile{
		text-align: center;
		margin: 6em 0;
		h4{
			font-size: 26px;
		}
		a{
			color: #fff;
			@include font-bold;
			i{
				color: #CBDB2A;
				margin-right: 5px;
			}
			&:hover{
				color: #CBDB2A;
			}
		}
	}
}


#keyword-search{
	display: none;
}

.search-options-container{

	.form-group{
		overflow: visible;
	}

	.input-group {
		.form-control{
			height: 100%;
		}
		.input-group-btn {
		    font-size: 1.2em;
		    font-size: 1em;
		    position: absolute;
		    top: 0;
		    right: 0;
		    width: auto;
		    height: 60px;
		    z-index: 9;
		    button{
		    	border: 0;
			    background: $brand-darkblue;
			    border-radius: 0 30px 30px 0;
			    background-image: none;
			    width: 100%;
			    height: 100%;
			    border: 0;
			    transition: background 0.2s ease-in-out, color;
			    font-size: 14px;
			    color: #fff;
			    padding-right: 30px;
			    padding-left: 30px;
			    font-style: italic;
			    i{
			    	margin-right: 5px;
			    }
			    &:hover{
			    	background: $brand-lightgreen;
			    }
		    }
		}
	}

	.custom-select{ 

		border-radius: 30px;
    	padding: 0;
    	background-color: #fff;

    	.select-items div, 
    	.select-selected{
    		font-weight: 400;
    		font-style: italic;
    		font-size: 18px;
    		padding: 17px 30px;
    		color: #9c9c9c;
    	}
    	.select-selected{
    		&:after{
    			content:"";
    			font-weight: 700;
    			color: $brand-color;
    			top: 15px;
    		}
    	}
    	.select-items{
    	    overflow-y: auto;
    		padding: 0;
    		margin-top: 10px;
    		border-radius: 30px;
    		background-color: #fff;
    		color: #212b36;

    		div{
    			padding: 15px 30px;
    			&:hover{
    				background-color: $brand-darkblue;
    			}
    		}
    	}

	}

	.input-group{
		width: 100%;
		.form-control {
		    background-color: #fff;
		    border-radius: 30px;
		    font-size: 18px;
			padding: 15px 30px;
			height: 60px;
		}
	}
	.form-section{
		
		input{
			margin-bottom: 20px;	
			&::placeholder{
		    	font-style: italic;
		    }
		}
	}



	.half-col-inputs{
    	overflow: visible;
		.form-group {
		    &:nth-child(even){
		    	padding-right: 0;
		    }

		}

	}

	.filter-btn{
		.btn.btn-dark-solid{
			padding: 15px 50px;
			border-radius: 30px;
		}
		.btn.btn-dark-solid.customized{
			padding: 15px 50px;
			border-radius: 30px;
			background-color: #aeefff;
			color:#333 !important;
		}
		.btn.btn-dark-solid.customized:hover{
			padding: 15px 50px;
			border-radius: 30px;
			background-color:#cfe018;
		}
		.btn.btn-dark-solid.customized-green:hover{
			padding: 15px 50px;
			border-radius: 30px;
			background-color:#cfe018;
		}
	}

}


.search-results-container{
	background-color: #fff;
	padding: 20px 15px 0 20px;

	& > h4{
		padding-left: 30px;
    	padding-right: 30px;
	}

	h4{
		margin-bottom: 0;
		font-weight: 200;
	}

	.search-results-list{
		margin-bottom: 100px;
		height: 700px;
	    max-height: 100%;
	  	position: relative;

		h2,p{
			color: #212b36;
		}
		
		h4{
		  	font-weight: 200;
		}

		.search-results-content{
			margin: 0;
		    padding: 15px 30px;
		    height: 100%;
		    overflow-y: scroll;
		    z-index: 1;


		    .panel-default{
		    	border-top: 2px solid #ededed;
			    border-radius: 0;
			    margin-top: 0;
			    padding: 20px 0;
		    	& > .panel-heading{
	    			border-bottom: 0;
	    			padding: 0;
	    			& + .panel-collapse{
	    				& >.panel-body{
	    					padding-top: 10px;
	    					padding-left: 30px;
	    				}
	    			}
	    			.panel-title{
	    				a{
	    					padding-left: 30px;
	    					&.collapsed{
	    						&::before{
	    							color: $brand-color;
	    						}
	    					}
	    					&::before{
								content: "";
								@include fontawesome-solid;
								position: absolute;
								left: 0;
								color: $brand-lightgreen;
								transition: color .2s cubic-bezier(.455,.03,.515,.955);
				    		}
				    		&:hover{
				    			&::before{
									color: $brand-lightgreen;
								}
				    		}
	    				}
	    			}

		    	}
		    	
		    }
			.more{

				color: $brand-color;
				display: inline-flex;
				vertical-align: text-bottom;
				line-height: 1.6rem;
				cursor: pointer;
				position: relative;
				padding: 12px 0;

				svg{
					display: none;
				}
				
				&:after{
					display: none;
					@include fontawesome-light;
					content:'\f178';

					font-size: 1.8rem;
					line-height: 0.8em;
					transform:translateX( 0);
					right: -7px;
					padding-left: 8px;
					display: inline-block;
					transition:transform 0.15s ease-out;
				}
				&:hover, 
				&:focus{
					color: $brand-lightgreen;
					&:after{
						transform:translateX(7px );
					}
				} 
				& + a{
					margin-left: 15px;
				}
			}
		}

		&::before {
		    background: linear-gradient(0deg, rgba(255,255,255,0.00) 0%, #FFFFFF 80%);
		    background-size: 100% 100%;
		    height: 50px;
		    z-index: 2;
		    pointer-events: none;
		    content: '';
		    display: block;
		    position: absolute;
		    left: 0;
		    top: 0;
		    width: 100%;
		}
		&::after{
		    background: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 80%);
		    background-size: 100% 100%;
		    height: 50px;
		    z-index: 2;
		    pointer-events: none;
		    content: '';
		    display: block;
		    position: absolute;
		    left: 0;
		    bottom: 0;
		    width: 100%;
		}
	}
}


#search-results-list{
	.panel-default{
		background-color: transparent;
	}
}

.physio-card{
	a{
		h4{
			color:#333;
			transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
		&:hover{
			h4{
				color:#CBDB2A;
			}
		}
	}
	h4{
		@include font-regular;
		font-size: 1.5em;
		margin-bottom: 10px;
		position: relative;
		&:before{
			content:'\f3c5';
			@include fontawesome-light;
			color: $brand-color;
			position: absolute;
			left: -25px;

		}
	}
	ul{
		list-style: none;
		padding-left: 0;
		li{
			padding: 6px 0;
			position: relative;
			&:before{
				position: absolute;
				left: -25px;
				color: $brand-color;
				@include fontawesome-light;

			}
			&.address:before{
				content:'\f5a0';

			}
			&.phone:before{
				content:'\f095';

			}
			&.more:before{
				content:'\f061';
			}
		}
	}
}




#your-address,
#address-search{
	display: none;

	&.active{
		display: block;
		max-height: 425px;

	}
}



@media(min-width: 767px){

	.physio-members {

	   display: block !important;
	}
}

@media(min-width: 991px){


	.public-website{
		.header-wrap{
			.navbar-brand{
				height: 100px;
				overflow: hidden;
				img{

				}
			}
			.main-nav{
				margin-top: 30px;
				padding-bottom: 30px;

			}
		}
		.header-wrap.shrink{
			.navbar-brand{
				margin-top: 12px;
				height: 66px;
			}
			.main-nav{

			}
		}
	}

}




@media(max-width:1200px) {
	.public-website{

		.dont-say-oh {
			background-position: center top;
			&:before{
				    background-size: 500px;
    				margin-top: 3em;
			}

			.home-tile{
				h4 {
					font-size: 20px;
					line-height: 1.2em;	
				}

			}
		}
	}

}


@media(max-width:991px) {
	.public-website{
		.dont-say-oh {
			.home-tile{
				h4 {
					font-size: 20px;
					line-height: 1.2em;	
				}

			}

		}
	}

}


@media(max-width:767px) {
	.public-website {

		.search-section{
		    padding-top: 15px;
		    padding-bottom: 15px;
		}

		.search-options-container{
			margin: 30px 0 15px;
		}

		.home-tile{
			text-align: center;
			margin: 2em 0;
		}
		
		.dont-say-oh {
			padding-top: 15em;

			&:before{
				background-size: 330px;
				margin-top: 0;
			}

			h4 {
				font-size: 25px;
				line-height: 1.4em;
			}
			.d-flex{
				display: block;
			}
			.external-links{
				& + .external-links{
					padding-top: 15px;
				} 
			}
			.external-links{
				padding-top: 100px;
				.see-more{
					font-size: 1.2em;
				}
				h2{
					font-size: 1.1em;
				}
			}
		}
	}


	ul{
		&.ticklist{
			li{
	    		width: 100%;
			}
		}
	}

	.physio-members{
	display: none;

		.fa-li {
			display: none;
		}


		& > li{
			padding: 20px 0;
			border-top: 1px rgba(170,165,158,0.3) solid;
	    	margin-top: 20px;
	    	display: flex;
	    	align-items: center;

	    	&:nth-last-child(1){
	    		border-bottom: 0;
	    		padding-bottom: 20px;
	    	}
			ul{
				padding-left: 0;
				li{
					display: inline-block;
					width: 100%;
					&:after{
						display: none;
					}
				}
			}
		}
	
	}


.physio-member-heading{
	h2{
		margin: 0;
		display: flex;
		align-items: center;
	}
	&.open{
			h2{
				&:after{
					padding-top: 10px;
					content: "";
				}
			}
		}
	}

}


@media(max-width: 580px){

	.public-website {
		.dont-say-oh{
			.form {
				.form-group{
					max-width: 100%;
				}
			}
			.d-flex{
				display: block;
			}
			.external-links{
				+ .external-links{
					padding-top: 15px;
				}

			}
		} 
		.search-section {
			.form-group {
				max-width: 100%;
			}
		}
		.search-options-container{
			 .input-group {
			 	.input-group-btn{
			 		button{
			 			padding-right: 15px;
   	 					padding-left: 15px;
			 		
				 		span{
				 			display: none;

				 		}
			 		}
			 	}
			 }
		}
	}
}
