.to-edge{
	position: relative;
	background-color: #f5f7f6;
	&:nth-of-type(2n){
		background-color: #fff;
	}
	.tint-bg + &{

		background:#fff;
		&:nth-of-type(2n){
			background-color: #f5f7f6;
		}
	}
	.container{
		display: flex;
		align-items:center;
	}
	.to-edge-image{
		position: absolute;
		top: 0;
		&.right{
			right: 0;

		}
		&.left{
			left: 0;
		}
	}
	.img-container{
		height: 100%;
		padding-bottom: 0;
		position: static;
	}
	.text{
		padding: 100px 60px;
	}
	& + .tint-bg{
		margin-top: 0;
	}
}

@media( max-width: 991px ){
	.to-edge{
		.text{
			padding: 50px 30px;
		}

	}


}

@media( max-width: 767px ){
	.to-edge{
		.to-edge-image{
			position: relative;
			.img-container{
				height: 100%;
				padding-bottom: 56.5%;
				position: relative;

			}
		}
	}
}