.public-website{

	.page-header{
		background-color: $brand-color;
		padding-top:75px;
		padding-bottom: 75px;
		margin: 0;
		border: 0;

		span.subheading{
			color: rgba(33,43,54,0.6);
			font-size: 28px;
			@include font-bold;
			& + h1{
				margin-top: 0;
			}
		}
		&:not(.search-banner){
			h1{
				margin-bottom: 25px;
				text-align: left;
				@include font-bold;
				font-size: 2.8em;
				color: $brand-darkblue;
			}
		}

		p{
			text-align: left;
			color: #212B36;
			font-size: 1.5em;
			@include font-light;
		}
	}

}