/////
//search results
/////


#search-modal{
	margin-bottom: 2em;
	margin-top: 1em;
	.modal-header{
		border-bottom: 0;
	}
	.modal-content{
		background-color: transparent;
	}
	.input-group-addon {
		border: none;

	}
	.form-control{
		padding: 0 0 0 10px;
		background-color: transparent;
		padding: 30px 8px;
		border:0;
		border-radius: 0;
		border-bottom:1px $brand-darkblue solid;
		font-size: 2.4em;
		@include font-light;
		&:focus{
			box-shadow: none;
			outline: 0;
		}
	}
	form{
		margin-top: 2em;
		.input-group-addon{
			background: transparent;
			button{
				width: 100%;
				height: 100%;
				border:0;
				font-size: 2em;
				background: transparent;
				color: $brand-darkblue;
				transition:color 0.2s ease-in;
				&:hover{
					color: $brand-lightblue;
				}
			}
		}
	}
	
}
