.custom-checkbox, 
.custom-radio{
	margin-bottom: 20px;

	&:last-of-type{
		margin-bottom: 0;
	}

	[type="radio"]:checked,
	[type="radio"]:not(:checked),
	[type="checkbox"]:checked,
	[type="checkbox"]:not(:checked) {

		position: absolute;
		left: -9999px;
		& + label{
			position: relative;
			padding-left: 40px;
			cursor: pointer;
			display: inline-block;
			line-height: 19px;
			  padding-top: 6px;
			@include font-bold();
			&:before{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 30px;
				height: 30px;
				border-radius: 100%;
				background: #f6f6f6;
			}
			&:after{
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
				position: absolute;
				line-height: 30px;
			}
		}
	}


	[type="radio"]:not(:checked),
	[type="checkbox"]:not(:checked){

		& + label{
			&:after {
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0);
			}
		}
	}

	[type="radio"]:checked,	
	[type="checkbox"]:checked {

		& + label{
			&:after {
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
			}
		}
	}


	[type="radio"]:checked,
	[type="radio"]:not(:checked){
		& + label{
			&:after{
				content: '';
				width: 20px;
				height: 20px;
				background: $brand-darkblue;
				top: 5px;
				left: 5px;
				border-radius: 100%;

			}
		}
	}

	[type="checkbox"]:checked,
	[type="checkbox"]:not(:checked) {
		& + label{
			&:before{
				border-radius: 0;
				background: #f6f6f6;

			}
			&:after{
				content: '';
				@include fontawesome-regular();
				font-size: 23px;
				color: $brand-darkblue;
				top: -1px;
				left: 4px;
				border-radius: 3px;

			}
		}
	}
}

