#home-carousel{
	height: 600px;
	position: relative;
	h1{
		margin-top: 0;
		color: #fff;
		@include font-bold;
	}
	.carousel-inner{
		height: 100%;

		.item{
			height: 100%;
			&:before{
				content:'';
				height: 100%;
				width:33%;
				opacity: 0.82;
				background-image: linear-gradient(270deg, rgba(0,76,93,0.00) 0%, rgba(0,141,173,1) 100%, #00BCE7 100%);
				display: block;
				position: absolute;
				z-index: 2;
			}
			
			img{
				height: 100%;
				max-width: none;
				position: absolute;
				transform:translate(-50%, -50%);
				left: 50%;
				top: 50%;
			}
		}
		.control-container{
			position: absolute;
			width: 100%;
			top: 30%;
			.container{

			}
			.carousel-control{
				background: transparent;
				background-image: none;
				width: 40px;
				height: 40px;
				opacity: 1;
				text-shadow: none;
				border:1px solid #637381;
				border-radius:50%;
				color: #fff;
				transition:background 0.2s ease-in-out, border-color 0.2s ease-in-out;
				position: relative;
				display: inline-block;
				span{
					position: absolute;
					top: 50%;
					left: 50%;
					transform:translate(-50%, -50%);
				}
				&:hover{
					background: #fff;
					color: $brand-color;
					border-color:#fff;
				}
				&.right{
					left: 50px;
				}
			}
		}
	}

	.text-container{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: auto;
		padding-bottom: 75px;
		z-index: 3;
		&::before{
			content: '';
			position: absolute;
			display: block;
			height: 200%;
			width: 100%;
			z-index: 0;
			bottom: 0;
			background: rgb(60,60,60);
			background: linear-gradient(-180deg, rgba(104,134,146,0.00) 30%, rgba(55,78,89,0.60) 91%);

		}
		.btn-default{
			background-color: #fff;
			color: $brand-color;
			margin-top:20px;
			&:hover,
			&:focus{
				background: $brand-darkblue;
				color: #fff;
			}
		}
	}

}

@media(min-width: 1850px){
	#home-carousel{
		.carousel-inner .item img{
			width: 100%;
			height: auto;

		}
	}
}


@media(max-width: 991px){
	#home-carousel{

		height: 400px;
		h1{
			font-size: 2.4em;
		}

	}
}



@media(max-width: 768px){
	#home-carousel{
		height: 400px;
		.carousel-inner{
			.control-container{
				top: 15px;
			}
			.text-container{
				padding-bottom: 20px;
				
				h1{
					color: $brand-color;
					width: 85%;
				}
				&::before{
					height: 200%;
				}
			}

			.item{
				&:before{
					content: '';
					height: 100%;
					width: 100%;
					opacity: 0.85;
					background-image: linear-gradient(-180deg, rgba(254, 254, 254, 0) 30%, #212B36 70%);
					display: block;
					position: absolute;
					z-index: 2;
				}
			}
		}

	}


}

@media(max-width: 520px){
	#home-carousel{
		.carousel-inner{

			.item{

				img{
					height: 100%;
					width: auto;
					max-width: none;
				}
			}
		}
	}
}


.quote-carousel{
	position: relative;
	.carousel-inner{
		padding-top: 20px;
		padding-bottom: 50px;
		blockquote{
			padding: 60px 0;
		}
		h6{
			position: absolute;
			top: 0px;
			left: 50%;
			transform:translateX(-50%);
		}
		.item{
			padding-top: 25px;
		}
	}
	.carousel-control{
		text-shadow:none;
		
		font-size: 30px;
		font-weight: bold;
		height: 30px;
		width: 30px;
		bottom:80px;
		top: initial;



		&.right,
		&.left{
			background-image: none;
			color: $brand-color;
			border:1px solid $brand-darkblue;
			border-radius: 50%;
			font-size: 1em;
			display: flex;
			align-items:center;
			justify-content:center;
			transition:background-color 0.2s ease-in-out;
			&:hover,
			&:focus{
				border:$brand-color;
				color: #fff;
				background:$brand-color;
			}
		}

		&.left{
			left: 46%;
			transform:translateX(-54%);
		}
		&.right{
			left:54%;
			transform:translateX(-54%);
		}


	}
}


main{
	.carousel-inner{
		.item{
			height: 0;
			padding-bottom: 56.5%;
			img{
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform:translate(-50%, -50%);
			}
		}
		.carousel-control{
			.far{
				position: absolute;
				top: 50%;
				transform:tranlsateY(-50%);
			}
		}
	}
}


.carousel-fade{

	.carousel-inner{
		.item {
			transition-property: opacity;
		}
		.item,
		.active.left,
		.active.right {
			opacity: 0;
		}
		.active,
		.next.left,
		.prev.right {
			opacity: 1;
		}
		.next,
		.prev,
		.active.left,
		.active.right {
			left: 0;
			transform: translate3d(0, 0, 0);
		}
	}

}
