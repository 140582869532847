.login{
	padding-top: 0;
	padding-bottom: 0;
	.form-group{
		max-width: 100%;
	}
	.remember-me-checkbox{
		float: right;
			label{
		color: #ffffff;
	}
	}
}

.form-page{
	background-image: url("./../images/homepage-banner-1.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	background-position: center center;
	min-height: 100vh;


	a{
		color: #ffffff;
		&:hover{
			color: $brand-darkblue;
		}
		&.form-link{
			display: block;
			float: right;
			width: 50%;
			font-weight: 600;
			span{
				font-style: italic;
				font-weight: 400;
			}

		}
	}
	.tint-bg{
		background-color: rgba($brand-color, 0.9);
		margin-top: 0;
		padding: 30px 50px;
		min-height: 100vh;
		position: relative;
	}
	.form-header{
		a{
			display: block;
			float: right;
			@include font-bold;
		}
	}
	.form{
		padding: 60px 0;

		legend{
			color: $brand-darkblue;
			padding: 20px 0;
			font-size:3em;
			@include font-bold;
			border-bottom: 0;
		}
		.form-group{
			position: relative;
		    margin-bottom: 25px;
			&.col-md-6{
				padding:0 15px 0 0;
				float: left;
				&:nth-of-type(2n){
					padding:0 0 0 15px;
				}
			}
			&:last-of-type{
				margin-bottom: 60px;
			}
			label{
				color: $brand-darkblue;
				text-transform: uppercase;
				@include font-bold;
				font-size: 12px;
			}
			.form-control{
	    border: 0;
    background-color: #fff;
    border-radius: 25px;
     	padding: 6px 20px;
    @include placeholder-colors(#eee)

			}
			.view-toggle{
				color: #5A7382;
				position: absolute;
				width: 30px;
				height: 30px;
				z-index: 6;
				right: 0;
				top: 32px;
				cursor: pointer;
				opacity: 0.7;
				transition: opacity 0.1s ease-in;

				&:before{
					line-height: 34px;
				}
				&:hover{
					opacity: 1;
				}
			}
		}
		.btn{
			float: left;
			margin-right: 30px;
		}
	}
	.form-footer{
		font-weight: 600;
		a{
			display: block;
			span{
				font-style: italic;
				font-weight: 400;
			}
		}
	}
}
@media(max-width:991px){
	.form-page{
		height: auto;
		// overflow: scroll;
		.tint-bg{
			height: auto;
		}

		.form {
			.form-group{
				&.col-md-6{

					padding: 0;
					&:nth-of-type(2n) {
						padding: 0;
					}
				}
			}
		}
	}
}
@media(max-width:768px){

	.form-page .form .form-group .view-toggle {
		top:27px;
	}
}

@media(max-width: 580px){

	.form-page{
		.form{
			padding: 35px 0;
			.form-group {
				&:last-of-type{
					margin-bottom: 35px;
				}
			}
		}
		.tint-bg{
			padding: 30px 25px;
		}
		.form-header{
			a{
				float: none;
				margin-top: 25px;
			}
		}
		a.form-link{
			float: none;
			padding: 50px 0 0;
			clear: both;
			width: 100%;
		}
		.form-footer{
			a{
				width: 100%;
				margin-bottom: 35px;
			}
		}
	}
}

@media(max-height: 800px){
	.form-page{
		height: auto;
		// overflow: scroll;
		.ting-bg{
			height: auto;
		}
	}
}
