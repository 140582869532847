.card{
	margin-bottom: 50px;
	padding: 0 30px;
	.img-container{

		margin-bottom: 20px;
		padding-bottom: 0;
		height:auto;
		min-height: 75px;
		img{
			position: relative;
			width: auto;
			height: auto;
			transform:none;
			min-width: 0;
			
			left: 0;
			top: 0
		}
	}
	h2{
		font-size: 1.5em;
		transition:color 0.2s ease-in-out;
		line-height: 1.4em;
		margin: 10px 0 15px;
	}
	p{
		margin-bottom: 20px;
	}
	a{ 
		font-weight: bold;
		&:hover{
			h2{
				color: $brand-color;
			}

		}
	}

	.category{
		margin-bottom: 5px;
	}
	small{
		color: $brand-color;
		
		& + .card-title, 
		& + .card-title h2{
			margin-top: 5px;
		} 
	}
	.img-container{
		margin-bottom: 15px;
		& + p{
			margin-bottom: 0;
			& + h3{
				margin-top: 5px;
			}
		}
	}

	.card-tags{
		padding-left: 0;
		list-style: none;
		li{
			display: inline-block;
			color: #637381;
			margin-right: 10px;

			&.emphasis{
				color: #FA5E3F;
			}
			&.early-bird{
				color: #06D6A0;
			}
		}

	}


	&.card-news{
		padding: 0;
		small{
			color: #AAA59E;
			text-transform: uppercase;
			font-size: 70%;
		}
		h2{
			font-size: 20px;
		}
		a{
			color: $brand-color;
			&:hover{
				color: $brand-darkblue;
			}

		}
		
		.img-container{
			padding-bottom: 100%;
			margin-bottom: 0;
			min-height: 0;
			height: 0;
			img{
				max-width: 100%;
				object-fit: cover;
				object-position: 50% 50%;
				position: absolute;
				left: 50%;
				top: 50%;
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				min-width: 100%;
				min-height: 100%;
				height: 100%;

			}
		}
		small{
			color: #AAA59E;

			& + .card-title, 
			& + .card-title h2{
				margin-top: 5px;
			} 

		}
		.card-block{
			background: #fff;
			padding: 15px 15px 40px;
			margin-bottom: 40px;
		}
	}
	.date{
		color: $brand-lightblue;
		@include font-bold();
	}

	&.card-resources{
		padding: 0;
		h2{
			color: $brand-color;
			font-size: 18px;
			margin-bottom: 15px;
		}
		img {
			width: 100%;
		}
		.img-container{
			padding-bottom: 100%;
			margin-bottom: 0;
			min-height: 0;
			height: 0;
			img{
				position: absolute;

			}
		}
		small{
			color: #AAA59E;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 70%;
			& + .card-title, 
			& + .card-title h2{
				margin-top: 5px;
			} 
		}
		.category{
			margin-bottom: 20px;
		}
		.card-block{
			min-height: 250px;
			background: #fff;
			padding: 15px 15px 40px;
			margin-bottom: 40px;
		}
		.information{
			color: #AAA59E;
			font-size: 14px;
			padding: 0;
			list-style: none;
			li{

			}
		}
	}
	&.card-magazine{
		padding: 0;
		h2{
			color: $brand-color;
			font-size: 1.4em;
			margin-bottom: 15px;
		}
		.img-container{
			padding-bottom: 100%;
			margin-bottom: 0;
			min-height: 0;
			height: 0;
			img{
				position: absolute;

			}
		}
		img {
			width: 100%;
		}
		small{
			color: #AAA59E;
			font-weight: bold;
			& + .card-title, 
			& + .card-title h2{
				margin-top: 5px;
			} 
		}
		.category{
			margin-bottom: 20px;
		}
		.card-block{
			background: #fff;
			padding: 15px 15px 40px;
			margin-bottom: 40px;
		}
		.information{
			color: #AAA59E;
			font-size: 14px;
			padding: 0;
			list-style: none;
			li{

			}
		}
	}
	&.card-jobs{
		.img-container{
			margin-bottom: 25px;
			min-height: auto;
			height: 55px;
			img{
				height: 100%;
			}
		}
		a{ 
			font-weight: bold;
			color: $brand-darkblue;
			&:hover{
				color: $brand-color;

			}
		}
	}
	&.card-events {
		a{ 
			font-style: normal;
			font-weight: 400;
			font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
			color: $brand-darkblue;
			&:hover{
				color: $brand-color;

			}
		}
	}
}

