.panel-group{
	margin-bottom: 40px;
}
.panel-default{
	position: relative;
	border:0;
	box-shadow: none;
	& > .panel-heading{
		position: relative;
		border:0;
		background:none;
		overflow: hidden;
		width: 100%;
    padding: 20px 0 25px;
		border-bottom: 1px #ededed solid;
		.panel-title{
			color: $brand-darkblue;
			font-size: 20px;

			a{
				display: block;
				overflow: hidden;
				width:100%;
				&:hover,
				&:focus{
					[class^="fa"]{
						color: $link-highlight;
					}
				}
			}
			[class^="fa"]{
				position: absolute;
				right: 0;
    top: 25px;
    font-size: 20px;
				color: $brand-darkblue;
				transition:color 0.2s ease-in-out;
			}

		}
		& + .panel-collapse{
			& > .panel-body{
				padding-top: 25px;
				border-top: 0;
			}
		}
	}
}

.panel{
	box-shadow: 0;
	.panel-body {
    padding: 0;
}

&:nth-last-child(1){
	& > .panel-heading{
		border-bottom: 0;
	}
}
}

.img-accordion{
	overflow: hidden;
	width: 100%;
	padding-top: 20px;

	.images{

		position: relative;
		.img-container{
			position: absolute;
			opacity: 0;
			top:0;
			left: 0;
			width: 100%;
			transition:opacity 0.15s ease-in-out;
			&.active{
				opacity: 1;
			}
		}
	}
}

@media(max-width: 991px){
	
	.img-accordion{
		.images{
			overflow: hidden;
			height: 400px;
			margin-bottom: 30px;
			.img-container{
				height: 100%;
			}
		}
	}

}

@media(max-width: 580px){
	.img-accordion{
		.images{
			height: 250px;

		}
	}
}