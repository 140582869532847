
//- summary panel

.summary-panel {
	margin-bottom: 0;
	.list-contacts, dl {
		margin-top: 2em;
	}
}

.presenters {
	.media-list {
		.media-object {
			max-width: 180px;
			float: right;
			margin-left: 2em;
		}

		.list-inline li {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

@media screen and (max-width: 480px){

	.presenters {
		.media-list {
			.media-object {
				max-width:100%;
				float: none;
				margin-bottom: 2em;
				margin-left: 0;
			}
		}
	}
}