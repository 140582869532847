
.custom-select {
  position: relative;

  float: left;
  margin-right: 20px;
  padding-right: 20px;
  width: 100%;
  border-bottom:1px solid #fff;
  padding: 6px 0;
  select {
    display: none !important; /*hide original SELECT element:*/

  }
}


.select-selected {
  background-color: transparent;
  color:#fff;
  /*style the arrow inside the select element:*/
  &::after {
    position: absolute;
    content: "\f078";
    top: 8px;
    right: 25px;
    width: 0;
    height: 0;
    @include fontawesome-light;
  }
  /*point the arrow upwards when the select box is open (active):*/
  &.select-arrow-active::after {
    content:'\f077';
    top: 7px;
  }

}
/*style the items (options), including the selected item:*/
.select-selected {
font-size: 24px;
  color: $brand-darkblue;
  padding: 8px 15px;
  cursor: pointer;
  @include font-bold;
}

.select-items div{
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  @include font-bold;
}
/*style items (options):*/

.select-arrow-active{
  & +{
    .select-items{
      div{
        opacity: 1;
      }
    }
  }
}

.select-items{
  padding: 10px 0;
  position: absolute;
  width: 100%;
  background-color: $brand-darkblue;
  color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  overflow-x: hidden;
  max-height: 300px;
  overflow-y: scroll;
  display: none;
  div{
    opacity: 0;
    transition: opacity 0.2s 0.1s;
    color: #fff;

  }
} 

.select-items div:hover, .same-as-selected {
  background-color: #ffffff;
  color: $brand-darkblue;;
}

