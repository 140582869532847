
html{
	min-height: 100%;
}

body{
	font-size: 16px;
	@include font-regular;
	-webkit-font-smoothing:antialiased;
	overflow-x: hidden;
  overflow-y: scroll;
}

p{
	@include font-regular;
	margin-bottom: 1em;
	line-height: 1.5em;
	color: $brand-lightblue;

}
img{
	max-width: 100%
}
h1{
	line-height: 1.3em;
	color: $brand-color;
	@include font-bold;
} 
h2, h3{
	line-height: 1.4em;
	color: #212B36;
	@include font-bold;
}
h3{
	margin-bottom: 1em;
}
h4, h5, h6{
	@include font-bold;
}

main{
	h1,h2,h3,h4,h5,h6{
		margin-top: 20px;
	}
}
h1{
	font-size: 3em;
}
h2{
	margin-bottom: 1em;
}

strong{
	@include font-bold;
	color: $brand-color;
}

main{
	padding-top:50px;
    padding-bottom: 50px;

    & >  .tint-bg:first-of-type,& >  .card-section:first-of-type{
    	margin-top:-50px;
    	margin-bottom: -50px;
    }
}

a{
	color: $link-color;
	transition: color .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

	&:hover,
	&:focus{
		color: $link-highlight;
		text-decoration: none;
	}

	&.linkedin:hover,
	&.linkedin:focus{
		color: $linkedin-color;
	}
	&.twitter:hover,
	&.twitter:focus{
		color: $twitter-color;
	}
	&.see-more{
		@include font-bold;
		color: $brand-color;
	}

}


li{
		color: $brand-lightblue;
	}

td{
		color: $brand-lightblue;
}

h4{
	margin-bottom: 1.4em;
}

hr{
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

blockquote{
	border-left: 0 none transparent;
	padding: 40px 0 25px;
	margin-bottom: 0;
	position: relative;
	overflow: hidden;
	@include font-bold;
	font-size: 1.8em;
	line-height: 1.2em;
	text-align: center;

	p{
		font-weight: 500;
		font-size: 1em;
		line-height: 1.4em;
		margin-bottom: 30px;
		color: $brand-color;
	}

}

@media(max-width: 767px){
	h2{
		margin-bottom: 25px;
	}

	blockquote{

		max-width: 100%;
		font-size: 1.4em;
		line-height: 1.3em;

	}
}