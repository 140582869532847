.branch-intro{
	padding-top:60px;
}
.key-date-tile{
	margin-bottom: 50px;
	.key-date-date{
		width: 65px;
		height: 65px;
		border-radius: 50%;
		color: $brand-darkblue;
		background: #f5f7f6;
		text-align: center;
		display: flex;
		align-items:center;
		justify-content:center;
		
		p{
			@include font-bold;
			margin-bottom: 0;
			line-height: 1.2em;
		}

	} 
	.key-date-content{

	}
}
@media(max-width: 767px){
	.branch-intro{
		padding-top: 30px;
		img{
			max-height: 200px;
			margin-bottom: 30px;
		}
	}
}
.branch-info{
    display: flex;
    height: 100%;
.member-count{
	.number{
		border:1px solid #fff;
		color: #fff;
	}
	h3{
		color: #fff;
	}
	
}
}
.member-count{
	overflow: hidden;
	.number{
		height: 55px;
		width: 55px;
		border:1px solid $brand-darkblue;
		border-radius: 50%;
		float: left;
		text-align: center;
		padding-top: 10px;
		margin-right: 20px;
		color: $brand-darkblue;
		font-size: 1.4em;
		@include font-bold;
	}
	h3{
		float: left;
		margin-top: 10px;
	}
}

.branch-list{
	h3{
		text-align: center;
		font-size: 1.6em;
		margin-bottom: 2em;
	}
	ul{
		list-style: none;
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		li{
			display: inline-block;
			position: relative;
			font-size: 1.3em;
			padding: 0 10px;

			a{
				color: $brand-darkblue;
				@include font-regular;
				&:hover{
					color: $brand-lightblue;
				}
			}
			& + li{
				margin-bottom: 15px;
			}

		}
	}
	.no-flexbox &{
		ul{
			list-style: none;
			padding-left: 0;

			a{
				color: $brand-darkblue;
				&:hover{
					color: $brand-lightblue;
				}
			}
			& + li{
				padding-left: 20px;
				margin-left: 15px;
				margin-bottom: 15px;
				&::before{
					content:'●';
					position: absolute;
					left: 0;
				}
			}
		}
	}
}