.cta{
	text-align: center;
	h1{
		text-align: center;
		margin-bottom: 1em;
	}
	h2{
		margin-bottom: 1em;
		@include font-light;
	}
}

.no-banner {
	height: 10px;
	background:$brand-color;
}

.branch-header{
	background-color: $brand-color;
	background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    padding: 100px 0 50px;
    position: relative;
    min-height: 400px;
    display: flex;
    align-items: flex-end;
	&.light-bg{
		h1{
			color: $brand-darkblue;
		}	
		.countdown {
			div{
				color: $brand-darkblue;
				span{
					color: $brand-darkblue;
					border-color: $brand-darkblue;
				}
			}
		}
		.event-details{
			ul{
				color: $brand-darkblue;
			}
		}
	}
	span.subheading{
		color: #fff;
			font-size: 28px;
			@include font-bold;
			& + h1{
				margin-top: 0;
			}
		}
	h1{
		color: #fff;
		margin-bottom: 20px;
	}

	.event-details{
		ul{
			color: #fff;
			list-style: none;
			padding-left: 0;
			margin-bottom: 20px;
			li{
				color: #ffffff;
			}
		}
		
	}
	&:before{
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.82;
		background-image: linear-gradient(270deg, rgba(0,76,93,0.00) 0%, #00BCE7 100%);
	}
}

.search-banner{
	padding-top: 50px;
	padding-bottom: 60px;
	h1{
		text-align: center;
		font-weight: 200;
	}

	form{
		padding-top: 30px;

		.form-section{
			display: flex;
			justify-content: center;
		}

		.form-group {
			margin-top: 20px;
			max-width: 50%;
			margin-bottom: 40px;
			position: relative;
			width: 33%;
			float: left;
			padding: 0 20px;
			overflow: visible;
			label {
				color: rgba(33, 43, 54, 0.4);
			}
		}
		.form-control {
			background-color: transparent;
			border-bottom: 1px #ffffff solid;
			font-weight: bold;
			color: #212B36;
			font-size: 24px;
			background: $brand-color;
			border-bottom: 1px solid #fff;
			font-size: 26px; 
			margin-bottom: 30px;
			min-height: 52px;
		}
		@include placeholder-colors(#207D98)
	}
	h1{
		text-align: center;
	}
	& + .tint-bg{
		margin-top: -20px;
	}
	.form-group {
		margin-top: 20px;
		max-width: 50%;
		margin-bottom: 40px;
		position: relative;
		width: 33%;
		float: left;
		padding: 0 20px;

		label {
			color: rgba(33, 43, 54, 0.4);
		}

		.form-control {
			background-color: transparent;
			border-bottom: 1px #ffffff solid;
			font-weight: bold;
			color: #212B36;
			font-size: 24px;
		}
	}
}


@media(max-width: 580px){
	.search-banner{

		form{
			.form-section{
				flex-direction:column;
			}
			.form-group {
				width: 100%;
				margin:0 0 30px;
				max-width: 100%;
				.form-control{
					margin-bottom: 0;
				}
			}
		}
	}
}



@media(min-width: 767px){

	.search-banner{
		.form-section{
			display: flex;
			justify-content: center;
		}
	}
}


@media(max-width: 991px){

	.search-banner{
		.form-section{
			.form-group {
				width: 50%;
			}
		}
	}
}



@media(max-width: 767px){

	.search-banner{
		form{
			padding-top: 15px;
			.form-control{
				margin-bottom: 20px;
			}

			.form-section{
				display: inline-block;
				.form-group {
					max-width: 100%;
					width: 100%;
					margin-bottom: 10px;
					margin-top: 10px;
				}
			}
			.btn-default{
				margin-top: 30px;
			}
		}
	}
}


.page-header{
	background-color: $brand-color;
	padding-top:75px;
	padding-bottom: 75px;
	border-bottom: 0;
	margin: 0;
	h1{
		text-align: center;
		@include font-bold;
		font-size: 2.8em;
		color: #fff;
	}
	p{
		text-align: center;
		color: #212B36;
		font-size: 1.5em;
		@include font-light;
	}
	& + .tint-bg,
	& + .blue-bg{
		margin-top: -20px;

	}
}

.content-header{
	position: relative;
	min-height: 600px;
	background-repeat: no-repeat;
	background-color: #00bce7;
	background-size: cover;
	padding: 50px 0 50px;
	position: relative;

	.text-container {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: auto;
		padding-bottom: 50px;
		z-index: 3;
		span.subheading{
			font-size: 28px;
			@include font-bold;
			& + h1{
				margin-top: 0;
			}
		}
		.btn-solid{
			border-radius: 35px;
			padding: 15px 40px;
		}
		h1{
			margin-bottom: 25px;
			text-align: left;
			@include font-bold;
			font-size: 2.8em;
		}
		h1,.subheading{
			color: #fff;
		}
		&:before{
			content: '';
			position: absolute;
			display: block;
			height: 200%;
			width: 100%;
			z-index: 0;
			bottom: 0;
			background-image: linear-gradient(-180deg, rgba(33,43,54,0.00) 20%,  rgba(33,43,54,0.90) 99%);
		}
		.img-container{
			height: 100%;
			img{
				width: 100%;
				position: absolute;
				transform: translate(-50%, -20%);
				left: 50%;
				top: 20%;
			}
		}
	}
}



@media(max-width: 991px){
	.page-header{
		margin-top:0px;
		padding-top:75px;
	}

	.content-header{
		min-height: 400px;
	}
}

@media(max-width: 767px){
	.page-header{
		margin-top:0px;
		padding-top:40px;
		padding-bottom: 40px;
	}
}


.hero{

	.page-header{
		padding-bottom: 50px;
	}
	.img-container{
		width:100%;
	}
	.video{
		margin-bottom: 40px;
		cursor: pointer;
		.overlay{
			z-index: 2;
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 1;
			background: rgba(#4e4e4e, 0.5);
			transition:opacity 0.2s ease-in-out;
		}
		.fa-play{
			z-index: 2;
			position: absolute;
			left: 50%;
			top: 50%;
			color: #fff;
			font-size: 8em;
			transform: translate(-50%,-50%);
			transition:opacity 0.2s ease-in-out;
			opacity: 1;
		}
		&:hover{
			.overlay{
				opacity: 0.5
			}
			.fa-play{
				opacity: 0.5
			}
		}
	}
	&.video{
		.page-header{
			padding-bottom: 70px;
		}
	}
}

@media(max-width: 991px){
	.hero{
		.page-header{
			padding-top: 40px;
		}
	}
}