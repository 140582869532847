.map-selector{
	.fa-ul{
		margin-top: 30px;
		li{
			margin-bottom: 20px;
			font-size: 1.2em;
			&.hover{
				a{
					color: $brand-darkblue;
				}
			}
		}
		.fa-li{
			.far{

				font-size: 1.6em;
			}
		}
	}
	
	a{
		color: $brand-darkblue;
		&:hover,
		&:focus{
			color: $brand-lightblue;
		}

	}
}

$branches : ( "southland" , "otago", "canterbury", "marlborough-nelson", "wellington", "hawkes-bay", "middle-districts", "waikato", "auckland", "north-shore", "northland");

@each $val in $branches{
	.#{$branches}{
		##{$branches}{
			path,
			polygon{
				fill:$brand-lightblue;
			}
		}
	}
}


.map-container{
	.region{
		path,
		polygon{
			cursor: pointer;
			transition: fill 0.1s ease-in-out;
		}
		&.hover,
		&:hover,
		&:focus{
			path,
			polygon{
				fill:$brand-lightblue;
			}
		}
	}

}
