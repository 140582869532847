//- Change at your peril
.nav-trigger {
	display: none;
}

.header-wrap{
	position: fixed;
	width: 100%;
	z-index: 99;
	top: 0;
	left: 0;
}

.main-nav.navbar.navbar-default {

	border: 0 none transparent;
	margin-bottom: 0;
	.navbar-nav {
		margin-bottom: 0;

		li {
			&>.active>a,
			&>.active>a:hover,
			&>.active>a:focus {
				background: transparent;
			}
		}
		.dropdown {
			position: relative;
			.close {
				display: none;
				position: absolute;
				opacity: 1;
				text-shadow: none;
				&:hover{
					color: $brand-color;
				}
			}
			&.open {
				.dropdown-menu {
					transform: translateX(0%);
				}

				&>a,
				& a:hover,
				& a:focus {
					background-color: transparent;
				}
			}
			.dropdown-menu {


				& > li {
					
					&>a {
						padding: 10px 25px;
						font-size: 21px;
						white-space: nowrap;
						justify-content: left!important;
						&:focus,
						&:hover {

							text-decoration: none;
							background-color: transparent;
						}
					}
					&:hover {
						background-color: transparent;
						color: $brand-color;
					}
				}

			}
		}
	}
}


@media (max-width: 991px) {

	.navbar-brand {
		overflow: hidden;
		display: block;
		float: none;
		padding: 0;

	}

	.page-wrapper {
		position: relative;
		z-index: 2;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);

	}
	.main-nav.navbar.navbar-default {

		.navbar-nav {
			li {
				float: none;
				&>a:hover,
				&>a:focus,
				&>a:hover:before,
				&>a:focus:before {
					color: $brand-lightblue;
				}
			}
		}
		.dropdown-menu {
			position: fixed;
			top: 0;
			transform: translateX(-120%);
			width: 90%;
			height: 100vh;
			transition: all 0.5s cubic-bezier(0.82, 0.01, 0.77, 0.78);
			text-align: left;
			z-index: 99;
			display: block;
			border: 0;
			overflow: auto;
			right: initial;

			&>li {
				&>a {
					white-space: normal;
				}
			}
			&:before {
				content: '';
				display: block;
				position: absolute;

			}
			.close {
				display: block;
			}
		}
	}

	.navigation-is-open {
		overflow: hidden;
		.page-wrapper {
			transform: translateX(100%);
		}
		.main-nav-trigger {
			transform: rotate(180deg);
		}

		.main-nav {
			visibility: visible;
			.main-navigation-wrapper {
				transform: translateX(0);
				transition: transform 0.5s cubic-bezier(0.82, 0.01, 0.77, 0.78);
				width: 100%;
			}
		}
	}

	.main-nav {
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		padding: 0;

		.main-navigation-wrapper {
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		}

	}

	.main-nav-trigger {
		position: absolute;
		z-index: 11;
		display: block;
		overflow: hidden;
		text-indent: 100%;

		.main-nav-icon {

			position: absolute;
			@include center;
			background-color: #ffffff;

			&::before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background-color: inherit;
				transform: translateZ(0);
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				transition: transform 0.3s, width 0.3s, top .3s;
			}
			&::before {
				transform-origin: right top;
				transform: translateY(-10px);
			}
			&::after {
				transform-origin: right bottom;
				transform: translateY(10px);
			}
		}
		.no-touch &:hover .main-nav-icon::after {
			top: 2px;
		}
		.no-touch &:hover .main-nav-icon::before {
			top: -2px;
		}


		.navigation-is-open & {
			// rotate trigger when navigation becomes visible 
			transform: rotate(180deg);

			.main-nav-icon::after,
			.main-nav-icon::before {
				// animate arrow --> from hamburger to arrow 
				width: 50%;
				transition: transform 0.5s;
				transition: width 0.5s;
			}
			.main-nav-icon::before {
				transform: rotate(45deg);
				top: 1px;
			}
			.main-nav-icon::after {
				transform: rotate(-45deg);
				top: -1px;
			}
			.no-touch &:hover .main-nav-icon::after,
			.no-touch &:hover .main-nav-icon::before {
				top: 0;
			}
		}
	}

	.header-wrap {
		position: absolute;
		z-index: inherit;
	}

	.navbar.navbar-offcanvas.navbar-default {
		margin-top: 0;
		background: white;
	}

}

@media (min-width: 768px){
	.navbar-nav {
		float: none; 
		margin: inherit; 
	}
}