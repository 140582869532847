
@media (min-width: 992px){
	.five-col{
		width: 20%;
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
		float: left;
	}
}

.card-resources{
	.card-block{
		position: relative;
	}
	.icon{
		position: absolute;
		bottom: 15px;
		left: 15px;
	}
}

.directory-link {
	color: #212B36;
	float: right;
	@include font-bold;
	i{
		color: #CBDB2A;
		margin-right: 5px;
	}
}

