#meganav{

	position: absolute;
	top: 62px;
	background:$brand-lightblue;
	right: 0;
	width: 100%;
	transition-property: height;
	transition-duration: 0.3s ;
	transition-timing-function: ease-in-out;
	min-height: 0;
	border:0;

	&::after,
	&::before{
		display: block;
		height: 100%;
		background: $brand-lightblue;
		content:'';
		position: absolute;
		top: 0;
		width:30px;
	}

	&:after{
		left: -30px;
	}

	&:before{
		right: -30px;
	}

	h1{
		color: #ffffff;
		border-bottom:0;
	}

	h2{
		font-size: 1.4em;
		color: #ffffff;
		border-bottom: 2px solid #fff;
		padding-bottom: 20px;
		margin-bottom: 0;
	}

	h3{
		font-size: 1.2em;
		color: #ffffff;
	}

	p{
		color: #fff;
	}

	a{
		color: #ffffff;
		padding: 6px 0;
		&:hover,
		&:focus{
			background-color: transparent;
			color: $brand-color;
		}

	}
	.navbar-nav{
		& > li{
			float: none;
			padding: 6px 0;
		}
	}

	.arrow{
		position: absolute;
		display: block;
		padding: 3px;
		transform: rotate(-135deg);
		background:$brand-lightblue;
		border: solid $brand-lightblue;
		border-width: 0 10px 10px 0;
		visibility: hidden;
		right: 42px;
		top: 8px;

		transition:top 0.3s ease-in-out, visibility 0.1s ease-in 0.1s;

	}
	&.open{
		.arrow{
			top: -4px;
			visibility: visible;
		}
	}
	.user-type{
		line-height: 25px;
		color: #ffffff;
		margin: 30px 0 25px;
	}
	.mega-nav{
		overflow: scroll;
		.navbar-nav{
			li{
				float: none;
				padding: 6px 0;
			}
		}
		
		ul ul{
			list-style: none;
			padding-left: 0;

		}

	}
}

@media (min-width: 992px){
	#meganav{

		.mega-nav{
			height: 100%;
			
			.container{
				margin-bottom: 100px;
				padding-top: 50px;
			}
			.fa-close{
				display: none;
			}
		}
		&.closed{
			height: 0;
		}
		&.open{
			height: 100vh;
			width: 100%;
			.arrow{
				top: -4px;
				visibility: visible;
			}
		}

	}
}
@media (max-width: 991px){
	
	#meganav{
		top: 62px;
		min-height: 100vh;
		transition-property: right;
		transition-duration: 0.3s ;
		transition-timing-function:ease-in-out ;
		&.closed{
			right: -110%;
		}
		&.open{
			right: 0;
			.arrow{
				top: -8px;
				right: 14px;
			}
		}
		.arrow{
			right: 14px;
		}
		.mega-nav{
			overflow: hidden;
			.close{
				color: #ffffff;
				opacity: 1;
				position: absolute;
				top: 15px;
				right: 30px;
				font-size: 35px;
			}

			.dropdown{
				top: 77px;
				right: -100%;
				width: 90%;
				padding: 30px;
				height: 90%;
				background-color: $brand-darkblue;
				position: fixed;
				z-index: 2;
				transition: right 0.2s ease-in-out;
				&.open{
					right: 0;
				}

			}
		}
	}

}