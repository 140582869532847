.ecs .form-group {
	max-width: 100%;
}
.form-group{
	max-width: 50%;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;
	
	.form-group {
		max-width: none;
	}

	&.col-md-6 + .form-group:not(.col-md-6){
		clear: both;
	}
	.remove{
		position: absolute;
		right: 20px;
		cursor: pointer;
		background: transparent;
		border: 0;
		transition:0.2s ease-in-out color;
		&:hover,
		&:focus{
			color: $brand-darkblue;
		}
	}

	.consent-information {
    margin-top: 25px;
    display: block;
    font-weight: bold;
    color: $brand-color;
    font-style: italic;;
	}

	input{
		&:required {
  		color: #dc3545;
		}
	}
	&.invalid{
		input{
			 border: #dc3545 1px solid;
			 color: #dc3545;
			 &:focus{
    			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(220, 53, 69, 0.6);
			}
		}
	}
	&.is-valid{
		input{
			 border: #28a745 1px solid;
			 color: #28a745;
			 &:focus{
    			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(40, 167, 69, 0.6);
			}
		}
	}

}

.invalid-feedback{
		 color: #dc3545 !important;
		 font-style: italic !important;
		 font-weight: 500 !important;
		 font-size: 85% !important;
	}

.four-col-inputs,
.three-col-inputs,
.half-col-inputs{
	overflow: hidden;
	clear: both;
	counter-reset: section;
	.form-group{
		padding-right: 20px;
		float: left;
	}
}

.full-col-inputs{
	.form-group{
		max-width: 100%;
	}
}

.half-col-inputs{
	.form-group{
		width: 50%;

	}
}
.three-col-inputs{
	.form-group{
		width: 33%;

	}
}

.four-col-inputs{
	.form-group{
		width: 25%;
	}
}

@media(max-width:991px){
	.form-group{
		width: 100%;
		max-width: 100%;
	}
}
@media(max-width:767px){

	.four-col-inputs{
		.form-group{
			width: 50%;
		}
	}

}
@media(max-width:580px){
	.half-col-inputs{
		.form-group{
			padding-right: 0;
			max-width: 100%;
			width: 100%;
		}
	}
}

.form-section{
	// padding-bottom: 8vh;
	position: relative;
	.panel{
		box-shadow: none;
	}
	legend{
		& ~ .form-group,
		& ~ .form-section{
			& >	legend{
				padding-top: 15px;
				font-size: 18px;
			}
		}
		& + .form-section:first-of-type,
		& + .form-group:first-of-type{
			& > legend{
				padding-top: 0;
			}
		} 
	}

	.form-section:last-of-type{
		padding-bottom: 20px;
	}

	.form-group{
		legend,
		label{
    padding-top: 0px;
    color: #212B36;
    text-transform: uppercase;
    font-family: Gotham A,Gotham B, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
			& ~ fieldset.form-group{
				font-size: 18px;
			}
			.tooltip-toggle{
				line-height: 0;
				&:focus{
					outline: 0;
				}
			}
		}
		
	}
	.panel-form{
		margin-bottom: 0;
		.panel-heading{
			position: relative;
			padding-left: 30px;
			padding-top: 0;
			legend{
				padding-top: 0;
				font-size: 1.1em;
			}

			i{
				position: absolute;
				left: 0;
				top: 3px;
			}
		}
		.panel-collapse{
			overflow: hidden;
			.checkbox:last-of-type,
			.radio:last-of-type,
			.form-group:last-of-type{
				margin-bottom: 30px;
			}
		}
	}
}


form{
	p{
		color: $brand-lightblue;
		a{
			color: $brand-lightblue;
			text-decoration: underline;
			&:hover,
			&:focus,
			&:active{
				color: $brand-darkblue;
			}
		}
	}
}

.checkbox + .checkbox, 
.radio + .radio {
	margin-top: 10px;
}

.checkbox + .form-group{
	clear: both;
}
.half-checkboxes,
.half-radios{
	.checkbox,
	.radio{
		width: 50%;
		float: left;
	}
}
.third-checkboxes,
.third.radios{
	.checkbox,
	.radio{
		width: 33%;
		float: left;
	}
}

.inline-checks,
.inline-radios{
	.checkbox,
	.radio{
		float: left;
		& + .checkbox,
		& + .radio{
			margin-left: 20px;
		}
	}
}

@media(max-width:991px){
	.form-group{
		width: 100%;
		max-width: 100%;
	}
}
@media(max-width:767px){

	.four-col-inputs{
		.form-group{
			width: 50%;
		}
	}

}
@media(max-width:580px){
	.half-col-inputs{
		.form-group{
			max-width: 100%;
			width: 100%;
		}
	}
}



@media(max-width:767px){
	.third-checkboxes,
	.third.radios{
		.form-group{
			width: 50%;
			float: none;
		}
	}

}
@media(max-width:580px){
	.three-col-inputs,
	.half-col-inputs,
	.four-col-inputs{
		.form-group{
			width: 100%;
			float: none;
		}
	}
}
