$building-relationships: #773e90;
$patient-as-person:#2657a4;
$tailoring-organising: #2c7cbf;
$utilising-effective: #63c6e4;
$sharing-info: #74b457;
$health-literacy: #a6bc3b;
$empowering-person: #f4b91c;
$sharing-power: #ee7134;
$enabling-encouraging: #e7467c;
$recognising-person: #e56c67;
$engaging-goal-oriented: #b31c3c;

$system-level: #e9e9e7;
$values: #b2deec;
$whanau: #ffe614;
$person: #72b847;

$colors : (

	'overview' : (
		color: #fff,
		tone: 'light',
		),
	'building-relationships' : (
		color :  #773e90,
		tone: 'dark',
		),
	'seeing-patient' : (
		color : #2657a4,
		tone: 'dark',
		),
	'tailoring-organising' : (
		color :  #2c7cbf,
		tone: 'dark',
		),
	'utilising-communication' : (
		color :  #63c6e4,
		tone: 'mid', 
		),
	'sharing-info' : (
		color :  #74b457,
		tone: 'mid',
		),
	'health-literacy' : (
		color :  #a6bc3b,
		tone: 'mid',
		),
	'empowering-person' : (
		color :  #f4b91c,
		tone: 'mid',
		),
	'sharing-power' : (
		color :  #ee7134,
		tone: 'mid',
		),
	'enabling-encouraging' : (
		color :  #e7467c,
		tone: 'mid',
		),
	'recognising-person' : (
		color :  #e56c67,
		tone: 'mid',
		),
	'engaging-goal-oriented' : (
		color :  #b31c3c,
		tone: 'mid',
		),
	'system-level' : (
		color :  #bfbfbe,
		tone: 'light',
		),
	'values' : (
		color :  darken(#b2deec , 10%),
		tone: 'light',
		),
	'behaviours' : (
		color :   #828282,
		tone: 'light',
		),
	'foot-notes' : (
		color :   #828282,
		tone: 'light',
		),
	'person-whanau' : (
		color :  #72b847,
		tone: 'light',
		),

	);


.pwcc-link{
	transition: opacity 0.2s ease-in-out;
	overflow: hidden;
	opacity: 1;

	.pwcc-diagram:hover &:not(:hover){
		opacity: 0.5;
	}
}

.pwcc-section{
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
	padding-bottom: 100px;
	.fade-content &{
		opacity: 0 !important;
	}
	.text-center{
		margin-bottom: 50px;
	}
}

#page-section-wrap{

	#title{
		display: block;
		width: 100%;

		h3{
			font-size:1.1em;
			max-width: 65%;
			color: #fff;
			margin: 15px 0;
		}
	}
	#sub-title{
		display: block;
		width: 100%;
		h4{
			font-size:1.1em;
			max-width: 45%;
			margin: 15px 0;
			color: #fff;
		}
	}
}


.back-to-site{
	width: 100%;
	height: 75px;
	display: flex;
	align-items:flex-end;
	.back-img{
		width: 75px;
		height:75px;
	}
	.back-text{
		color: #fff;
		padding-left: 15px;
	}
	&:hover{
		.back-text{
			text-decoration: underline;
		}
	}
}

.stop-scroll{
	overflow-y:hidden;
}

.flex-row{
	display: flex;
	flex-wrap: wrap; 
	margin-right: -15px;
	margin-left: -15px;

	.side-col{
		flex: 0 0 33.333%;
		max-width: 33.333%;
		display: block;
		position: relative;
		padding-right: 20px;

	}
	.main-col{
		max-width: 66.666%;
		flex: 0 0 66.666%;
		display: block;
		position: relative;
		padding: 0 30px;
		background-color: rgba(#fff, 0.75);
	}
}

#pwcc-nav{
	display: flex;
	flex-wrap: wrap; 
	position: sticky;
	top: 0;
	height: 100vh;
	width: 100%;
	align-content:baseline;
	overflow: scroll;
	.mini-svg{
		width: 250px;
		overflow: hidden;
		padding-top: 15px;
		a{
			transition:0.2s ease-in-out opacity;
			opacity: 0.8;
		}
	}
}


@each $name, $map in $colors {

	$color 	: map-get($map, color);
	$tone		: map-get($map, tone);	

	[data-active-section="#{$name}"]{
		background-color: $color;
		transition:background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79);

		.side-col{
			background-color: $color;
			transition:background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79);
			@if $name == 'overview'{
				visibility: hidden;
			}
		}

		h1, h2, h3, p, li{
			transition: color 0.5s cubic-bezier(0.08, 0.29, 0.53, 0.79);
			color: #333;
		}

		#pwcc-nav{
			.mini-svg{
				a[href="##{$name}"]{
					opacity: 0.5;
					&:hover{
						opacity: 1;

					}
				}
			}
		}
	}

	#pwcc-nav{
		.mini-svg{
			a[href="##{$name}"]{
				&:hover{
					path{
						fill: $color;
					}
				}
			}
		}
	}
}


#pwcc-collapsable-nav{
	.nav-toggler{
		display: none;
	}
	ul{
		list-style: none;
		ul{
			margin-top: 3px;
			max-height: 0;
			transition:max-height 0.3s ease-in-out;
			overflow: hidden;
		}
		li{
			line-height: 1.2em;
			font-size: 0.95em;
			position: relative;
			padding: 5px 0 2px;
			@include font-med;

			a{
				color: #fff;
				display: block;
				width: 100%;
				position: relative;
				&:hover,
				&:focus{
					&:after{
						width: 100%;
					}
				}
				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					display: block;
					width: 0;
					height: 1px;
					background:#fff;
					transition: width 0.2s ease-in-out;
				}
			} 
			&.active{
				& > a{
					&:after{
						width: 100%;
					}
				}
				ul{
					max-height: 500px;
				}
			}
			ul{
				padding-left: 15px;
				li{
					padding: 5px 0;
					
				}
			}
		}

	}
	& > ul{
		padding-left: 0;
	}


}

.video-wrapper{
	width: 100%;
	position: relative;
	height: 0;
	padding-bottom: 56.25%;

	iframe{
		position: absolute;
		width: 100%;
		height: 100%;

	}
}




@media(max-width: 991px ){

	.back-to-site{
		height: 30px;
		position: absolute;
		.back-img{
			display: none;
		}
	}
	.pwcc-section{
		h1{
			font-size: 2.5em;
		}
	}
	.flex-row{
		display:block;

		.side-col{
			flex: 0 0 100%;
			max-width: 100%;
			width: 100%;
			display: block;
			position: fixed;
			left: 0;
			z-index: 2;
			padding: 0 30px;
			transition:bottom ease-in-out 0.5s, background-color 0.6s cubic-bezier(0.08, 0.29, 0.63, 0.79);
			border-top:1px #00000059 solid;
			height: 225px;
			&.open{
				height:100%;
			}
		}
		.main-col{
			max-width: 100%;
			flex: 0 0 100%;
			padding: 0 15px;
			h1{
				font-size: 2em;
			}
			h2{
				font-size: 1.6em;
				line-height: 1.1em; 
			}
			ul{
				padding-left: 20px;
			}
			blockquote{
				font-size: 1.3em;
			}
		}
	}
	[data-active-section]{
		.flex-row{
			.side-col{
				bottom:0;
			}
		}
	}
	[data-active-section="overview"]{
		.flex-row{
			.side-col{
				bottom:-251px;
			}
		}
	}

	#page-section-wrap{
		#sub-title {
			padding-top:10px;
			text-align: center;
			h4{
				font-size: 0.9em;
				margin-top: 0;
				margin: 0;
				max-width: 100%;

			}
		}
	} 
	#pwcc-nav{
		flex-direction:column;
		position: static;
		.mini-svg{
			width: 280px;
			height: 145px;
			max-width: 100%;
			margin: 0 auto -6px;
		}
	} 

	#pwcc-collapsable-nav{
		.nav-toggler{
			position: absolute;
			top: 15px;
			right: 15px;
			width: 20px;
			height: 20px;
			display: block;
			border:0;
			background: transparent;
			padding: 0;
			&:focus{
				outline: none;
			}
			.nav-icon{

				display: block;
				width: 20px;
				height: 1px;
				background-color: #fff;
				transform:rotate(0deg);
				transition:background 0.2s ease-in-out;

				&:after,
				&:before{
					content: '';
					display: block;
					width: 20px;
					height: 1px;
					background-color: #fff;
					transition:transform 0.2s ease-in-out;
					transform-origin:center center;
				}
				&:before{
					transform:translateY(-6px) rotate(0deg);
					transition:transform 0.2s ease-in-out;
				}
				&:after{
					transform:translateY(5px);
					transition:transform 0.2s ease-in-out;
				}
			}
		}
		ul{
			li:not(.active){
				display: none;
			}
			ul{
				li{
					font-size: 0.9em;
				}
			}
		}
	}
	.side-col.open{
		transform:translateZ(1px);
		overflow-y:scroll; 

		&:after{
			opacity: 0;
		}

		#pwcc-nav{

			.mini-svg{
				width: 100px;
				height: 70px;
			}
		}
		#pwcc-collapsable-nav{
			.nav-toggler{

				.nav-icon{
					background: transparent;
					&:before{
						transform:rotate(45deg);
					}
					&:after{
						transform:rotate(-45deg);
					}
				}

			}
			ul li{
				display: block;
			}
		}
	}

}

.foot-notes{
	padding-left: 15px;
	li{
		font-size: 0.8em;
	}
}
