////////////
//Pagination
////////////
.pagination-container {
    clear: both;
    text-align: center;
}

.pagination {
    & > .disabled{
        display: none;
        }
    & > .active {
        & > a,
        & > a:hover,
        & > a:focus,
        & > span,
        & > span:hover,
        & > span:focus {
            z-index: 3;
            color: #fff;
            border-radius: 50%;
            background-color: $brand-color;
            border: 0;
            cursor: default;
        }
    }
    li {
        a,
        span {
            background-color: transparent;
            color: $brand-color;
            border: 1px solid #6B7A86;
            border-radius:50%;
            margin-right: 10px;
            transition:background-color 0.2s ease-in-out;
            @include font-regular;
            &:last-of-type{
                 border-radius:50%;
            }
            &:hover,
            &:focus {
                z-index: 2;
                color: #fff;
                background-color: $brand-color;
                border-color:  $brand-color
            }
        }
        i {
            font-size: 12px;
            line-height: 12px;
        }
    }
}

