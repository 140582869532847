

$eventType1: #26b8e6 ;
$eventType2: #b8082d ;
$eventType3: #f6a100;
$eventType4: #c3d500;
$eventType5: #000000;
$eventType6: #F6F6F7;

@mixin eventType( $color ){
  a{
    border-left:5px solid $color;
  }
}


#schedule-container-outer{

  width: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
}


#schedule-container-inner{

  overflow: hidden;
  position: relative;
  padding-left: 65px;
  padding-top: 50px;
  .times-container{
    height: 100%;
    width: 100%;
    position: absolute;
    margin-top: 50px;
    top: 0;
    left: 0;
    overflow: hidden;

  }
  #times{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    .line{
      position: relative;
      width: 100%;
      height: 90px;
      border-top: 1px solid #e4e4e4;
      &::after{
        content: attr(data-time);
        position: absolute;
        top: 0;
        left: 0;
        width: 65px;
        display: flex;
        justify-content:center;
        align-items:center;
        height: 100%;
        border-right: 1px solid #e4e4e4;
        // border-bottom: 1px solid #979797;
        color: $brand-color;
        text-align: center;
      }
    }
  }
  .room-titles-container{
    width: 100%;
    overflow: hidden;
    padding-left: 65px;
    position:absolute;
    top: 0;
    height: 100%;
  }
  #room-titles{
    position: absolute;
    top: 0;
    left:0;
    width: 100%; 

    overflow: visible;
    padding-left: 55px;

    .room-name{
      display: block;
      position: absolute;
      width: 180px;
      height: 50px;
      text-align: center;
      border-left:1px solid #e4e4e4;
      border-bottom: 1px #e4e4e4 solid;
      background: #fff;
      z-index: 9;

      p{
       color: #292F36;
       margin-top: 12px;
     }

   }
 }

}
#schedule::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
  background: #F5F6F6;
}
#schedule::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #D8D8D8;
    // -webkit-box-shadow: 0 0 1px $brand-color;
  }
  #schedule{
    position: relative;
    background: transparent;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    cursor: -webkit-grabbing;
    overflow-x: scroll;
    z-index: 1;



    .room{
      text-align: center;
      position:absolute;

      width: 180px;
      height: 100%;

      &.active{
        display: block;
        visibility: visible;
      }
      h1{
        font-size: 1em;
        margin:0 auto 3px;
        color: $brand-color;
        @include font-regular; 
      }
      .speaker{
        font-size: 0.9em;
        margin-bottom: 3px;
      }
      time{
        color: #292F36;
        font-size: 0.8em;
        @include font-bold;
      }
      .event{


        border:1px solid rgba(196, 205, 213, 0.4);
        min-width: 180px;
        background: #F6F6F7;
        z-index: 2;
        position: absolute;

        a{
          display: block;
          width: 100%;
          height: 100%;
         padding: 15px 5px;
        }

      }
      .concussion{
        @include eventType($eventType1);
      }
      .physical{
        @include eventType($eventType2);

      }
      .musculoskeletal{
        @include eventType($eventType3);
      }
      .acuteCare{
        @include eventType($eventType4);
      }
      .modelsOfCare{
        @include eventType($eventType5);
      }
      .other{
        @include eventType($eventType6);
      }
      .time-short,
      .time-Vshort{
        // height: 30px;
        .speaker,
        time{
          display: none;
        }
      }
      .time-Vshort{
        a{
          padding: 0 5px;
        }
        h1{

          font-size: 12px;
        }
      }
      .time-med{
        height: 60px;
        .speaker{
          display: none;
        }
      }
    }

    .timetable{
      position: relative;
      height: 100%;
      width: auto;

      z-index: 4;
      opacity: 0;
      visibility: hidden;
      height: 0;
      overflow: hidden;
      transition:opacity .3s ease-in, visibility 0s ;
      &.active{
        opacity: 1;
        visibility: visible;
        overflow:visible;
      }
    }

  }
  #schedule-controls{
    width: 100%;
    padding-bottom: 30px;
    clear: both;
    .room-filters{

      z-index: 4;

      .btn{
        position: relative;
        margin-right: 25px;
        &:not(.active):not(:hover):not(:focus){
         background: #C4CDD5;
         color:#fff;
         border:1px solid #C4CDD5; ;
       }
       &:after{
        content:'\f0d7';
        position: absolute;
        display: block;
        left: 50%;
        transform:translateX(-50%);
        bottom: 0;
        @include fontawesome-solid();
        color:  #C4CDD5;
        font-size: 2.2em;
        transition:color 0.2s ease-in-out, bottom 0.2s ease-in-out;
        z-index: -1;
      }
      &:hover,
      &:focus,
      &.active{
        &::after{
         color: $brand-darkblue;
         bottom: -25px;
       }
     }
   }
 } 

}


.break{
  position: absolute;
  background: #E4E4E6;
  width: 100%;
  height: 90px;
  padding: 20px 10px; 
  display: flex;
  align-items:center;
  z-index: 1;
  p{
    margin-bottom: 0;
    color: #2d2d2d;
  }
  &.lunch{
    top: 360px;
  }
}


$keys: $eventType1, $eventType2, $eventType3, $eventType4, $eventType5, $eventType6;

.key{
  display: inline-block;
  p{
    padding:0 15px;
  }
  &::before{
    width:5px;
    height: 20px;
    content: '';
    display: inline-block;
    float: left;
  }
  @for $i from 1 through length($keys){
    &.event-type-#{$i}{
      &::before{
        background-color: nth($keys, $i)
      }
      
    }
  }
}

@media( max-width: 767px){

  #schedule-container-inner{
    // padding-left: 55px;
    #times .line::after{
      left: 0;
    }
  }
  #schedule-controls{
    .room-filters{
      .btn{
        margin: 5px 5px;
        padding: 8px 15px;
      }

    }
  }

  .key{
    display: inline-block;
    p{
      padding:0 15px;
      margin: 0 8px;
    }
  }
}