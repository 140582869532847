.attachments{
	margin: 30px 0;
	.download-list{
		list-style: none;
		padding-left: 0;
		li{
			margin-bottom: 25px;
			padding-left: 0;
			position: relative;
			border-bottom: #F2F1F0 solid 1px ;
			padding-bottom: 20px;
			&::before{
				position: absolute;
				content: '\f15b';
				@include fontawesome-solid;
				right: 0;
				top: 0;
				color:$brand-color;
				width:35px;
				height: 35px;
				border-radius: 30px;
				line-height: 25px;
				font-size: 1.5em;
				text-align: center;
			}
			a{
				color:$brand-color;
				line-height: 1.5em;
				&:hover{
					color: $brand-darkblue;
				}
			}
			small{
				display: block;
				color: #888;
			}
		}
	}	
}
