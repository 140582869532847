.product-features{
	text-align: center;
	margin-bottom: 100px;
	.price{
		font-size: 2.5em;
		display: block;
		color: $brand-darkblue;
		padding-bottom: 20px;
		@include font-light;
	}
	.benefit-list{
		list-style: none;
	margin: 30px 0;
		padding-left: 0;
		li{
			padding-bottom: 15px;
		}

	}
		.btn{
			padding: 8px 40px;
		}
}