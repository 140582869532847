.top5 { padding-top:5px; }
.top7 { padding-top:7px; }
.top10 { padding-top:10px; }
.top15 { padding-top:15px; }
.top17 { padding-top:17px; }
.top30 { padding-top:30px; }
.top60 { padding-top:60px; }
.bottom5 { padding-bottom:5px; }
.bottom7 { padding-bottom:7px; }
.bottom10 { padding-bottom:10px; }
.bottom15 { padding-bottom:15px; }
.bottom17 { padding-bottom:17px; }
.bottom30 { padding-bottom:30px; }
.bottom60 { padding-bottom:60px; }