
@mixin even-vertical-padding($Vpad){
	padding-top: $Vpad;
	padding-bottom: $Vpad;
}

//center vertically and/or horizontally an absolute positioned element
@mixin center($xy:xy) {
	@if $xy==xy {
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		@include transform(translateX(-50%) translateY(-50%));
	}
	@else if $xy==x {
		left: 50%;
		right: auto;
		@include transform(translateX(-50%));
	}
	@else if $xy==y {
		top: 50%;
		bottom: auto;
		@include transform(translateY(-50%));
	}
}


// Browser Prefixes
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

@mixin custom-scrollbar($button, $button-highlight, $background, $width){
	&::-webkit-scrollbar {
  width: $width;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: $background;
	  z-index: 9;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: $button; 
	  z-index: 9;
	  border-radius: 5px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background:  $button-highlight;
	}
}

@mixin placeholder-colors($color){
	::-webkit-input-placeholder {
		color: $color;
	}
	::-moz-placeholder {
		color: $color;
	}
	:-ms-input-placeholder {
		color: $color;
	}
	:-moz-placeholder {
		color: $color;
	}
}


@mixin link-color($color, $highlight){
	a{
		color: $color;
		&:hover, 
		&:focus,
		&:active{
			color: $highlight;
		}
	}
}

